import React from 'react';
import BigCalendar from '@theoremone/th1-calendar';
import moment from 'moment';
import withDragAndDrop from '@theoremone/th1-calendar/lib/addons/dragAndDrop';
import styled from 'styled-components';

import ResizableMonthEvent from '../ResizableMonthEvent';
import Undo from '../Undo';
import '@theoremone/th1-calendar/lib/addons/dragAndDrop/styles.css';

import type { PassedProps } from './container';

// import Toolbar from "../Toolbar";

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));
let formats = {
  dateFormat: 'D',
  weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', 'en'),
};

const DragAndDropCalendar = withDragAndDrop(BigCalendar, { backend: false });

const Container = styled.div`
  flex: 2;
  height: 100%;
`;

const ToolBarExtrasWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  button {
    margin-left: 10px;
  }
`;

const ToolbarExtrasButton = styled.button`
  &:hover {
    cursor: pointer;
  }

  &:focus {
    background-color: transparent !important;
    outline: none !important;
  }

  &:active {
    background-color: transparent !important;
    outline: none !important;
  }
`;

export default function DnDCalendar({
  contextMenuComponents,
  events,
  eventsSorter,
  handleEventDrop,
  handleEventReorder,
  handleEventResize,
  handleEventSelect,
  handleInlineEditEventTitle,
  handleNavigate,
  handleOpenExportModal,
  handleOutsideEventDrop,
  handleOutsideEventOrderAndDrop,
  handleShowTwoMonths,
  handleSlotSelect,
  handleShiftSelect,
  showTwoMonths,
  selectedDate,
}: PassedProps) {
  return (
    <Container>
      <DragAndDropCalendar
        components={{ month: { event: ResizableMonthEvent } }}
        contextMenuComponents={contextMenuComponents}
        defaultDate={new Date()}
        date={selectedDate}
        defaultView="month"
        events={events}
        eventsSorter={eventsSorter}
        formats={formats}
        onEventDrop={handleEventDrop}
        onEventReorder={handleEventReorder}
        onEventResize={handleEventResize}
        onInlineEditEventTitle={handleInlineEditEventTitle}
        onNavigate={handleNavigate}
        onOutsideEventDrop={handleOutsideEventDrop}
        onOutsideEventOrderAndDrop={handleOutsideEventOrderAndDrop}
        onSelectEvent={handleEventSelect}
        onSelectSlot={handleSlotSelect}
        onShiftSelect={handleShiftSelect}
        selectable
        showAllEvents
        showTwoMonths={showTwoMonths}
        titleAccessor="name"
        style={{
          minHeight: 'calc(100vh - 50px)',
        }}
        toolbarExtras={
          <ToolBarExtrasWrapper>
            <div style={{ marginRight: '5px' }}>
              <ToolbarExtrasButton style={{ minWidth: '115px' }} onClick={handleShowTwoMonths}>
                {showTwoMonths ? 'One Month' : 'Two Months'}
              </ToolbarExtrasButton>
            </div>
            <Undo />
            <div>
              <ToolbarExtrasButton onClick={handleOpenExportModal}>Export</ToolbarExtrasButton>
            </div>
          </ToolBarExtrasWrapper>
        }
        views={['month']}
      />
    </Container>
  );
}
