const messages = {
  CLEAR_COPY_PASTE: 'CLEAR_COPY_PASTE',
  CLOSE_ADD_CALENDAR_MODAL: 'CLOSE_ADD_CALENDAR_MODAL',
  CLOSE_EVENT_MODAL: 'CLOSE_EVENT_MODAL',
  CLOSE_EXPORT_MODAL: 'CLOSE_EXPORT_MODAL',
  CLOSE_USERS_MODAL: 'CLOSE_USERS_MODAL',
  COPY_EVENT: 'COPY_EVENT',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  UPDATE_CATEGORY_MODAL_CATEGORY_LOCATION: 'UPDATE_CATEGORY_MODAL_CATEGORY_LOCATION',
  NOT_ADDING_CATEGORY_INPUT: 'NOT_ADDING_CATEGORY_INPUT',
  OPEN_ADD_CALENDAR_MODAL: 'OPEN_ADD_CALENDAR_MODAL',
  OPEN_ADD_EVENT_MODAL: 'OPEN_ADD_EVENT_MODAL',
  OPEN_EXPORT_MODAL: 'OPEN_EXPORT_MODAL',
  OPEN_USERS_MODAL: 'OPEN_USERS_MODAL',
  SELECT_DAY_CELL: 'SELECT_DAY_CELL',
  SELECT_EVENT: 'SELECT_EVENT',
  TOGGLE_ADD_EVENT_MODAL: 'TOGGLE_ADD_EVENT_MODAL',
  TOGGLE_SIDEBAR_EVENT_TEMPLATE_SELECTION: 'TOGGLE_SIDEBAR_EVENT_TEMPLATE_SELECTION',
  RESET_SIDEBAR_EVENT_TEMPLATE_SELECTION: 'RESET_SIDEBAR_EVENT_TEMPLATE_SELECTION',
  SELECT_DATE: 'SELECT_DATE',
  ADD_UNDO: 'ADD_UNDO',
  CLEAR_UNDO_HISTORY: 'CLEAR_UNDO_HISTORY',
  CLEAR_UNDO_PRESENT: 'CLEAR_UNDO_PRESENT',
  TOGGLE_CALENDAR_NEWNESS: 'TOGGLE_CALENDAR_NEWNESS',
  SELECT_EVENTS: 'SELECT_EVENTS',
};

export type Msg =
  | 'CLEAR_COPY_PASTE'
  | 'CLOSE_ADD_CALENDAR_MODAL'
  | 'CLOSE_EVENT_MODAL'
  | 'CLOSE_EXPORT_MODAL'
  | 'CLOSE_USERS_MODAL'
  | 'COPY_EVENT'
  | 'OPEN_MODAL'
  | 'CLOSE_MODAL'
  | 'UPDATE_CATEGORY_MODAL_CATEGORY_LOCATION'
  | 'NOT_ADDING_CATEGORY_INPUT'
  | 'OPEN_ADD_CALENDAR_MODAL'
  | 'OPEN_ADD_EVENT_MODAL'
  | 'OPEN_EXPORT_MODAL'
  | 'OPEN_USERS_MODAL'
  | 'SELECT_DAY_CELL'
  | 'SELECT_EVENT'
  | 'TOGGLE_ADD_EVENT_MODAL'
  | 'TOGGLE_SIDEBAR_EVENT_TEMPLATE_SELECTION'
  | 'RESET_SIDEBAR_EVENT_TEMPLATE_SELECTION'
  | 'SELECT_DATE'
  | 'ADD_UNDO'
  | 'CLEAR_UNDO_HISTORY'
  | 'TOGGLE_CALENDAR_NEWNESS'
  | 'CLEAR_UNDO_PRESENT'
  | 'SELECT_EVENTS';

export default messages;
