import { withHandlers } from 'recompose';

const enhancer = withHandlers({
  handleClick: ({ onClick, id }) => e => {
    e.stopPropagation();
    onClick && onClick(id);
  },
  handleDrillDownClick: ({ onDrillDown, id }) => e => {
    e.stopPropagation();
    onDrillDown && onDrillDown(id);
  },
});

export default enhancer;
