import { firebase } from '../rebase';
const userList = require('../config/users.json');

export const decodeEmail = email => email.replace(/%2E/g, '.');

export const encodeEmail = email => email.replace(/\./g, '%2E');

export const users = userList.reduce((result, email) => {
  result[email.replace(/\./g, '%2E')] = true;
  return result;
}, {});

export const flattenUsers = (users, except = null) => {
  return Object.keys(users).reduce((acc, encodedEmail) => {
    const email = decodeEmail(encodedEmail);
    const user = {
      ...users[encodedEmail],
      email,
    };

    if (except !== email) {
      acc.push(user);
    }

    return acc;
  }, []);
};

export const getUserPath = email => `users/${encodeEmail(email)}`;

export const getUserRef = email => {
  return firebase.database().ref(getUserPath(email));
};
