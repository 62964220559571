import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import rebase from '../../rebase';
import { PathBuilder, calendarId } from '../../helpers';
import type { PathObject } from '../../helpers';
import type { Event } from '../../models';

type Props = {};

type Config = {
  fileName: string,
  title: string,
  episodes: string,
  calendarType: string,
  from: string,
  to: string,
  duration: number,
  client: string,
};

export type PassedProps = {
  location: Location,
  loading: boolean,
  events: Array<Event>,
  config: Config,
  callback: () => void,
};

function provider(WrappedComponent) {
  return class extends Component<Props, void> {
    path: PathObject;

    componentDidMount() {
      this.path = new PathBuilder(calendarId(this.props));

      rebase.bindToState(this.path.events(), {
        context: this,
        state: 'events',
        asArray: true,
        queries: {
          orderByChild: 'weight',
        },
      });
      rebase.bindToState(this.path.export(), {
        context: this,
        state: 'config',
      });
    }
    render() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }
  };
}

export default compose(withRouter, provider);
