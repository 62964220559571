import React from 'react';
import { Subscriber } from 'react-broadcast';

import CalendarModal from './component';
import enhance from './container';

const EnhancedCalendarModal = enhance(CalendarModal);

export default (props: any) => (
  <Subscriber channel="firebase">
    {({ defaultCategories }) => <EnhancedCalendarModal {...{ ...props, defaultCategories }} />}
  </Subscriber>
);
