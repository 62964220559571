export type ItemType = 'category' | 'event-template';

export type ItemSelectionEvent = {
  shiftKey: boolean,
  ctrlKey: boolean,
  type: string,
};

export type ItemSelectionHandler = ({
  path: string[],
  type: ItemType,
  event: ItemSelectionEvent,
}) => void;

export const ItemTypes = {
  CATEGORY: 'category',
  EVENT: 'event', // Keep as 'event' - AR - Thu Oct 12 16:13:22 EDT 2017
};
