import React from 'react';
import { colors } from '../../theme';
import path from 'ramda/src/path';
import styled from 'styled-components';

import { truncate } from '../../../helpers';
import CategoryTree from '../../CategoryTree';
import IconButton from '../../IconButton';
import type {
  Category,
  FirebaseCalendar,
  FirebaseCategories,
  FirebaseEvents,
} from '../../../models';

const truncate65 = truncate(65);

const Header = styled.div`
  display: flex;
  margin: 23px 53px 12px 32px;
`;

const characterLength = length => {
  return length <= 25 ? 'short' : length <= 50 ? 'medium' : 'long';
};

const CalendarTitle = styled.h2`
  margin: 0;
  color: #454545;
  font-weight: 500;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 326px;
  padding: 0 0 25px;
`;

const HeaderWrapper = styled.div`
  position: fixed;
  z-index: 100;
  background-color: #f2f2f2;
  width: 400px;
`;

const SubHeader = styled.div`
  margin: 0 50px 0 32px;
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div`
  background-color: #f2f2f2;
  font-family: ${path(['theme', 'fontFamily'])};
  height: calc(100vh - 50px);
  left: 0px;
  overflow-y: scroll;
  padding-top: 50px;
  position: fixed;
  top: 0px;
  width: 400px;
  z-index: 1;
`;

const StyledIconButton = styled(IconButton)`
  display: flex;
  border: none;
  color: ${colors('teal')};
  font-size: 14px;
  align-items: center;

  span {
    margin-left: 6px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const dynamicMargin = {
  short: '105px',
  medium: '130px',
  long: '160px',
};

const TreeContainer = styled.div`
  margin-top: ${props => dynamicMargin[props.dynamicMargin]};
`;

export type Props = {
  calendar: FirebaseCalendar,
  calendarCategories: FirebaseCategories,
  calendarEvents: FirebaseEvents,
  categories: Array<Category>,
  categoryName: string,
  handleAddCategory: () => {},
  handleCategoryNameAction: () => {},
  handleCategoryNameUpdate: () => {},
  handleOpenEventTemplateModal: () => {},
  isAddingCategory: boolean,
  openEventModal: () => {},
};

export default ({
  calendar,
  calendarCategories,
  calendarEvents,
  categories,
  categoryName,
  handleAddCategory,
  handleCategoryNameAction,
  handleCategoryNameUpdate,
  handleEditCategory,
  handleOpenEventTemplateModal,
  isAddingCategory,
}: Props) => (
  <Container>
    <HeaderWrapper>
      <Header>
        <CalendarTitle title={calendar.name}>{truncate65(calendar.name)}</CalendarTitle>
      </Header>
      <SubHeader className="subheader">
        <Buttons>
          <StyledIconButton
            color="#2ABEC7"
            height="20px"
            name="plus"
            onClick={handleAddCategory}
            text="New Category"
            width="20px"
          />
          <StyledIconButton
            color="#2ABEC7"
            height="20px"
            name="plus"
            onClick={handleOpenEventTemplateModal}
            text="New Event Template"
            width="20px"
          />
        </Buttons>
      </SubHeader>
    </HeaderWrapper>
    <TreeContainer dynamicMargin={characterLength(calendar.name.length)}>
      <CategoryTree categories={categories} />
    </TreeContainer>
  </Container>
);
