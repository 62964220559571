import { last, nth, trim } from 'ramda';
import { compose } from 'recompose';

export function parseNumber(str: string): number {
  const match = str.match(/\d+/g);
  return match ? parseInt(match[match.length - 1], 10) : 0;
}

export function parseWords(str: string): string {
  const match = str.match(/[a-zA-Z]+/g);
  return match ? match.join(' ') : '';
}

export const genName = (
  name: string,
  startingNumber: string,
  incrementBy: number,
  format: string,
  idx: number,
) => {
  if (!startingNumber && !format) {
    return name;
  }

  let shouldInterpolate = false;
  let ret = name;
  if (format) {
    ret = format;
    shouldInterpolate = true;
  }

  // figure out startingNumber format
  let currNumber = +startingNumber;
  if (isNaN(currNumber)) {
    const parts = startingNumber.split(',');
    const startIncrementing = idx > parts.length - 1;
    currNumber = compose(
      numStr => (startIncrementing ? +numStr + idx * incrementBy : numStr),
      trim,
      startIncrementing ? last : nth(idx),
    )(parts);
  } else {
    currNumber += idx * incrementBy;
  }

  if (shouldInterpolate) {
    // %event%
    ret = ret.replace(/%event%/g, name);

    // %num%
    // $FlowFixMe
    ret = ret.replace(/%num%/g, currNumber);
  } else {
    // remove any trailing numbers
    ret = ret.replace(/[\S\s]+?(\d+$)/, '').trim();

    // add currNumber
    ret = `${ret} ${currNumber}`;
  }
  return ret;
};
