import React from 'react';
import { Subscriber } from 'react-broadcast';

import UsersModal from './component';
import enhance from './container';

const EnhancedUsersModal = enhance(UsersModal);

export default (props: any) => (
  <Subscriber channel="firebase">
    {({ users }) => <EnhancedUsersModal {...{ ...props, users }} />}
  </Subscriber>
);
