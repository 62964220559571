import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Broadcast } from 'react-broadcast';
import Spinner from 'react-spinkit';

import CalendarModal from '../CalendarModal';
import rebase from '../../rebase';
import theme, { colors } from '../theme';
import UsersModal from '../UsersModal';
import {
  ManageUsersButton,
  Container,
  LoginContainer,
  Logo,
  CallToAction,
  WelcomeText,
  Back,
  UnauthorizedText,
} from './LoginComponents'

export default class Login extends Component {
  state = {
    defaultCategories: {},
  };

  componentDidMount() {
    this.ref = rebase.bindToState('defaults/categories', {
      context: this,
      state: 'defaultCategories',
      asArray: false,
    });
  }

  componentWillUnmount() {
    rebase.removeBinding(this.ref);
  }

  render() {
    const { handleLogin, handleLogout, user, isAdmin, authorized, loading, redirectUrl, handleManageUsers } = this.props;

    return (
      <Broadcast
        channel="firebase"
        value={{
          defaultCategories: this.state.defaultCategories,
          users: this.props.users,
        }}
      >
        <Container>
          <LoginContainer>
            <Logo />
            {(() => {
              if (user && authorized === false) {
                return (
                  <div>
                    <UnauthorizedText>
                      You are not authorized to use the ITV Calendar application. Please contact an
                      administrator to gain access.
                    </UnauthorizedText>
                    <Back onClick={handleLogout}>Back to Login</Back>
                  </div>
                );
              }
              if (redirectUrl && authorized) return <Redirect to={redirectUrl} />;
              if (loading) return <Spinner name="three-bounce" color={theme.teal} />;
              if (user && authorized)
                return (
                  <>
                    <WelcomeText key="text">
                      Welcome, {user.displayName}! <br />
                      <Back onClick={handleLogout}>Logout</Back>
                    </WelcomeText>

                    {isAdmin && (
                      <ManageUsersButton
                        name="user-plus-solid"
                        width="15px"
                        height="15px"
                        text="Manage Users"
                        onClick={handleManageUsers}
                      />
                    )}

                    <CalendarModal
                      embeded
                      key="form"
                      title="Create a new calendar"
                      handleCloseModal={handleLogout}
                    />

                    <UsersModal />
                  </>
                )
              return <CallToAction onClick={handleLogin}>Login using Google</CallToAction>;
            })()}
          </LoginContainer>
        </Container>
      </Broadcast>
    );
  }
}
