import React from 'react';
import { Subscriber } from 'react-broadcast';
import DnDSidebar from './component';
import enhance from './container';

const EnhancedDnDSidebar = enhance(DnDSidebar);

export default (props: any) => (
  <Subscriber channel="firebase">
    {({ categories, events }) => <EnhancedDnDSidebar {...{ ...props, categories, events }} />}
  </Subscriber>
);
