import { createSelector } from 'reselect';

const eventsPath = state => state.events;

export const canUndo = createSelector(eventsPath, events => events.past.length > 0);

export const canRedo = createSelector(eventsPath, events => events.future.length > 0);

export const selectPresentEvents = createSelector(eventsPath, events => events.present);

export const selectPastEvents = createSelector(eventsPath, events => events.past);

export const selectFutureEvents = createSelector(eventsPath, events => events.future);

export const selectEventsId = createSelector(eventsPath, events => events.index);
