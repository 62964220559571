import type { OnlyMsg, MsgAndPayload } from './types';
import type { ModalType, ModalConfig } from '../../types';
import type { Event } from '../../../models';
import msg from '../../messages';

export * from './sidebar';

export function toggleEventModal() {
  return {
    type: msg.TOGGLE_ADD_EVENT_MODAL,
  };
}

export function openEventModal(event: Event): MsgAndPayload {
  return {
    type: msg.OPEN_ADD_EVENT_MODAL,
    payload: event,
  };
}

export function closeEventModal(): OnlyMsg {
  return {
    type: msg.CLOSE_EVENT_MODAL,
  };
}

export function notAddingCategory(): OnlyMsg {
  return {
    type: msg.NOT_ADDING_CATEGORY_INPUT,
  };
}

export function openAddCalendarModal(): OnlyMsg {
  return {
    type: msg.OPEN_ADD_CALENDAR_MODAL,
  };
}

export function closeAddCalendarModal(): OnlyMsg {
  return {
    type: msg.CLOSE_ADD_CALENDAR_MODAL,
  };
}

export function openExportModal(): OnlyMsg {
  return {
    type: msg.OPEN_EXPORT_MODAL,
  };
}

export function closeExportModal(): OnlyMsg {
  return {
    type: msg.CLOSE_EXPORT_MODAL,
  };
}

export function updateCategoryModalCategoryLocation(payload: any): MsgAndPayload {
  return {
    type: msg.UPDATE_CATEGORY_MODAL_CATEGORY_LOCATION,
    payload,
  };
}

export function openModal(
  payload: ModalConfig & {
    type: ModalType,
  },
): MsgAndPayload {
  return {
    type: msg.OPEN_MODAL,
    payload,
  };
}

export function closeModal(): OnlyMsg {
  return {
    type: msg.CLOSE_MODAL,
  };
}

export function openUsersModal(): OnlyMsg {
  return {
    type: msg.OPEN_USERS_MODAL,
  };
}

export function closeUsersModal(): OnlyMsg {
  return {
    type: msg.CLOSE_USERS_MODAL,
  };
}

export function selectDayCell(date: Date): MsgAndPayload {
  return {
    type: msg.SELECT_DAY_CELL,
    payload: date,
  };
}

export function selectEvent(event: Event): MsgAndPayload {
  return {
    type: msg.SELECT_EVENT,
    payload: event,
  };
}

export function copyEvent(event: Event): MsgAndPayload {
  return {
    type: msg.COPY_EVENT,
    payload: event,
  };
}

export function clearCopyPaste(): OnlyMsg {
  return {
    type: msg.CLEAR_COPY_PASTE,
  };
}

export function toggleCalendarNewNess(isNew: boolean): MsgAndPayload {
  return {
    type: msg.TOGGLE_CALENDAR_NEWNESS,
    payload: { isNew },
  };
}

export function selectEvents(events: Event[]): MsgAndPayload {
  return {
    type: msg.SELECT_EVENTS,
    payload: events,
  };
}
