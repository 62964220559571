export type PathObject = {
  calendar: () => string,
  calendars: () => string,
  categories: () => string,
  category: (category: string) => string,
  event: (event: string) => string,
  events: () => string,
  eventTemplate: (path: string[]) => string,
  eventTemplates: (paths: string[]) => string,
  export: (config: string) => string,
  nestedCategory: () => string,
  redo: (id: string) => string,
  redos: () => string,
  state: (state?: string) => string,
  undo: (id: string) => string,
  undos: () => string,
  users: () => string,
  user: (userId: string) => string,
};

export function PathBuilder(calendarId: string): PathObject {
  this.calendarId = calendarId;
  this.nestedCategory = (paths = []) => {
    return paths.reduce((acc, category) => {
      acc += `/categories/${category}`;
      return acc;
    }, `categories/${this.calendarId}`);
  };

  return {
    calendar: () => `calendars/${this.calendarId}`,
    calendars: () => `calendars`,
    categories: () => `categories/${this.calendarId}/categories`,
    category: (category = '') => `categories/${this.calendarId}/categories/${category}`,
    event: (event = '') => `events/${this.calendarId}/${event}`,
    events: () => `events/${this.calendarId}`,
    eventTemplate: (paths = []) =>
      `${this.nestedCategory(paths.slice(0, -1))}/eventTemplates/${paths[paths.length - 1]}`,
    eventTemplates: (paths = []) => `${this.nestedCategory(paths)}/eventTemplates`,
    export: (config = '') => `export/${this.calendarId}/${config}`,
    nestedCategory: this.nestedCategory,
    redo: (id = '') => `redo/${this.calendarId}/${id}`,
    redos: () => `redo/${this.calendarId}`,
    state: (state = '') => `state/${this.calendarId}/${state}`,
    undo: (id = '') => `undo/${this.calendarId}/${id}`,
    undos: () => `undo/${this.calendarId}`,
    users: () => `users`,
    user: userId => `users/${userId}`,
  };
}
