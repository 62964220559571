import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { toTOMLString } from 'toml-stream';
import cuid from 'cuid';

import * as selectors from '../../store/selectors';
import rebase from '../../rebase';
import type { FirebaseCategories, FirebaseEventTemplates } from '../../models';
import type { PathObject } from '../../helpers';
import { PathBuilder, calendarId, genName } from '../../helpers';
import {
  closeAddCalendarModal,
  clearHistory,
  toggleCalendarNewNess,
  clearPresent,
} from '../../store/actions';

// $FlowFixMe
const preSelectedCategories = require('../../helpers/data/pre-selected-categories.json');

const withStore = connect(
  state => ({
    isAddCalendarModalOpen: selectors.isAddCalendarModalOpen(state),
  }),
  {
    closeAddCalendarModal,
    clearHistory,
    clearPresent,
    toggleCalendarNewNess,
  },
);

export type PassedProps = {
  embeded: boolean,
  handleChange: () => void,
  handleCloseModal: () => void,
  handleSubmit: () => void,
  handleCheck: (categoryId: string, check: boolean) => void,
  isAddCalendarModalOpen: boolean,
  name: string,
  title: string,
  categories: FirebaseCategories,
  episodesSize: number,
  episodesStartNumber: string,
};

function handlers(WrappedComponent) {
  type State = {
    name: string,
    postCategories: ?FirebaseCategories,
    episodesSize: number,
    episodesStartNumber: string,
  };

  type Props = {
    closeAddCalendarModal: any,
    defaultCategories: ?FirebaseCategories,
    history: any,
    match: any,
    clearHistory: () => any,
    toggleCalendarNewNess: (isNew: boolean) => any,
    clearPresent: () => any,
  };

  return class extends Component<Props, State> {
    ref: () => mixed;
    path: PathObject;

    constructor(props) {
      super(props);
      this.state = {
        name: '',
        postCategories: this.getCategoriesByName(this.props.defaultCategories, 'post'),
        episodesSize: 6,
        episodesStartNumber: '101',
      };
    }

    componentDidMount() {
      this.path = new PathBuilder(calendarId(this.props));
    }

    componentDidUpdate(prevProps) {
      const { defaultCategories } = this.props;

      if (prevProps.defaultCategories !== defaultCategories) {
        this.setState({
          postCategories: this.getCategoriesByName(this.props.defaultCategories, 'post'),
        });
      }
    }

    getCategoryIdByName = (categories: ?FirebaseCategories, name: string) => {
      if (!categories) return null;

      const [category] = Object.keys(categories).filter(
        categoryId =>
          categories &&
          categories[categoryId] &&
          categories[categoryId].name.toLowerCase() === name.toLowerCase(),
      );

      return category;
    };

    getCategoriesByName = (categories: ?FirebaseCategories, name: string) => {
      if (!categories) return null;

      const _category = this.getCategoryIdByName(categories, name) || '';

      const _categories = categories && categories[_category] && categories[_category].categories;

      if (!_categories) return _categories;

      const lowerCasePreSelectedCategories = preSelectedCategories.map(cat => cat.toLowerCase());

      return Object.keys(_categories).reduce((accumulator, categoryId) => {
        const category = _categories[categoryId];
        category.checked = lowerCasePreSelectedCategories.includes(category.name.toLowerCase());

        accumulator[categoryId] = category;
        return accumulator;
      }, {});
    };

    getCheckedCategories = (categories: ?FirebaseCategories): ?FirebaseCategories => {
      if (!categories) return null;

      return Object.keys(categories)
        .filter(
          categoryId => categories && categories[categoryId] && categories[categoryId].checked,
        )
        .reduce((accumulator, categoryId) => {
          const category = categories && categories[categoryId];
          if (category) {
            delete category.checked;
            accumulator[categoryId] = category;
          }
          return accumulator;
        }, {});
    };

    formatCategories = (categories: ?FirebaseCategories): ?FirebaseCategories => {
      if (!categories) return categories;
      let categoriesCopy = categories;

      Object.keys(categoriesCopy).map(categoryId => {
        const category = categoriesCopy && categoriesCopy[categoryId];
        if (category) {
          categoriesCopy[categoryId].eventTemplates = this.generateEventTemplates(category.name);
        }
        return categoryId;
      });

      categories = categoriesCopy;

      return categories;
    };

    generateEventTemplates = (categoryName: string): FirebaseEventTemplates => {
      let name, duration, format, id;
      const { episodesStartNumber, episodesSize } = this.state;
      const words = categoryName.trim().split(' ');
      const keyName = words[0];
      const hasMidFormat = words.length > 1;
      const eventTemplates = {};
      const isEditCategory = 'edit' === keyName.toLowerCase();

      for (let index = 0; index < episodesSize; index++) {
        id = cuid();
        format = isEditCategory ? '%event% %num% - Week' : '%event% %num%';
        name = genName(keyName, episodesStartNumber, 1, format, index);

        if (hasMidFormat) {
          const rest = words.slice(1, words.length).join(' ');
          name = [name, rest].join(' ');
        }

        // Edit and Online templates should have a duration of 5 days, others 1.
        duration = ['edit', 'online'].includes(keyName.toLowerCase()) ? 5 : 1;

        eventTemplates[id] = {
          name,
          weight: (index + 1) * 1000,
          duration,
          visible: true,
        };
      }

      return eventTemplates;
    };

    handleChange = ({ target: { name, value } }): void => {
      this.setState({
        [name]: value,
      });
    };

    handleCloseModal = (): void => {
      this.setState({
        postCategories: this.getCategoriesByName(this.props.defaultCategories, 'post'),
      });
      this.props.closeAddCalendarModal();
    };

    handleSubmit = e => {
      e.preventDefault();
      this.props.clearHistory();
      this.props.clearPresent();
      this.props.toggleCalendarNewNess(true);

      const { history, closeAddCalendarModal, defaultCategories } = this.props;
      let { name, postCategories } = this.state;
      const cal = { name };

      if (!defaultCategories) {
        console.error('categories not loaded');
        return;
      }

      let dataCategories: any = defaultCategories;
      const postCategoryId = this.getCategoryIdByName(dataCategories, 'post');

      let selectedPostCategories: ?FirebaseCategories;

      if (postCategories) {
        selectedPostCategories = this.getCheckedCategories(postCategories);
        this.formatCategories(selectedPostCategories);

        dataCategories[postCategoryId].categories = selectedPostCategories;
      }

      const ref = rebase.push('calendars/', {
        data: cal,
        then: err => {
          if (err) {
            return console.error(err);
          }
          rebase.post(new PathBuilder(ref.key).categories(), {
            data: dataCategories,
            then: err => {
              if (err) {
                return console.error(err);
              }
            },
          });
          // close modal
          closeAddCalendarModal();

          // generate shortcut
          const endpoint = document.location.href.split('/#').shift();
          const shortcutTemplate = `[InternetShortcut]\nURL=${endpoint}/#/calendar/${ref.key}\n\n`;
          const metadata = {
            metadata: {
              id: ref.key,
              name: name,
              created_on: Date.now(),
            },
          };

          toTOMLString(metadata, (err, data) => {
            const blob = new Blob([shortcutTemplate, data], { type: 'text/plain;charset=utf-8' });
            saveAs(blob, `${name}.url`, false);

            history.replace(`/calendar/${ref.key}`);
          });
        },
      });
    };

    handleCategoryCheck = (categoryId: string, check: boolean) => {
      const { postCategories } = this.state;

      if (postCategories) {
        postCategories[categoryId].checked = check;
      }

      this.setState({
        postCategories,
      });
    };

    render() {
      const { postCategories } = this.state;
      return (
        <WrappedComponent
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          handleCloseModal={this.handleCloseModal}
          {...this.props}
          {...this.state}
          categories={postCategories}
          handleCheck={this.handleCategoryCheck}
        />
      );
    }
  };
}

export default compose(withStore, withRouter, handlers);
