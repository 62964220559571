import EventModal from './component';
import enhance from './container';
import React from 'react';
import { Subscriber } from 'react-broadcast';

const EnhancedModal = enhance(EventModal);

export default (props: any) => (
  <Subscriber channel="firebase">
    {({ events }) => <EnhancedModal {...{ ...props, events }} />}
  </Subscriber>
);
