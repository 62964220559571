import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import Divider from './Divider';
import Row from './Row';

const Container = styled.div`
  flex: 0 auto;
  overflow-y: auto;
  height: 150px;

  & > div:first-child {
    margin-top: 5px;
  }

  & > div:last-child {
    margin-bottom: 5px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
`;

const Text = styled.span`
  font-size: 0.9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto 0 auto 5px;
`;

const Header = ({ isRoot, parentName, onClick }) => (
  <HeaderWrapper>
    {!isRoot && <BackButton onClick={onClick} />}
    <Text>{!isRoot ? parentName : 'Top Level'}</Text>
  </HeaderWrapper>
);

const BackButtonWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: block;
  flex: auto 0;

  background-color: ${colors('white')};
  color: ${colors('teal')};
  border: 1px solid ${colors('teal')};
  border-radius: 2px;

  &:hover {
    color: ${colors('white')};
    background-color: ${colors('teal')};
  }

  & i {
    margin: 2px -1px;
    text-align: center;
    width: 100%;
    vertical-align: middle;
  }
`;

const BackButton = props => (
  <BackButtonWrapper {...props}>
    <i className="fa fa-chevron-left" />
  </BackButtonWrapper>
);

const TreeNav = (props: any) => {
  const { handleBackClick, path: categoryPath, items, maybeParentName } = props;

  const list = items.map(
    ({ type, selected, props }) =>
      type === 'divider' ? (
        <Divider key={`divider-${props.index}`} {...props} />
      ) : (
        <Row key={`row-${props.id}`} {...props} />
      ),
  );

  return (
    <div>
      <Header
        isRoot={categoryPath.length === 0}
        onClick={handleBackClick}
        parentName={maybeParentName}
      />
      <Container>{list}</Container>
    </div>
  );
};

export default TreeNav;
