import React, { Component } from 'react';
import { Broadcast } from 'react-broadcast';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';
import { withRouter } from 'react-router';
import Combokeys from 'combokeys';
import cuid from 'cuid';

// Group imports after 2.0 release of date-fns
import addMilliseconds from 'date-fns/add_milliseconds/index';
import differenceInMilliseconds from 'date-fns/difference_in_milliseconds/index';
import format from 'date-fns/format/index';
import getDate from 'date-fns/get_date/index';
import getMonth from 'date-fns/get_month/index';
import getYear from 'date-fns/get_year/index';
import setDate from 'date-fns/set_date/index';
import setMonth from 'date-fns/set_month/index';
import setYear from 'date-fns/set_year/index';

import CalendarModal from './CalendarModal';
import CategoryModal from './CategoryModal';
import ContextMenuModal from './ContextMenuModal';
import DnDCalendar from './DnDCalendar';
import DnDSidebar from './DnDSidebar';
import EventModal from './EventModal';
import EventTemplateModal from './EventTemplateModal';
import ExportModal from './ExportModal';
import Layout from './Layout';
import QuickBuildModal from './QuickBuildModal';
import SaveAsModal from './SaveAsModal';
import UsersModal from './UsersModal';

import { calendarId, encodeEmail, PathBuilder } from '../helpers';
import { copyEvent, openEventModal, addUndo } from '../store/actions';
import { selectedDate, copiedEvent, selectedEvent } from '../store/selectors';
import rebase, { firebase } from '../rebase';
import type { Event, FirebaseEvents, FirebaseCategories, FirebaseUsers } from '../models';
import type { PathObject } from '../helpers';

const combokeys = new Combokeys(document.documentElement);

const enhance = compose(
  connect(
    state => ({
      copiedEvent: copiedEvent(state),
      selectedDate: selectedDate(state),
      selectedEvent: selectedEvent(state),
    }),
    {
      copyEvent,
      openEventModal,
      addUndo,
    },
  ),
  withRouter,
);

class UpdateBlocker extends Component<{ children: any }, void> {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return this.props.children;
  }
}

type Props = {
  copiedEvent: Event,
  copyEvent: (event: Event) => void,
  openEventModal: () => void,
  selectedDate: Date,
  selectedEvent: Event,
  user: any,
  addUndo: (events: FirebaseEvents) => void,
};

type State = {
  categories: FirebaseCategories,
  copiedEvent: Event | {},
  defaultCategories: FirebaseCategories,
  events: FirebaseEvents,
  ui: { selectedEvent: Event | {} },
  users: FirebaseUsers,
  isAdmin: boolean,
};

class Calendar extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      events: {},
      categories: {},
      defaultCategories: {},
      copiedEvent: {},
      ui: {
        selectedEvent: {},
      },
      users: {},
      isAdmin: false,
    };
  }

  ref: () => mixed;
  path: PathObject;

  componentDidMount() {
    this.path = new PathBuilder(calendarId(this.props));

    this.ref = rebase.bindToState(this.path.state(), {
      context: this,
      state: 'ui',
      asArray: false,
    });

    rebase.bindToState(this.path.events(), {
      context: this,
      state: 'events',
      asArray: false,
      queries: {},
    });

    rebase.bindToState(this.path.categories(), {
      context: this,
      state: 'categories',
      asArray: false,
    });

    rebase.bindToState('defaults/categories', {
      context: this,
      state: 'defaultCategories',
      asArray: false,
    });

    rebase.bindToState(this.path.users(), {
      context: this,
      state: 'users',
      asArray: false,
    });

    const { openEventModal } = this.props;
    combokeys.bind('alt+e', openEventModal);
    combokeys.bind('meta+c', () => {
      const { selectedEvent } = this.props;
      if (!isEmpty(selectedEvent)) {
        this.props.copyEvent(selectedEvent);
      }
    });
    combokeys.bind('meta+v', () => {
      const { selectedDate, copiedEvent } = this.props;
      if (selectedDate && !isEmpty(copiedEvent)) {
        // TODO: These transformations will happen many times over
        // It would be good to make this into a lib
        const [date, month, year] = [
          getDate(selectedDate),
          getMonth(selectedDate),
          getYear(selectedDate),
        ];
        const duration = differenceInMilliseconds(copiedEvent.end, copiedEvent.start);
        const nextStart = compose(
          v => setYear(v, year),
          v => setDate(v, date),
          v => setMonth(v, month),
        )(copiedEvent.start);

        const updates = {};
        const id = cuid();

        const nextEnd = addMilliseconds(nextStart, duration);
        const data = {
          ...copiedEvent,
          id,
          start: format(nextStart),
          end: format(nextEnd),
        };

        updates[this.path.event(id)] = data;
        this.props.addUndo({ ...this.state.events, [id]: data });

        firebase
          .database()
          .ref()
          .update(updates);
      }
    });
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.users !== this.state.users) {
      this.checkUserIsAdmin();
    }
  }

  componentWillUnmount() {
    rebase.removeBinding(this.ref);
  }

  checkUserIsAdmin = () => {
    const { user } = this.props;
    if (user) {
      const userEmail = user.email;
      const firebaseEmail = encodeEmail(userEmail);
      const firebaseUser = this.state.users[firebaseEmail];
      const isAdmin = (firebaseUser && firebaseUser.role.toUpperCase() === 'ADMIN') || false;

      this.setState({ isAdmin });
    }
  };

  render() {
    const isAdmin = this.state.isAdmin;
    return (
      <Layout isAdmin={isAdmin}>
        <Broadcast
          channel="firebase"
          value={{
            categories: this.state.categories,
            defaultCategories: this.state.defaultCategories,
            events: this.state.events,
            users: this.state.users,
          }}
        >
          <UpdateBlocker>
            <DnDSidebar />
            <DnDCalendar />
            <EventModal />
            <CalendarModal />
            <ContextMenuModal />
            <ExportModal />
            <SaveAsModal />
            <EventTemplateModal />
            <CategoryModal />
            <QuickBuildModal />
            <UsersModal />
          </UpdateBlocker>
        </Broadcast>
      </Layout>
    );
  }
}

export default enhance(Calendar);
