import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import rebase from '../../rebase';

import * as selectors from '../../store/selectors';
import { openUsersModal } from '../../store/actions';
import { PathBuilder, encodeEmail } from '../../helpers';

import type { PathObject } from '../../helpers';

const withStore = connect(
  state => ({
    isUsersModalOpen: selectors.isUsersModalOpen(state),
  }),
  {
    openUsersModal: openUsersModal,
  },
);

export type PassedProps = {
  handleManageUsers: () => void,
  isAdmin: boolean,
};

function withHandlers(WrappedComponent) {
  type Props = {
    isUsersModalOpen: boolean,
    openUsersModal: () => void,
    isAdmin: boolean,
  };

  return class extends Component<Props> {
    constructor(props) {
      super(props);

      this.state = {
        users: {},
        isAdmin: false,
      };
    }

    ref: () => mixed;
    path: PathObject;

    componentDidMount() {
      this.path = new PathBuilder('');

      rebase.bindToState(this.path.users(), {
        context: this,
        state: 'users',
        asArray: false,
      });
    }

    componentDidUpdate(_prevProps, prevState) {
      if (prevState.users !== this.state.users) {
        this.checkUserIsAdmin();
      }
    }

    handleManageUsers = () => {
      this.props.openUsersModal();
    };

    checkUserIsAdmin = () => {
      const { user } = this.props;
      if (user) {
        const userEmail = user.email;
        const firebaseEmail = encodeEmail(userEmail);
        const firebaseUser = this.state.users[firebaseEmail];
        const isAdmin = (firebaseUser && firebaseUser.role.toUpperCase() === 'ADMIN') || false;

        this.setState({ isAdmin });
      }
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          handleManageUsers={this.handleManageUsers}
        />
      );
    }
  };
}

export default compose(withStore, withRouter, withHandlers);
