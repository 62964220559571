import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router';

// TODO: group imports later
import isAfter from 'date-fns/is_after';
import isSameDay from 'date-fns/is_same_day';
import addDays from 'date-fns/add_days';
import format from 'date-fns/format';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days/index';

import { PathBuilder, calendarId } from '../../helpers';
import { updateState } from '../stateHandlers';
import rebase from '../../rebase';
import type { Event } from '../../models';
import type { PathObject } from '../../helpers';
import { selectedEvents } from '../../store/selectors';
import { addUndo } from '../../store/actions';

type Modal = 'events' | 'category-events' | 'event' | 'event-template' | 'multiple-events';

const withStore = connect(
  state => ({
    selectedEvents: selectedEvents(state),
  }),
  {
    addUndo,
  },
);

export type PassedProps = {
  handleChange: () => void,
  handleCloseModal: () => void,
  handleSubmit: () => void,
  hasFocus: boolean,
  modalPushPull: { isOpen: boolean, type: Modal, categoryId?: string },
  push: number,
  startDate: string,
};

function withHandlers(WrappedComponent) {
  type State = {
    push: number,
    events: Array<Event>,
    ui: {
      modalPushPull: { isOpen: boolean, type: Modal, categoryId?: string },
      selectedDate: string,
    },
    startDate: string,
  };

  type Props = {
    match: any,
    addUndo: (events: any) => void,
    selectedEvents: Event[],
  };

  return class extends Component<Props, State> {
    state = {
      push: 1,
      events: [],
      startDate: '',
      ui: {
        modalPushPull: {
          isOpen: false,
          type: 'events',
        },
        selectedDate: '',
      },
    };

    ref: any;
    path: PathObject;

    componentDidMount() {
      this.path = new PathBuilder(calendarId(this.props));

      this.ref = rebase.bindToState(this.path.events(), {
        context: this,
        state: 'events',
        asArray: true,
      });

      rebase.syncState(this.path.state(), {
        context: this,
        state: 'ui',
        asArray: false,
      });
    }

    componentWillUnmount() {
      rebase.removeBinding(this.ref);
    }

    handleChange = ({ target: { name, value } }) => {
      let { ui: { selectedDate, modalPushPull: { type, categoryId } }, events }: State = this.state;
      const { selectedEvents } = this.props;
      let newState: any = {
        [name]: value,
      };
      const filterTypes = ['category-events', 'event-template'];
      let event;

      if (filterTypes.includes(type)) {
        [event] = events.filter(evt => evt.ancestors && evt.ancestors.includes(categoryId || ''));
        selectedDate = event && event.start.toString();
      } else if (type === 'event') {
        [event] = events.filter(evt => evt.id === categoryId);
        selectedDate = event && event.start.toString();
      } else if (type === 'multiple-events') {
        [event] = selectedEvents.sort((left, right) => {
          const leftStartDate = new Date(left.start).getTime();
          const rightStartDate = new Date(right.start).getTime();
          return leftStartDate - rightStartDate;
        });
        selectedDate = event && event.start.toString();
      }

      if (name === 'push') {
        const date = addDays(new Date(selectedDate), value);
        newState.startDate = format(date).split('T')[0];
      } else if (name === 'startDate') {
        const diff = differenceInCalendarDays(new Date(value), selectedDate) + 1;
        newState.push = diff;
      }

      this.setState(newState);
    };

    handleCloseModal = () => {
      this.setState(updateState({ path: ['ui', 'modalPushPull', 'isOpen'], value: false }));
      this.setState({
        push: 1,
        startDate: '',
      });
    };

    handleSubmit = e => {
      e.preventDefault();

      let {
        events,
        ui: { selectedDate, modalPushPull: { type, categoryId } },
        push,
      }: State = this.state;
      const { selectedEvents } = this.props;

      if (isNaN(push)) return;

      this.handleCloseModal();

      let data;

      if (type === 'category-events') {
        data = events.reduce((acc, event) => {
          const { start, end, id, locked, ancestors } = event;

          if (ancestors && ancestors.some(a => a === categoryId) && !locked) {
            const nextStart = format(addDays(start, push));
            const nextEnd = format(addDays(end, push));
            acc[id] = { ...event, start: nextStart, end: nextEnd };
          } else {
            // Nothing changed. Keep old data.
            acc[id] = event;
          }

          return acc;
        }, {});
      } else if (type === 'event-template') {
        data = events.reduce((acc, event) => {
          const { start, end, id, locked, eventTemplateId } = event;

          if (eventTemplateId === categoryId && !locked) {
            const nextStart = format(addDays(start, push));
            const nextEnd = format(addDays(end, push));
            acc[id] = { ...event, start: nextStart, end: nextEnd };
          } else {
            // Nothing changed. Keep old data.
            acc[id] = event;
          }

          return acc;
        }, {});
      } else if (type === 'event') {
        events.forEach(event => {
          if (event.id === categoryId && !event.locked) {
            const { start, end } = event;
            const nextStart = format(addDays(start, push));
            const nextEnd = format(addDays(end, push));
            data = {
              ...data,
              [event.id]: {
                ...event,
                start: nextStart,
                end: nextEnd,
              },
            };
          } else {
            // Nothing changed. Keep old data.
            data = {
              ...data,
              [event.id]: event,
            };
          }
        });
      } else if (type === 'multiple-events') {
        data = events.reduce((acc, evt) => {
          acc[evt.id] = evt;
          return acc;
        }, {});
        selectedEvents.map(event => {
          let { start, end, id, locked } = event;

          if (locked) return event;

          start = format(addDays(start, push));
          end = format(addDays(end, push));
          if (data) {
            data[id] = { ...event, start, end };
          }
          return event;
        });
      } else {
        data = events.reduce((acc, event) => {
          let { start, end, id, locked } = event;

          if (locked) return acc;

          if (isSameDay(selectedDate, start) || isAfter(start, selectedDate)) {
            start = format(addDays(start, push));
            end = format(addDays(end, push));
            acc[id] = { ...event, start, end };
          } else {
            // Nothing changed. Keep old data.
            acc[id] = event;
          }
          return acc;
        }, {});
      }

      if (!data) return;

      this.props.addUndo(data);

      rebase.update(this.path.events(), { data });
      this.setState({
        push: 1,
        startDate: '',
      });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          {...this.state.ui}
          handleChange={this.handleChange}
          handleCloseModal={this.handleCloseModal}
          handleSubmit={this.handleSubmit}
          hasFocus={this.state.ui.modalPushPull.isOpen}
        />
      );
    }
  };
}

export default compose(withStore, withRouter, withHandlers);
