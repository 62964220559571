import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, mapProps, setDisplayName } from 'recompose';
import { normalizeItems } from '../../helpers/normalizers';
import { resetSidebarSelections } from '../../store/actions/ui/sidebar';
import { openModal } from '../../store/actions';

export default compose(
  connect(null, { resetSidebarSelections, openModal }),
  withRouter,
  mapProps(normalizeItems),
  setDisplayName('TopLevelTreeItemEnhancer'),
);
