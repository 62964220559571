import React, { Component } from 'react';
import { compose, mapProps, withProps, type HOC, setDisplayName } from 'recompose';
import { connect } from 'react-redux';
import { ContextMenuTrigger } from 'react-contextmenu';
import { prop } from 'ramda';
import { Subscriber } from 'react-broadcast';
import styled, { css } from 'styled-components';

import { colors } from '../../theme';
import { normalizeItems } from '../../../helpers/normalizers';
import { sortByName } from '../../../helpers/sorters';
import enhance, { dragAndDrop } from './enhancer';
import Icon from '../../Icon';

import { selectDate } from '../../../store/actions/calendar';

// TODO: move to enhancer
import {
  toggleEventTemplateSelection,
  resetSidebarSelections,
} from '../../../store/actions/ui/sidebar';
import { isEventTemplateSelected } from '../../../store/selectors/ui';

import type {
  Category,
  Event as EventType,
  EventTemplate,
  FirebaseCategories,
  FirebaseEvents,
  Styles,
} from '../../../models';

const DragIcon = styled(Icon)`
  background-color: #949494;
  margin: auto 9px auto 13px;
  visibility: hidden;
`;

const PushPullIcon = styled.i`
  visibility: hidden;
  margin: 0 10px;
`;

const EditIcon = styled.i`
  visibility: hidden;
  margin: 0 10px;
`;

const ShowIcon = styled.i`
  color: #222;
  visibility: hidden;
  margin-left: auto;
  margin-right: ${props => (props.folderLevel ? '15px' : '10px')};
`;

const HideIcon = styled.i`
  color: #222;
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  margin-left: auto;
  margin-right: ${props => (props.folderLevel ? '15px' : '10px')};
`;

const SortDateIcon = styled.span`
  color: #222;
  visibility: hidden;
  margin: 0 5px;
  height: 15px;
  margin-top: -17px;
`;

const SortNameIcon = styled.i`
  color: #222;
  visibility: hidden;
  margin: 0 10px;
`;

const DeleteIcon = styled.i`
  color: #222;
  visibility: hidden;
  margin: 0 10px;
`;

const LockIcon = styled.i`
  color: #222;
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  margin-left: ${props => (props.folderLevel ? 'auto' : '10px')};
  margin-right: ${props => (props.folderLevel ? '0px' : '10px')};
`;

const Item = styled.div`
  align-items: center;
  display: flex;
  padding: 2px 0 2px 0;
  padding-right: 20px;

  &:focus {
    text-decoration: none;
    outline: none;
    border: none;
    box-shadow: none;
  }

  ${props =>
    props.selected
      ? css`
          background-color: ${colors('teal')};
          &:hover {
            ${Icon}, i {
              visibility: visible;
            }
          }
          & span,
          i {
            color: white !important;
          }
          & > div {
            border-color: white !important;
            color: white !important;
          }

          & ${DragIcon} {
            background-color: white;
          }
        `
      : css`
          &:hover {
            background-color: #ffffff;
            ${Icon}, i {
              visibility: visible;
            }
          }
        `} ${props =>
      props.invisible &&
      css`
        ${Icon}, i:not(.fa-folder) {
          color: #a3a3a3;
        }
      `};
`;

const BasicItem = styled.div`
  display: flex;
  padding: ${prop('shrinked') ? '4px 0 0' : '5px 0'};
  padding-right: 20px;

  &:hover {
    background-color: #fff;
    i {
      visibility: visible;
    }
  }

  ${props =>
    props.invisible &&
    css`
      ${EventTemplateLabel}, i {
        color: #a3a3a3;
      }
    `};
`;

const Square = styled.i`
  color: #889ba4;
  line-height: unset;
  margin-right: 8px;
  opacity: ${({ isHidden: v }) => (v ? 0 : 1)};
`;

const Folder = styled.i`
  color: #ffc44d;
  line-height: unset;
  margin-right: 8px;
`;

const File = styled.i`
  color: #e2e2e2;
  line-height: unset;
  margin-right: 9px;
`;

const EventTemplateLabel = styled.span`
  color: #333333;
  font-size: 12px;
  line-height: 1em;
`;

const CategoryLabel = styled.span`
  color: #333333;
  font-size: 13px;
  font-weight: bold;
  margin: auto 0;
`;

const ChildPadding = styled.div.attrs({
  width: props => props.width || '0px',
})`
  width: ${props => props.width};
`;

const ToggleIcon = ({ isCollapsed, ...rest }) => {
  const name = isCollapsed ? 'plus' : 'minus';
  return <Square className={`fa fa-${name}-square-o`} {...rest} />;
};

type CategoryItemType = Category & {
  ancestors: string[],
  handleDeleteCategoryOrTemplate: ({ ancestors: string[], type: 'category' | 'template' }) => void,
  handleOpenCategoryModal: () => mixed,
  handleOpenPushPullModal: (type?: string) => mixed,
  handleShowHideEventsMany: ({ id: string, visible: boolean }) => void,
  handleLockEventsMany: ({ id: string, locked: boolean, isParent: boolean, level: number }) => void,
  isCollapsed: boolean,
  label: string,
  level: number,
  onClick: (event: Event) => {},
  visible: boolean,
  locked: boolean,
};

const CategoryItem = ({
  ancestors,
  handleDeleteCategoryOrTemplate,
  handleOpenCategoryModal,
  handleOpenPushPullModal,
  handleShowHideEventsMany,
  handleLockEventsMany,
  id,
  isCollapsed,
  label,
  level,
  onClick,
  visible,
  locked,
}: CategoryItemType) => (
  <Item invisible={!visible}>
    <DragIcon name="drag" width="10px" height="16px" />
    <ChildPadding width={`${level * 24}px`} />
    <ToggleIcon isCollapsed={isCollapsed} onClick={onClick} />
    <Folder className="fa fa-folder" />
    <CategoryLabel>{label}</CategoryLabel>

    {visible === undefined || visible ? (
      <ShowIcon
        folderLevel
        className="fa fa-eye"
        title="Visible"
        name="show"
        width="15px"
        height="15px"
        onClick={(e: Event) => {
          e.stopPropagation();
          handleShowHideEventsMany({ id, visible: false });
        }}
      />
    ) : (
      <HideIcon
        show
        folderLevel
        className="fa fa-eye-slash"
        title="Invisible"
        name="hide"
        width="15px"
        height="15px"
        onClick={(e: Event) => {
          e.stopPropagation();
          handleShowHideEventsMany({ id, visible: true });
        }}
      />
    )}
    <EditIcon
      className="fa fa-pencil-square-o"
      height="15px"
      name="edit"
      title="Edit"
      width="15px"
      onClick={(e: Event) => {
        e.stopPropagation();
        handleOpenCategoryModal();
      }}
    />
    <PushPullIcon
      className="fa fa-exchange"
      height="15px"
      name="push-pull"
      onClick={(e: Event) => {
        e.stopPropagation();
        handleOpenPushPullModal();
      }}
      title="Push/Pull"
      width="15px"
    />
    <LockIcon
      show={locked}
      className={`fa ${locked ? 'fa-lock' : 'fa-unlock'}`}
      title="Lock/Unlock"
      name="lock/unlock"
      width="15px"
      height="15px"
      onClick={(e: Event) => {
        e.stopPropagation();
        const isParent = level === 0;
        handleLockEventsMany({ id, locked: !locked, isParent, level });
      }}
    />
    <DeleteIcon
      className="fa fa-times"
      name="delete"
      title="Delete"
      onClick={(e: Event) => {
        e.stopPropagation();
        handleDeleteCategoryOrTemplate({ ancestors, type: 'category' });
      }}
    />
  </Item>
);

const getCount = prop('instancesLength');

const InstanceCounter = styled.div`
  align-items: center;
  border-color: ${props => (getCount(props) > 0 ? colors('teal') : colors('grey'))};
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  display: flex;
  height: 16px;
  justify-content: center;
  margin-right: 8px;
  width: 16px;
  padding-left: 1px;
  padding-bottom: 1px;
  span {
    color: ${props => (getCount(props) > 0 ? colors('teal') : colors('grey'))};
    font-size: 9px;
    line-height: 1em;
    font-family: 'Roboto Mono', monospace;
  }
`;

type EventInstanceItemType = EventType & {
  handleDeleteCategoryOrTemplate: ({ ancestors: string[], type: 'category' | 'template' }) => void,
  handleShowEventInstances: () => void,
  handleOpenPushPullModal: (type?: string) => mixed,
  handleShowHideEventsMany: ({ id: string, visible: boolean }) => void,
  handleLockEventsMany: ({ id: string, locked: boolean, level: number }) => void,
  handleSortEventInstances: ({ sortBy: 'name' | 'date', ancestors: string[] }) => void,
  toggleEventTemplateSelection: (path: string) => void,
  isSelected: boolean,
  hasInstances: boolean,
  instancesLength: number,
  label: string,
  level: number,
  showEventInstances: boolean,
  locked: boolean,
};

const itemSelectionHandlerProxy = (cb: (path: string) => void, ancestors: string[]) => (
  e: Event,
) => {
  e.stopPropagation();
  cb(ancestors.join('/'));
};

const EventInstanceItem = (props: EventInstanceItemType) => {
  const {
    ancestors,
    handleDeleteCategoryOrTemplate,
    handleShowEventInstances,
    handleShowHideEventsMany,
    handleLockEventsMany,
    handleSortEventInstances,
    toggleEventTemplateSelection,
    handleOpenPushPullModal,
    hasInstances,
    id,
    instancesLength,
    label,
    level,
    showEventInstances,
    visible,
    isSelected,
    locked,
  } = props;
  return (
    <ContextMenuTrigger id="sidebar-context-menu" disable={!isSelected}>
      <Item
        invisible={!visible}
        selected={isSelected}
        onClick={itemSelectionHandlerProxy(toggleEventTemplateSelection, ancestors)}
      >
        <DragIcon name="drag" width="10px" height="16px" />
        <ChildPadding width={`${level * 24}px`} />
        <ToggleIcon
          isCollapsed={!showEventInstances}
          isHidden={!hasInstances}
          onClick={(e: Event) => {
            e.stopPropagation();
            handleShowEventInstances();
          }}
        />
        <InstanceCounter {...{ instancesLength }}>
          <span>{instancesLength < 1 ? '' : instancesLength}</span>
        </InstanceCounter>
        <EventTemplateLabel>{label}</EventTemplateLabel>
        {visible === undefined || visible ? (
          <ShowIcon
            className="fa fa-eye"
            title="Visible"
            name="show"
            width="15px"
            height="15px"
            onClick={(e: Event) => {
              e.stopPropagation();
              handleShowHideEventsMany({ id, visible: false, type: 'event-template' });
            }}
          />
        ) : (
          <HideIcon
            show
            className="fa fa-eye-slash"
            title="Invisible"
            name="hide"
            width="15px"
            height="15px"
            onClick={(e: Event) => {
              e.stopPropagation();
              handleShowHideEventsMany({ id, visible: true, type: 'event-template' });
            }}
          />
        )}
        <SortDateIcon
          className="fa-stack"
          onClick={(e: Event) => {
            e.stopPropagation();
            handleSortEventInstances({ sortBy: 'date', ancestors });
          }}
          title="Sort by Start Date"
        >
          <i className="fa fa-calendar-o fa-stack-1x" />
          <i style={{ marginTop: '2px' }} className="fa fa-caret-down fa-stack-1x" />
        </SortDateIcon>
        <SortNameIcon
          className="fa fa-sort-alpha-asc"
          height="15px"
          onClick={(e: Event) => {
            e.stopPropagation();
            handleSortEventInstances({ sortBy: 'name', ancestors });
          }}
          title="Sort by Name"
          width="15px"
        />
        <PushPullIcon
          className="fa fa-exchange"
          height="15px"
          name="push-pull"
          onClick={(e: Event) => {
            e.stopPropagation();
            handleOpenPushPullModal('event-template');
          }}
          title="Push/Pull"
          width="15px"
        />
        <LockIcon
          show={locked}
          className={`fa ${locked ? 'fa-lock' : 'fa-unlock'}`}
          title="Lock/Unlock"
          name="lock/unlock"
          width="15px"
          height="15px"
          onClick={(e: Event) => {
            e.stopPropagation();
            handleLockEventsMany({ id, locked: !locked, type: 'event-template', level });
          }}
        />
        <DeleteIcon
          className="fa fa-times"
          name="delete"
          title="Delete"
          onClick={() => {
            handleDeleteCategoryOrTemplate({ ancestors, type: 'template' });
          }}
        />
      </Item>
    </ContextMenuTrigger>
  );
};

const DraggableEventInstanceItem = dragAndDrop(({ connectDragSource, ...props }) =>
  connectDragSource(
    <div>
      <EventInstanceItem {...props} />
    </div>,
  ),
);

type ItemProps = {
  ancestors: string[],
  categories: Category[],
  duration: number,
  eventTemplates: EventTemplate[],
  handleDeleteCategoryOrTemplate: ({ ancestors: string[], type: 'category' | 'template' }) => void,
  handleOpenCategoryModal: () => mixed,
  handleOpenPushPullModal: (type?: string) => mixed,
  handleShowHideEvent: ({ id: string, visible: boolean, ancestors: string[] }) => void,
  handleShowHideEventsMany: ({ id: string, visible: boolean }) => void,
  handleLockEvent: ({ id: string, locked: boolean, ancestors: string[] }) => void,
  handleLockEventsMany: ({ id: string, locked: boolean, level: number }) => void,
  handleSortEventInstances: ({ sortBy: 'name' | 'date', ancestors: string[] }) => void,
  handleSortEventInstances: ({ sortBy: 'name' | 'date', ancestors: string[] }) => void,
  handleUpdateEventName: ({ id: string, name: string }) => void,
  id: string,
  instances?: EventType[],
  isCollapsed: boolean,
  label: string,
  level: number,
  name: string,
  onClick: (e: Event) => {},
  sortEventInstancesBy: 'name' | 'date',
  styles: Styles,
  type: string,
  visible: boolean,
  weight: number,
  path: string[],
  locked: boolean,
};

const SubFolder = compose(
  mapProps(normalizeItems),
  setDisplayName('SubFolder'),
)(
  ({
    ancestors,
    handleDeleteCategoryOrTemplate,
    handleShowHideEventsMany,
    handleLockEventsMany,
    items,
    level,
    styles: parentStyles,
  }: {
    ancestors: string[],
    handleDeleteCategoryOrTemplate: { ancestors: string[], type: 'category' | 'template' },
    handleShowHideEventsMany: { id: string, visible: boolean },
    items: ItemProps[],
    level: number,
    styles: Styles,
    handleLockEventsMany: { id: string, locked: boolean, level: number },
  }) => {
    return (
      <div>
        {items.map(({ id, name, styles, ...rest }) => {
          return (
            <Subscriber key={`subfolder-subscriber-${id}`} channel="firebase">
              {({
                categories: calendarCategories,
                events: calendarEvents,
              }: {
                categories: FirebaseCategories,
                events: FirebaseEvents,
              }) => {
                return (
                  <InternalTreeItem
                    {...rest}
                    ancestors={[...ancestors, id]}
                    calendarCategories={calendarCategories}
                    calendarEvents={calendarEvents}
                    handleDeleteCategoryOrTemplate={handleDeleteCategoryOrTemplate}
                    handleShowHideEventsMany={handleShowHideEventsMany}
                    handleLockEventsMany={handleLockEventsMany}
                    id={id}
                    initialIsCollapsed={!!rest.instances}
                    key={id}
                    label={name}
                    level={level}
                    styles={styles || parentStyles}
                  />
                );
              }}
            </Subscriber>
          );
        })}
      </div>
    );
  },
);

type EditEventNameState = { isEditing: boolean, eventName: string };

type EditEventNameProps = {
  handleUpdateEventName: ({ id: string, name: string }) => void,
  id: string,
  name: string,
  render: any,
  date: any,
  selectDate: (date: any) => void,
};

class EditEventName extends Component<EditEventNameProps, EditEventNameState> {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      eventName: this.props.name,
    };
  }

  handleEditing = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  handleChange = e => {
    this.setState({ eventName: e.target.value });
  };

  handleKeyDown = e => {
    if (e.keyCode === 13) {
      const { handleUpdateEventName, id } = this.props;
      const { eventName } = this.state;
      this.setState({ isEditing: false });
      handleUpdateEventName({ id, name: eventName });
    }
  };

  handleBlur = () => {
    const { handleUpdateEventName, id } = this.props;
    const { eventName } = this.state;
    this.setState({ isEditing: false });
    handleUpdateEventName({ id, name: eventName });
  };

  handleFocus = e => {
    const { eventName } = this.state;
    if (e.target.setSelectionRange) {
      e.target.setSelectionRange(eventName.length, eventName.length);
    }
  };

  handleClick = e => {
    e.preventDefault();
    const { date } = this.props;
    this.props.selectDate(new Date(date));
  };

  render() {
    const { eventName, isEditing } = this.state;
    const {
      handleBlur,
      handleChange,
      handleEditing,
      handleKeyDown,
      handleFocus,
      handleClick,
    } = this;
    return (
      <div onClick={handleClick}>
        {this.props.render({
          eventName,
          handleBlur,
          handleChange,
          handleEditing,
          handleFocus,
          handleKeyDown,
          isEditing,
        })}
      </div>
    );
  }
}

const sortByDate = (a, b) => (a && a.start && b && b.start && a.start < b.start ? 0 : 1);

const EventNameInput = styled.input`
  border: 0;
  font-family: 'ITV Font', monospace;
  font-size: 12px;
  line-height: 1;
  margin-left: -1px;
  max-height: 12px;
  outline: none;
`;

const withStoreInstance = connect(null, {
  selectDate,
});

const Instances = compose(
  withStoreInstance,
)(
  ({
    handleShowHideEvent,
    handleUpdateEventName,
    instances,
    level,
    sortEventInstancesBy,
    selectDate,
    handleLockEvent,
    handleOpenPushPullModal,
  }) => {
    return (
      <div>
        {instances
          .sort(
            {
              name: sortByName,
              date: sortByDate,
              default: sortByName,
            }[sortEventInstancesBy || 'default'],
          )
          .map(({ name, id, visible, ancestors, start, locked }, idx) => (
            <BasicItem key={`event-instance-${id}-${idx}`} shrinked invisible={!visible}>
              <ChildPadding width={`${level * 24 + 32}px`} />
              <ToggleIcon isHidden={true} isCollapsed={false} />
              <File style={{ opacity: 0 }} className="fa fa-file" />
              <EditEventName
                handleUpdateEventName={handleUpdateEventName}
                id={id}
                name={name}
                date={start}
                selectDate={selectDate}
                render={({
                  eventName,
                  handleBlur,
                  handleChange,
                  handleEditing,
                  handleFocus,
                  handleKeyDown,
                  isEditing,
                }) => {
                  return isEditing ? (
                    <EventNameInput
                      autoFocus
                      spellCheck={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onKeyDown={handleKeyDown}
                      type="text"
                      value={eventName}
                    />
                  ) : (
                    <EventTemplateLabel onDoubleClick={handleEditing}>
                      {eventName}
                    </EventTemplateLabel>
                  );
                }}
              />
              {visible === undefined || visible ? (
                <ShowIcon
                  className="fa fa-eye"
                  title="Visible"
                  name="show"
                  width="15px"
                  height="15px"
                  onClick={() => {
                    handleShowHideEvent({ id, visible: false, ancestors });
                  }}
                />
              ) : (
                <HideIcon
                  show
                  className="fa fa-eye-slash"
                  title="Invisible"
                  name="hide"
                  width="15px"
                  height="15px"
                  onClick={() => {
                    handleShowHideEvent({ id, visible: true, ancestors });
                  }}
                />
              )}
              <PushPullIcon
                className="fa fa-exchange"
                height="15px"
                name="push-pull"
                onClick={(e: Event) => {
                  e.stopPropagation();
                  handleOpenPushPullModal('event', id, start);
                }}
                title="Push/Pull"
                width="15px"
              />
              <LockIcon
                show={locked}
                className={`fa ${locked ? 'fa-lock' : 'fa-unlock'}`}
                title="Lock/Unlock"
                name="lock/unlock"
                width="15px"
                height="15px"
                folderLevel
                onClick={(e: Event) => {
                  e.stopPropagation();
                  handleLockEvent({ id, locked: !locked, ancestors });
                }}
              />
            </BasicItem>
          ))}
      </div>
    );
  },
);

const Container = styled.div`
  user-select: none;
  user-drag: element;
  &:hover {
    cursor: pointer;
  }
`;

type State = {
  showEventInstances: boolean,
};

type EventTemplatePassedProps = {
  type: string,
  ancestors: string[],
  handleShowEventInstances: () => void,
  handleShowHideEventsMany: ({ id: string, visible: boolean }) => void,
  handleLockEventsMany: ({ id: string, locked: boolean, level: number }) => void,
  handleSortEventInstances: ({ sortBy: 'name' | 'date', ancestors: string[] }) => void,
  showEventInstances: boolean,
};

type EventTemplateInjectedProps = EventTemplatePassedProps & {
  isSelected: boolean,
};

const itemTypeEnhancer = (
  props: EventTemplatePassedProps,
): HOC<EventTemplatePassedProps, EventTemplateInjectedProps> =>
  compose(
    connect(
      (state, { ancestors }) => ({
        isSelected: isEventTemplateSelected(ancestors.join('/'))(state),
      }),
      {
        toggleEventTemplateSelection,
        resetSidebarSelections,
      },
    ),
    withProps(props),
  );

class TreeItem extends Component<ItemProps, State> {
  constructor(props: ItemProps) {
    super(props);
    this.state = {
      showEventInstances: false,
    };
  }

  handleShowEventInstances = () => {
    this.setState(prev => ({ showEventInstances: !prev.showEventInstances }));
  };

  render() {
    const { handleShowEventInstances } = this;
    const {
      ancestors = [],
      handleDeleteCategoryOrTemplate,
      handleOpenCategoryModal,
      handleOpenPushPullModal,
      handleShowHideEvent,
      handleShowHideEventsMany,
      handleLockEvent,
      handleLockEventsMany,
      handleSortEventInstances,
      handleUpdateEventName,
      id,
      instances = [],
      isCollapsed,
      level,
      sortEventInstancesBy,
      type,
      visible,
      weight,
      locked,
      ...rest
    } = this.props;
    const { showEventInstances } = this.state;
    const ItemType =
      type === 'event-template'
        ? itemTypeEnhancer({
            type,
            ancestors,
            handleDeleteCategoryOrTemplate,
            handleShowEventInstances,
            handleShowHideEventsMany,
            handleLockEventsMany,
            handleSortEventInstances,
            showEventInstances,
            handleOpenPushPullModal,
          })(DraggableEventInstanceItem)
        : CategoryItem;
    const hasInstances = !!instances;
    const instancesLength = !instances ? 0 : instances.length;
    return (
      <Container>
        <ItemType
          {...{
            ...rest,
            ancestors,
            handleDeleteCategoryOrTemplate,
            handleOpenCategoryModal,
            handleOpenPushPullModal,
            handleShowHideEventsMany,
            handleLockEventsMany,
            hasInstances,
            id,
            instancesLength,
            isCollapsed,
            level,
            visible,
            weight,
            type,
            locked,
          }}
        />
        {!isCollapsed &&
          type === 'category' && (
            <SubFolder
              {...{
                ...rest,
                ancestors,
                handleDeleteCategoryOrTemplate,
                isCollapsed,
                handleShowHideEventsMany,
                handleLockEventsMany,
              }}
              level={level + 1}
            />
          )}
        {showEventInstances &&
          hasInstances && (
            <Instances
              {...{
                handleShowHideEvent,
                handleUpdateEventName,
                instances,
                level: level + 1,
                sortEventInstancesBy,
                handleLockEvent,
                handleOpenPushPullModal,
              }}
            />
          )}
      </Container>
    );
  }
}

const InternalTreeItem = enhance(TreeItem);

export default TreeItem;
