import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../theme';

const PlusSquare = styled.i`
  color: ${colors('teal')};
  display: none;

  &:before {
    background-color: ${colors('white')};
  }
`;

const Segment = styled.div.attrs({
  width: props => props.width || '50%',
})`
  height: 3px;
  background-color: ${colors('gray')};
  width: ${props => props.width};
  margin: auto 0;
`;

const isSelectedFragment = css`
  ${Segment} {
    background-color: ${colors('teal')};
  }
  ${PlusSquare} {
    display: unset;
    transform: translateY(-70%);
  }
`;

const hoverFragment = css`
  &:hover {
    ${isSelectedFragment};
  }
`;

const DividerContainer = styled.div.attrs({
  selected: props => !!props.selected,
})`
  height: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${props => (props.selected ? isSelectedFragment : hoverFragment)};
`;

const Divider = props => {
  return (
    <DividerContainer {...props}>
      <Segment />
      <PlusSquare className="fa fa-plus-square" />
      <Segment />
    </DividerContainer>
  );
};

export default Divider;
