import React, { Component } from 'react';
import cuid from 'cuid';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { prop, last, sortBy, assocPath, merge } from 'ramda';
import { withRouter } from 'react-router-dom';
import { ContextMenu, MenuItem } from 'react-contextmenu';

// TODO: group imports laterz
import addMilliseconds from 'date-fns/add_milliseconds/index';
import differenceInMilliseconds from 'date-fns/difference_in_milliseconds/index';
import format from 'date-fns/format/index';
import getHours from 'date-fns/get_hours/index';
import getMinutes from 'date-fns/get_minutes/index';
import isSameDay from 'date-fns/is_same_day/index';

import { PathBuilder, calendarId } from '../../helpers';
import { updateState } from '../stateHandlers';
import rebase, { firebase } from '../../rebase';
import {
  defaultEventEnd,
  defaultEventStart,
  duration,
  fAddDays,
  fAddHours,
  fAddMinutes,
} from './helpers';
import {
  selectedDate,
  selectedEvent,
  copiedEvent,
  selectedCalendarDate,
  selectPresentEvents,
  selectEventsId,
  selectPastEvents,
  selectFutureEvents,
  isNewCalendar,
  selectedEvents,
} from '../../store/selectors';
import {
  clearCopyPaste,
  copyEvent,
  openEventModal,
  openExportModal,
  selectDayCell,
  selectEvent,
  selectDate,
  addUndo,
  toggleCalendarNewNess,
  clearHistory,
  clearPresent,
  selectEvents,
} from '../../store/actions';

import type { Event, FirebaseEvents } from '../../models';
import type { PathObject } from '../../helpers';
import { cloneDeep, isEmpty } from 'lodash';

const withStore = connect(
  state => ({
    copiedEvent: copiedEvent(state),
    selectedDate: selectedDate(state),
    selectedEvent: selectedEvent(state),
    selectedCalendarDate: selectedCalendarDate(state),
    snapshotEvents: selectPresentEvents(state),
    snapshotId: selectEventsId(state),
    pastSnapshot: selectPastEvents(state),
    futureSnapshot: selectFutureEvents(state),
    isNewCalendar: isNewCalendar(state),
    selectedEvents: selectedEvents(state),
  }),
  {
    clearCopyPaste,
    copyEvent,
    openEventModal,
    openExportModal,
    selectDayCell,
    selectEvent,
    selectDate,
    addUndo,
    toggleCalendarNewNess,
    clearHistory,
    clearPresent,
    selectEvents,
  },
);

export type PassedProps = {
  contextMenuComponents: {
    dayCell: any,
    event: any,
  },
  events: Event[],
  eventsSorter: () => mixed,
  handleEventDrop: (event: Event, eventType: string) => void,
  handleEventReorder: (events: Event[]) => void,
  handleEventResize: (type: string, event: Event) => void,
  handleEventSelect: (event: Event) => void,
  handleInlineEditEventTitle: (event: Event) => void,
  handleNavigate: (date: Date) => void,
  handleOpenExportModal: () => void,
  handleOutsideEventDrop: (start: Date, event: Event) => void,
  handleOutsideEventOrderAndDrop: (event: Event) => void,
  handleShowTwoMonths: () => void,
  handleSlotSelect: (event: Event) => void,
  handleShiftSelect: (events: any) => void,
  showTwoMonths: boolean,
  selectedDate: Date,
};

function withHandlers(WrappedComponent) {
  type Props = {
    globalEvents: FirebaseEvents,
    clearCopyPaste: () => void,
    copiedEvent: (event: Event) => void,
    copyEvent: (event: Event) => void,
    match: any,
    openEventModal: (event: Event) => void,
    openExportModal: () => void,
    selectDayCell: (event: Event) => void,
    selectEvent: (event: Event) => void,
    selectedCalendarDate: Date,
    selectDate: (date: Date) => void,
    addUndo: (events: FirebaseEvents) => void,
    snapshotEvents: FirebaseEvents,
    pastSnapshot: FirebaseEvents,
    futureSnapshot: FirebaseEvents,
    snapshotId: number,
    toggleCalendarNewNess: (isNew: boolean) => void,
    clearHistory: () => any,
    clearPresent: () => any,
    isNewCalendar: boolean,
    selectedEvents: Event[],
    selectEvents: (events: Event[]) => void,
  };

  type State = {
    date: Date,
    showTwoMonths: boolean,
    events: FirebaseEvents,
    ui: {
      selectedDate: string,
      modalPushPull: {
        isOpen: boolean,
        type: string,
      },
    },
    isMounted: boolean,
    firstLoadedEvents: FirebaseEvents,
  };

  return class extends Component<Props, State> {
    constructor(props) {
      super(props);
      this.state = {
        date: this.props.selectedCalendarDate || new Date(),
        showTwoMonths: false,
        events: props.globalEvents,
        ui: {
          selectedDate: '',
          modalPushPull: {
            isOpen: false,
            type: 'events',
          },
        },
        isMounted: false,
        firstLoadedEvents: {},
      };
    }

    ref: () => mixed;
    events: () => mixed;
    path: PathObject;

    componentDidMount() {
      this.path = new PathBuilder(calendarId(this.props));

      this.ref = rebase.syncState(this.path.state(), {
        context: this,
        state: 'ui',
        asArray: false,
      });

      this.setState({
        ui: {
          selectedDate: '',
          modalPushPull: {
            isOpen: false,
            type: 'events',
          },
        },
        isMounted: true,
      });
    }

    componentDidUpdate(prevProps, prevState) {
      const { selectedCalendarDate, isNewCalendar } = this.props;

      if (prevProps.isNewCalendar !== this.props.isNewCalendar && this.props.isNewCalendar) {
        this.props.clearHistory();
        this.props.clearPresent();
      }

      if (prevProps.globalEvents !== this.props.globalEvents) {
        const isFirstLoad = !Object.keys(prevProps.globalEvents).length;
        const events = this.props.globalEvents;
        this.setState({ events });

        const { isMounted } = this.state;
        const canAddFirstLoadEvents = isFirstLoad && isMounted;

        if (canAddFirstLoadEvents && !isNewCalendar) {
          this.setState({ isMounted: false });
          this.setState({ firstLoadedEvents: cloneDeep(events) });
        }

        if (!isNewCalendar) {
          this.props.toggleCalendarNewNess(false);
        }
      }

      // On Undo/Redo click update firebase to present snapshot.
      if (
        prevProps.snapshotId !== this.props.snapshotId &&
        prevProps.pastSnapshot.length !== this.props.pastSnapshot.length &&
        prevProps.futureSnapshot.length !== this.props.futureSnapshot.length
      ) {
        /**
        * For some reason, the first snapshotEvents is an empty array instead of the initial loaded events.
        * In order to go back to the initial state, this isEmpty check is needed in order to revert the first
        * Undo to the initial Firebase database state.
        */
        const snapshot = isEmpty(this.props.snapshotEvents)
          ? this.state.firstLoadedEvents
          : this.props.snapshotEvents;

        // Remove undefined eventIds
        if (snapshot.undefined) {
          delete snapshot.undefined;
        }
        // Remove null eventIds
        if (snapshot.null) {
          delete snapshot.null;
        }

        const updates = {
          [this.path.events()]: snapshot,
        };

        firebase
          .database()
          .ref()
          .update(updates)
          .then(() => console.log('updated firebase snapshot.'));
      }

      // If there is a new selected date from redux update date state.
      if (
        prevProps.selectedCalendarDate !== selectedCalendarDate &&
        prevState.date !== selectedCalendarDate
      ) {
        this.setState({
          date: this.props.selectedCalendarDate,
        });
        this.handleSlotSelect({ start: selectedCalendarDate });
      }
    }

    componentWillUnmount() {
      rebase.removeBinding(this.ref);
    }

    getUpdatedSnapshot = (event: Event, newData: any, remove: boolean = false) => {
      const { events } = this.state;
      if (remove) {
        delete events[event.id];
      } else {
        events[event.id] = {
          ...event,
          ...newData,
        };
      }

      return events;
    };

    handleEventDrop = (eventType, data) => {
      const {
        start: nextStart,
        event: { start: eventStart, end: eventEnd, id: eventId, type, ...event },
      } = data;
      if (type === 'outsideEvent') return;
      const dur = duration(eventStart, eventEnd);
      const nextEnd = addMilliseconds(nextStart, dur);
      const path = this.path.event(eventId);
      const newData = { start: nextStart, end: nextEnd };

      const events = this.getUpdatedSnapshot(event, newData);

      switch (eventType) {
        case 'drop': {
          this.props.addUndo(events);
          rebase.update(path, {
            data: newData,
          });
          break;
        }
        default: {
          return;
        }
      }
    };

    handleInlineEditEventTitle = data => {
      const { event, title } = data;
      const { id } = event;
      const path = this.path.event(id);
      const newData = { name: title };
      const events = this.getUpdatedSnapshot(event, newData);
      this.props.addUndo(events);
      rebase.update(path, {
        data: newData,
      });
    };

    handleEventResize = (type, { event, start, end }) => {
      const { start: s, end: e, id: eventId } = event;

      const [sHours, sMins] = [getHours(s), getMinutes(s)];
      const [eHours, eMins] = [getHours(e), getMinutes(e)];
      const isLeftDragged = getHours(start) === 0;
      const path = this.path.event(eventId);

      const [hours, mins, date] = isLeftDragged ? [sHours, sMins, start] : [eHours, eMins, end];
      const next = compose(format, fAddHours(hours), fAddMinutes(mins))(date);
      const data = isLeftDragged ? { start: next } : { end: next };

      const events = this.getUpdatedSnapshot(event, data);

      if (type === 'drop') {
        this.props.addUndo(events);
        rebase.update(path, { data });
      } else {
        const currentEvent = this.state.events[eventId];
        this.setState(assocPath(['events', eventId], { ...currentEvent, ...data }));
      }
    };

    handleOutsideEventDrop = ({ start, event: { id, duration, name, styles, ancestors = [] } }) => {
      const nextStart = defaultEventStart(start);
      const nextEnd = compose(format, fAddDays(duration - 1))(defaultEventEnd(start));
      // $FlowFixMe
      const events = Object.values(this.state.events).filter(({ start }) =>
        isSameDay(start, nextStart),
      );
      const sortedEvents = sortBy(prop('weight'))(events);
      const weight = last(sortedEvents) ? (last(sortedEvents).weight || 0) + 100 : 100;

      const eid = cuid();
      const newEvent: Event = {
        ancestors,
        end: nextEnd,
        eventTemplateId: id,
        id: eid,
        key: eid,
        locked: false,
        name: name,
        note: '',
        start: nextStart,
        styles,
        url: '',
        visible: true,
        weight,
      };
      const data = { ...this.state.events, [eid]: newEvent };

      firebase
        .database()
        .ref(this.path.events())
        // $FlowFixMe
        .set(data)
        .then(() => {
          const updates = {};
          this.props.addUndo(data);
          updates[this.path.redos()] = null;
          firebase
            .database()
            .ref()
            .update(updates);
        });
    };

    handleMenuItemClick = ({ item, date }) => {
      const { ui } = this.state;
      const nextUI = updateState({
        path: ['ui'],
        value: {
          ...ui,
          modalPushPull: { isOpen: true, type: 'events', categoryId: null },
          selectedDate: format(date),
        },
      });
      this.setState(nextUI);
    };

    handleOpenPushPullModal = () => {
      const { ui } = this.state;
      const nextUI = updateState({
        path: ['ui'],
        value: {
          ...ui,
          modalPushPull: { isOpen: true, type: 'multiple-events', categoryId: null },
        },
      });
      this.setState(nextUI);
    };

    eventsSorter = ({ weight: a }, { weight: b }) => a - b;

    handleEventMenuDelete = (_, { event }) => {
      const { id: eventId } = event;
      const path = this.path.event(eventId);
      const events = this.getUpdatedSnapshot(event, null, true);
      this.props.addUndo(events);
      rebase.remove(path);
    };

    handleLockUnlock = (_, { event }) => {
      const { id: eventId, locked: isLocked } = event;
      const data = { locked: !isLocked };
      const events = this.getUpdatedSnapshot(event, data);

      this.props.addUndo(events);
      rebase.update(this.path.event(eventId), {
        data,
      });
    };

    handleOpenEventModal = (_, { event }) => {
      this.props.openEventModal(event);
    };

    handleCopy = (_, { event: copiedEvent }) => {
      this.props.copyEvent(copiedEvent);
    };

    handlePaste = ({
      date: nextStart,
      event: { id, key, start: eventStart, end: eventEnd, ...event },
    }) => {
      const duration = differenceInMilliseconds(eventEnd, eventStart);
      const nextEnd = addMilliseconds(nextStart, duration);
      const [start, end] = [format(nextStart), format(nextEnd)];

      const updates = {};
      const newId = cuid();

      const data = {
        ...event,
        id: newId,
        start,
        end,
      };

      updates[this.path.event(newId)] = data;
      this.props.addUndo({ ...this.state.events, [newId]: data });

      firebase
        .database()
        .ref()
        .update(updates);
    };

    eventMenu = props => {
      const { id, trigger } = props;
      const { selectedEvents } = this.props;
      const hasSelectedEvents = !!selectedEvents.length && selectedEvents.length > 1;
      const isSingleEvent = trigger && !hasSelectedEvents;
      return (
        <ContextMenu id={id}>
          {trigger &&
            hasSelectedEvents && (
              <MenuItem onClick={this.handleOpenPushPullModal}>Push/Pull</MenuItem>
            )}
          {isSingleEvent && <MenuItem onClick={this.handleOpenEventModal}>Edit</MenuItem>}
          {isSingleEvent && (
            <MenuItem onClick={this.handleLockUnlock}>
              {trigger.event.locked ? 'Unlock' : 'Lock'}
            </MenuItem>
          )}
          {isSingleEvent && <MenuItem onClick={this.handleEventMenuDelete}>Delete</MenuItem>}
          {isSingleEvent && <MenuItem onClick={this.handleCopy}>Copy</MenuItem>}
        </ContextMenu>
      );
    };

    contextMenuItems = () => {
      let items = [
        {
          label: 'Push/pull from this day',
          data: { item: 1 },
          onClick: (e, props) => this.handleMenuItemClick(props),
        },
      ];

      const { copiedEvent } = this.props;
      if (copiedEvent) {
        items.push({
          label: 'Paste',
          data: { event: copiedEvent },
          onClick: (_, props) => this.handlePaste(props),
        });
      }
      return items;
    };

    dayCellMenu = props => {
      const { id, trigger } = props;
      return (
        <ContextMenu id={id}>
          {this.contextMenuItems().map(
            (m, i) =>
              trigger && (
                <MenuItem key={i} onClick={m.onClick} data={m.data}>
                  {m.label}
                </MenuItem>
              ),
          )}
        </ContextMenu>
      );
    };

    handleEventReorder = list => {
      let nextList = {};
      let updates = {};

      for (let index = 0; index < list.length; index++) {
        const { id, ...event } = list[index];
        const data = { ...event, id, weight: index + 1 };

        if (id) {
          updates[this.path.event(id)] = data;
          nextList[id] = data;
        }
      }

      this.setState(prevState => {
        const events = merge(prevState.events, nextList);
        this.props.addUndo(events);
        return { ...prevState, events };
      });

      firebase
        .database()
        .ref()
        .update(updates);
    };

    handleEventSelect = selectedEvent => {
      if (!selectedEvent || !selectedEvent.start) return;
      const { start } = selectedEvent;
      this.props.selectDayCell(start);
      this.props.selectEvent(selectedEvent);
    };

    handleSlotSelect = ({ start }) => {
      this.props.selectDayCell(format(start));
    };

    handleOutsideEventOrderAndDrop = event => {
      // side-effects for when an outside event is dropped on the calendar
      const updates = {};

      updates[`${this.path.eventTemplate(event.ancestors)}/visible`] = true;

      for (let i = 1; i < event.ancestors.length; i++) {
        const ancestors = event.ancestors.slice(0, i);
        const path = `${this.path.nestedCategory(ancestors)}/visible`;
        if (!updates[path]) {
          updates[path] = true;
        }
      }

      firebase
        .database()
        .ref()
        .update(updates);
    };

    handleShowTwoMonths = () => {
      this.setState({ showTwoMonths: !this.state.showTwoMonths });
    };

    handleNavigate = (date: Date) => {
      this.setState({ date });
      this.props.selectDate(new Date(date));
    };

    handleShiftSelect = (...events: any) => {
      this.props.selectEvents(events);
    };

    render() {
      // $FlowFixMe
      const events = Object.values(this.state.events).filter(e => e.visible);

      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          contextMenuComponents={{
            event: this.eventMenu,
            dayCell: this.dayCellMenu,
          }}
          events={events}
          eventsSorter={this.eventsSorter}
          handleEventDrop={this.handleEventDrop}
          handleEventReorder={this.handleEventReorder}
          handleEventResize={this.handleEventResize}
          handleEventSelect={this.handleEventSelect}
          handleInlineEditEventTitle={this.handleInlineEditEventTitle}
          handleNavigate={this.handleNavigate}
          handleOpenExportModal={this.props.openExportModal}
          handleOutsideEventDrop={this.handleOutsideEventDrop}
          handleOutsideEventOrderAndDrop={this.handleOutsideEventOrderAndDrop}
          handleShowTwoMonths={this.handleShowTwoMonths}
          handleSlotSelect={this.handleSlotSelect}
          handleShiftSelect={this.handleShiftSelect}
          selectedDate={this.state.date}
        />
      );
    }
  };
}

export default compose(withStore, withRouter, withHandlers);
