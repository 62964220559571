import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import type { PassedProps } from './container';

import {
  CloseButton,
  CloseIcon,
  Error,
  Field,
  Header,
  HR,
  Input,
  PrimaryButton,
  SecondaryButton,
} from '../Modal';

import {
  Color,
  ColorField,
  ColorPicker,
  Form,
  Header2,
  LockedIcon,
  LockedIconWrapper,
  RowBottom,
  StyledCalendarIcon,
  StyledDropdown,
  StyledEventModal,
  StyledFieldAndCalendar,
  StyledRow,
} from './components';

import { DROPDOWN_OPTIONS, COLORS } from '../constants';

import 'react-dropdown/style.css';

const TallError = Error.extend`
  min-height: 30px;
`;

export default function EventModal({
  closeEventModal,
  handleDelete,
  handleShowPicker,
  height = '640px',
  isEventModalOpen,
  showBackgroundPicker,
  showBorderPicker,
  showColorPicker,
  // FormikBag
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,
  handleSubmit,
  handleReset,
}: PassedProps) {
  const { styles = {} } = values;

  if (!isEventModalOpen) return null;

  return (
    <StyledEventModal isOpen={isEventModalOpen} height={height}>
      <Header>
        <Header2>Edit Event</Header2>
        <CloseButton
          onClick={() => {
            handleReset();
            closeEventModal();
          }}
          aria-label="close"
        >
          <CloseIcon name="close" width="15px" height="15px" />
        </CloseButton>
      </Header>
      <HR />
      <Form onSubmit={handleSubmit} key="form">
        <StyledRow>
          <Field label="Event Name">
            {isEventModalOpen && (
              <Input
                spellCheck={true}
                autoFocus
                id="name"
                name="name"
                onChange={handleChange}
                onFocus={e => {
                  let temp_value = e.target.value;
                  e.target.value = '';
                  e.target.value = temp_value;
                }}
                type="input"
                value={values.name}
                width="190px"
              />
            )}
            <TallError>{touched.name && errors.name ? errors.name : null}</TallError>
          </Field>
          <StyledFieldAndCalendar width="165px">
            <Field label="Starts On">
              <DatePicker
                onChange={date => {
                  setFieldValue('start', date.format());
                }}
                placeholderText="Click to select a date"
                selected={moment(values.start)}
                width="175px"
              />
              <TallError>{touched.start && errors.start ? errors.start : null}</TallError>
            </Field>
            <StyledCalendarIcon name="calendar" height="20px" width="20px" />
          </StyledFieldAndCalendar>
          <StyledFieldAndCalendar width="165px">
            <Field label="Ends On">
              <DatePicker
                onChange={date => {
                  setFieldValue('end', date.format());
                }}
                placeholderText="Click to select a date"
                selected={moment(values.end)}
                width="175px"
              />
              <TallError>{touched.end && errors.end ? errors.end : null}</TallError>
            </Field>
            <StyledCalendarIcon name="calendar" height="20px" width="20px" />
          </StyledFieldAndCalendar>
        </StyledRow>
        <StyledRow>
          <LockedIconWrapper>
            <LockedIcon
              name={values.locked ? 'locked' : 'unlocked'}
              height="20px"
              width="20px"
              onClick={() => {
                setFieldValue('locked', !values.locked);
              }}
            />
            <span>{values.locked ? 'Locked' : 'Unlocked'}</span>
          </LockedIconWrapper>
        </StyledRow>
        <StyledRow>
          <Field label="URL">
            <Input
              spellCheck={true}
              id="url"
              name="url"
              type="url"
              onChange={handleChange}
              value={values.url}
              width="100%"
            />
          </Field>
        </StyledRow>
        <StyledRow>
          <Field label="Note">
            <Input id="note" name="note" onChange={handleChange} value={values.note} width="100%" />
          </Field>
        </StyledRow>
        <StyledRow>
          <Field label="Font Color">
            <ColorField onClick={handleShowPicker('showColorPicker')}>
              <Color color={styles.color}>{COLORS[styles.color] || styles.color}</Color>
            </ColorField>
            {showColorPicker && (
              <ColorPicker
                color={styles.color}
                onChange={color => {
                  setFieldValue('styles.color', color.hex);
                  handleShowPicker('showColorPicker')();
                }}
                onMouseLeave={handleShowPicker('showColorPicker')}
              />
            )}
          </Field>
          <Field label="Font Style">
            <StyledDropdown
              onChange={selected => {
                setFieldValue('styles.fontStyle', selected.value);
              }}
              options={['normal', 'italic']}
              value={styles.fontStyle}
            />
          </Field>
          <Field label="Font Size">
            <StyledDropdown
              onChange={selected => {
                setFieldValue('styles.fontSize', selected.value);
              }}
              options={DROPDOWN_OPTIONS.FONT_SIZE}
              value={styles.fontSize}
            />
          </Field>
          <Field label="Font Weight">
            <StyledDropdown
              onChange={selected => {
                setFieldValue('styles.fontWeight', selected.value);
              }}
              options={DROPDOWN_OPTIONS.FONT_WEIGHT}
              value={styles.fontWeight}
            />
          </Field>
        </StyledRow>

        <StyledRow>
          <Field label="Background Color">
            <ColorField onClick={handleShowPicker('showBackgroundPicker')}>
              <Color color={styles.backgroundColor}>
                {COLORS[styles.backgroundColor] || styles.backgroundColor}
              </Color>
            </ColorField>
            {showBackgroundPicker && (
              <ColorPicker
                color={styles.backgroundColor}
                onChange={color => {
                  setFieldValue('styles.backgroundColor', color.hex);
                  handleShowPicker('showBackgroundPicker')();
                }}
                onMouseLeave={handleShowPicker('showBackgroundPicker')}
              />
            )}
          </Field>
          <Field label="Border Color">
            <ColorField onClick={handleShowPicker('showBorderPicker')}>
              <Color color={styles.borderColor}>
                {COLORS[styles.borderColor] || styles.borderColor}
              </Color>
            </ColorField>
            {showBorderPicker && (
              <ColorPicker
                color={styles.borderColor}
                onChange={color => {
                  setFieldValue('styles.borderColor', color.hex);
                  handleShowPicker('showBorderPicker')();
                }}
                onMouseLeave={handleShowPicker('showBorderPicker')}
              />
            )}
          </Field>
          <Field label="Border Style">
            <StyledDropdown
              options={DROPDOWN_OPTIONS.BORDER_STYLE}
              value={styles.borderStyle}
              onChange={selected => {
                setFieldValue('styles.borderStyle', selected.value);
              }}
            />
          </Field>
          <Field label="Border Width">
            <StyledDropdown
              onChange={selected => {
                setFieldValue('styles.borderWidth', selected.value);
              }}
              options={DROPDOWN_OPTIONS.BORDER_WIDTH}
              value={styles.borderWidth}
            />
          </Field>
        </StyledRow>
        <StyledRow>
          <Field label="Text Align">
            <StyledDropdown
              onChange={selected => {
                setFieldValue('styles.textAlign', selected.value);
              }}
              options={DROPDOWN_OPTIONS.TEXT_ALIGN}
              value={styles.textAlign}
            />
          </Field>
        </StyledRow>
        <RowBottom>
          <SecondaryButton
            type="button"
            aria-label="delete"
            onClick={() => handleDelete(values.id)}
          >
            Delete event
          </SecondaryButton>
          <SecondaryButton
            type="button"
            onClick={() => {
              handleReset();
              closeEventModal();
            }}
            aria-label="cancel"
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton aria-label="submit" type="submit">
            Save Changes
          </PrimaryButton>
        </RowBottom>
      </Form>
    </StyledEventModal>
  );
}
