import { parseNumber, parseWords } from './parsers';

export function sortByName(a: any, b: any) {
  if (!a.name || !b.name) {
    throw TypeError('Sort values should have a name field');
  }

  const wordsA = parseWords(a.name);
  const wordsB = parseWords(b.name);

  const numberA = parseNumber(a.name);
  const numberB = parseNumber(b.name);

  if (wordsA < wordsB || (wordsA < wordsB && numberA < numberB)) {
    return -1;
  }

  if (wordsA <= wordsB && numberA <= numberB) {
    return -1;
  }

  if (wordsA > wordsB || (wordsA > wordsB && numberA > numberB)) {
    return 1;
  }

  if (wordsA >= wordsB && numberA >= numberB) {
    return 1;
  }

  return 0;
}
