import msg from '../../messages';
// $FlowFixMe
import { ActionCreators } from 'redux-undo';
import type { Msg } from '../../messages';

type MsgAndPayload = {
  type: Msg,
  payload?: any,
};

export function addUndo(events: any): MsgAndPayload {
  return {
    type: msg.ADD_UNDO,
    payload: events,
  };
}

export function clearHistory(): MsgAndPayload {
  return {
    type: msg.CLEAR_UNDO_HISTORY,
    payload: ActionCreators.clearHistory,
  };
}

export function clearPresent(): MsgAndPayload {
  return {
    type: msg.CLEAR_UNDO_PRESENT,
  };
}
