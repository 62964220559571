import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import aperture from 'ramda/src/aperture';
import range from 'ramda/src/range';
import findLastIndex from 'ramda/src/findLastIndex';
import addDays from 'date-fns/add_days/index';
import addMonths from 'date-fns/add_months/index';
import addWeeks from 'date-fns/add_weeks/index';
import areRangesOverlapping from 'date-fns/are_ranges_overlapping/index';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days/index';
import differenceInCalendarMonths from 'date-fns/difference_in_calendar_months/index';
import endOfMonth from 'date-fns/end_of_month/index';
import endOfWeek from 'date-fns/end_of_week/index';
import format from 'date-fns/format/index';
import isWithinRange from 'date-fns/is_within_range/index';
import maxDate from 'date-fns/max/index';
import minDate from 'date-fns/min/index';
import startOfMonth from 'date-fns/start_of_month/index';
import startOfWeek from 'date-fns/start_of_week/index';
import isAfter from 'date-fns/is_after/index';

import { colors } from '../theme';
import Header from './Header';
import type { PassedProps as Props } from './container';

const jsPDF = window.jsPDF;
const html2pdf = window.html2pdf;
const html2canvas = window.html2canvas;

const maxStackedEventsPerCalendar = 36;
const paperInchesWidth = 11;
const safeAreaPixelHeight = 564;
const safeAreaPixelWidth = 729;
const safeAreaPixelPadding = 30;
const eventPixelHeight = 11;
const eventPixelPadding = 3;
const weekTopInchesPadding = 0.3;
const toInches = pixelValue =>
  pixelValue * paperInchesWidth / (safeAreaPixelWidth + safeAreaPixelPadding * 2);

const eventInchesHeight = toInches(eventPixelHeight);
const eventInchesPadding = toInches(eventPixelPadding);
const safeAreaInchesHeight = toInches(safeAreaPixelHeight);
const safeAreaInchesWidth = toInches(safeAreaPixelWidth);

const findMissingLowest = (array, start = 0) =>
  Array.isArray(array)
    ? array.indexOf(start) < 0 ? start : findMissingLowest(start + 1, array)
    : 0;

const Main = ({ className, children, onMount }) => (
  <main className={className} ref={el => onMount(el)}>
    {children}
  </main>
);

const StyledMain = styled(Main)`
  display: flex;
  flex-direction: column;
  padding: ${safeAreaPixelPadding}px;
  padding-bottom: 0;
  * {
    font-family: sans-serif;
  }
`;

const StyledPage = styled.div`
  height: 8.5in;
  width: 11in;
  display: flex;
  align-items: center;
`;

const SafeArea = styled.div`
  border-top: 1px solid ${colors('gray')};
  display: ${props => (props.lastPage ? 'block' : 'flex')};
  flex-direction: ${props => (props.lastPage ? 'unset' : 'column')};
  height: ${safeAreaInchesHeight}in;
  overflow: hidden;
  width: ${safeAreaInchesWidth}in;
  margin: 0 auto;
`;

const Calendar = styled.div`
  border-left: 1px solid ${colors('gray')};
  display: block;

  ${props =>
    props.firstPage &&
    css`
      display: flex;
      flex-direction: column;
      flex: 1;
    `};
`;

const WeekRow = styled.div`
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex: auto;
  position: relative;
  width: 100%;

  ${props =>
    props.firstPage &&
    css`
      height: 100%;
    `};
`;

const Event = styled.span`
  box-sizing: border-box;
  display: ${props => (props.duration ? 'block' : 'none')};
  left: ${props => safeAreaInchesWidth / 7 * props.distanceFromWeekStart}in;
  margin: 0 ${eventInchesPadding}in 0 ${eventInchesPadding}in;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: ${props => eventInchesHeight * props.index + weekTopInchesPadding}in;
  white-space: nowrap;
  width: ${props => safeAreaInchesWidth / 7 * props.duration - 2 * eventInchesPadding}in;
  z-index: 666;
  font-family: Arial, Helvetica, sans-serif;
`;

const Day = styled.div`
  border-bottom: 1px solid ${colors('gray')};
  border-right: 1px solid ${colors('gray')};
  position: relative;
  width: ${safeAreaInchesWidth / 7}in;
  z-index: 333;
  ::after {
    content: '${props => props.number}';
    font-size: .77em;
    position: absolute;
    top: 8px;
    left: 8px;
    display: ${props => (props.isWithinMonth ? 'block' : 'none')};
  }
  ::before {
    background-color: ${colors('gray-light')};
    content: '';
    display: ${props => (props.isWithinMonth ? 'none' : 'block')};
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const overlaps = ({ right, left }) => ({ right: r, left: l }) => r >= left && right >= l;

const segment = (weekStart, monthStart, weekEnd, monthEnd, event) => {
  const { start, end } = event;
  const actualStart = maxDate(start, monthStart, weekStart);
  const actualEnd = minDate(end, monthEnd, weekEnd);
  const left = differenceInCalendarDays(actualStart, weekStart) + 1;
  const right = differenceInCalendarDays(actualEnd, weekStart) + 1;
  return { left, right, span: right - left + 1, event };
};

export default class Export extends Component<Props, void> {
  main: any;

  componentWillReceiveProps({ location, loading, events, config, callback }: Props) {
    // Don't generate the PDF when seeing the `/export` URL (that exists for development)
    if (!loading && config && events && !location.pathname.endsWith('/export')) {
      window.setTimeout(() => {
        if (!this.main) return;
        const { fileName, title, episodes, calendarType } = this.props.config;
        const today = format(new Date(), 'MM-DD-YY');
        const fileNameTemplate = `${title} ${episodes} ${calendarType} ${today}`;

        this.renderPdf([].slice.call(this.main.childNodes), fileName || fileNameTemplate, callback);
      }, 700);
    }
  }

  renderPdf = (pages: any[], filename: string, cb: any) => {
    const opt = {
      margin: 0.0,
      filename: '' + (filename || 'download') + '.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        dpi: 192,
        letterRendering: true,
      },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
    };

    html2pdf.parseInput('noop', opt);
    var pageSize = jsPDF.getPageSize(opt.jsPDF);
    pageSize.inner = {
      // $FlowFixMe
      width: pageSize.width - opt.margin[1] - opt.margin[3],
      // $FlowFixMe
      height: pageSize.height - opt.margin[0] - opt.margin[2],
    };

    // $FlowFixMe
    pageSize.inner.ratio = pageSize.inner.height / pageSize.inner.width;

    var canvases = pages.map((source, i) => {
      var idx = i;
      return new Promise((resolve, _reject) => {
        var container = html2pdf.makeContainer(source, pageSize);
        var overlay = container.parentElement;

        var onrendered = canvas => {
          // $FlowFixMe
          document.body.removeChild(overlay);
          var imgData = this.getCanvasData(canvas, pageSize, { ...opt, pageNumber: idx });
          resolve([idx, imgData]);
        };

        html2canvas(container, { ...opt.html2canvas, onrendered });
      });
    });

    Promise.all(canvases).then(results => {
      var pages: any[] = results.reduce((acc, pageTupple) => {
        acc[pageTupple[0]] = pageTupple[1];
        return acc;
      }, []);

      var pdf = new jsPDF(opt.jsPDF);

      pages.forEach((pageData, i) => {
        if (i) pdf.addPage();
        pdf.addImage(
          pageData,
          opt.image.type,
          // $FlowFixMe
          opt.margin[1],
          // $FlowFixMe
          opt.margin[0],
          pageSize.inner.width,
          pageSize.inner.height,
        );
      });

      pdf.save(opt.filename);
      if (cb) cb();
    });
  };

  getCanvasData = (canvas: any, pageSize: any, opt: any) => {
    var pxPageHeight = Math.floor(canvas.width * pageSize.inner.ratio);

    // Create a one-page canvas to split up the full image.
    var pageCanvas = document.createElement('canvas');
    var pageCtx = pageCanvas.getContext('2d');
    pageCanvas.width = canvas.width;
    pageCanvas.height = pxPageHeight;

    // Display the page.
    var w = pageCanvas.width;
    var h = pageCanvas.height;
    pageCtx.fillStyle = 'white';
    pageCtx.fillRect(0, 0, w, h);
    pageCtx.drawImage(canvas, 0, 0, w, h, 0, 0, w, h);
    return pageCanvas.toDataURL('image/' + opt.image.type, opt.image.quality);
  };

  render() {
    const { config, events = [], loading } = this.props;
    if (loading || !events || !config) return null;

    const from = new Date(config.from + 'T00:00:00');
    const to = new Date(config.to + 'T23:59:59');

    const monthsRange = range(0, differenceInCalendarMonths(to, from) + 1).map(idx => {
      const monthStart = addMonths(startOfMonth(from), idx);
      const monthEnd = endOfMonth(monthStart);

      if (isWithinRange(to, monthStart, monthEnd)) return [monthStart, to];
      return idx === 0 ? [from, monthEnd] : [monthStart, monthEnd];
    });

    // filter and group events by month
    const eventsByMonth = monthsRange.map(([monthStart, monthEnd], idx) =>
      events.filter(event => event.visible).reduce((acc, event) => {
        const { start, end } = event;
        if (isAfter(start, end)) return acc; // corrupt data
        if (areRangesOverlapping(start, end, monthStart, monthEnd)) {
          acc.push(event);
        }
        return acc;
      }, []),
    );

    return (
      <StyledMain className="main" onMount={el => (this.main = el)}>
        {/* Determines how many months are covered by the given range */}
        {[...Array(differenceInCalendarMonths(to, from) + 1)].map((_, monthIndex) => {
          const monthStart = addMonths(startOfMonth(from), monthIndex);
          const monthFirstWeekStart = startOfWeek(monthStart);
          const monthEnd = endOfMonth(monthStart);
          // Determines if we need 6 or 5 rows for each month
          const monthCalendarWeekAmount =
            differenceInCalendarDays(monthEnd, monthFirstWeekStart) > 34 ? 6 : 5;
          const monthEvents = eventsByMonth[monthIndex];

          /*
            An array of event arrays. One array per week.
          */
          const eventsByWeek = [...Array(monthCalendarWeekAmount)].map((_, weekIndex) => {
            const weekStart = addWeeks(monthFirstWeekStart, weekIndex);
            const weekEnd = endOfWeek(weekStart);
            return monthEvents.filter(event =>
              areRangesOverlapping(event.start, event.end, weekStart, weekEnd),
            );
          });

          // calculate levels beforehand
          const eventSegLevelsByWeek = eventsByWeek.map((weekEvents, weekIndex) => {
            const weekStart = addWeeks(monthFirstWeekStart, weekIndex);
            const weekEnd = endOfWeek(weekStart);
            return weekEvents.sort(({ weight: a }, { weight: b }) => a - b).reduce((acc, event) => {
              const seg = segment(weekStart, monthStart, weekEnd, monthEnd, event);
              const hostLevelIndex = findLastIndex(lvl => lvl.some(overlaps(seg)), acc) + 1;

              const hostLvl = acc[hostLevelIndex] || [];
              hostLvl.push(seg);
              acc[hostLevelIndex] = hostLvl;
              return acc;
            }, []);
          });

          /*
            An array containing the amount of vertically stacked events per week
          */
          const rowsPerWeek = eventSegLevelsByWeek.map(weekLevels => weekLevels.length);

          /*
            An array of week indexes in which the maxStackedEventsPerCalendar variable
            is surpassed and we need a new page.
          */
          const breakPoints = [
            0,
            ...rowsPerWeek.reduce(
              (acc, value, index) => {
                const sum = acc[1] + value;
                const found = sum >= maxStackedEventsPerCalendar;
                return [found ? [...acc[0], index] : acc[0], found ? 0 : sum];
              },
              [[], 0],
            )[0],
            monthCalendarWeekAmount,
          ];

          /*
            aperture(2, [1, 2, 3, 4, 5]); //=> [[1, 2], [2, 3], [3, 4], [4, 5]]
            With `apertures` we render each segment in between breakpoints.
          */
          return aperture(2, breakPoints).map(([startWeekIndex, endWeekIndex], idx) => (
            <StyledPage className="safe-area">
              <SafeArea
                key={`${monthStart}-${startWeekIndex}`}
                lastPage={breakPoints.length > 2 && endWeekIndex === monthCalendarWeekAmount}
              >
                <Header
                  {...this.props.config}
                  className="header"
                  month={format(monthStart, 'MMMM')}
                  year={format(monthStart, 'YYYY')}
                />
                <Calendar className="calendar" firstPage={idx === 0}>
                  {range(startWeekIndex, endWeekIndex).map(weekIndex => {
                    /*
                    An array in which each index is a level, each level contains
                    all events that haven't been indexed yet and that don't overlap.
                    Events are ordered by `weight` from the moment we retrive them
                    from firebase. See `./container.js`.
                  */
                    const weekStart = addWeeks(monthFirstWeekStart, weekIndex);
                    const eventsByLevel = eventSegLevelsByWeek[weekIndex];

                    const weekRowMinHeight =
                      eventInchesHeight * eventsByLevel.length + weekTopInchesPadding + 'in';

                    return (
                      <WeekRow
                        firstPage={idx === 0}
                        className="week-row"
                        key={`${monthStart}-${weekStart}`}
                        style={{
                          minHeight: `${weekRowMinHeight}`,
                        }}
                      >
                        {eventsByLevel.map(
                          (events, level) =>
                            // Only render the level if it's not over the limit
                            level <= maxStackedEventsPerCalendar &&
                            events.map(({ event, span, right, left }) => {
                              return (
                                <Event
                                  className="event"
                                  key={event.id}
                                  style={{
                                    ...event.styles,
                                    fontSize: `${eventInchesHeight * 0.666}in`,
                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                  }}
                                  duration={span}
                                  distanceFromWeekStart={left - 1}
                                  index={level}
                                >
                                  {event.name}
                                </Event>
                              );
                            }),
                        )}
                        {[...Array(7)].map((_, dayIndex) => {
                          const day = addDays(weekStart, dayIndex);
                          const isDayWithinMonth = isWithinRange(day, monthStart, monthEnd);
                          return (
                            <Day
                              className="day"
                              key={`${monthStart}-${weekStart}-${day}`}
                              number={format(day, 'D')}
                              isWithinMonth={isDayWithinMonth}
                            />
                          );
                        })}
                      </WeekRow>
                    );
                  })}
                </Calendar>
              </SafeArea>
            </StyledPage>
          ));
        })}
      </StyledMain>
    );
  }
}
