import React, { Component } from 'react';
import Dropdown from 'react-dropdown';
import prop from 'ramda/src/prop';
import styled from 'styled-components';

import { colors } from './theme';
import Icon from './Icon';

// prettier-ignore
const Modal = styled.div`
  ${props => props.embeded
    ? ''
    : `
      position: absolute;
      display: ${props.isOpen ? 'block' : 'none'};
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: auto 0;
      border: 1px solid ${colors('gray-light')};
      border-radius: 0.2em;
      background-color: #fff;
      z-index: 30;
      padding: 10px;
      font-family: ${props.fontFamily};
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
      width: ${props => props.width || "auto"};
  `};
`;

export const Row = styled.div`
  align-items: ${props => (props.align ? props.align : 'center')};
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  border-bottom: 1px solid ${colors('silver')};
  padding-bottom: 20px;
  margin-bottom: 20px;
  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  & > * {
    flex: 1;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  h3 {
    margin: 0;
  }
`;

export const Header2 = styled.h2`
  margin: 0;
  font-weight: 300;
`;

export const PrivateInput = styled.input`
  border-radius: 0.2em;
  border: 2px solid ${props => props.borderColor || colors('gray-light')};
  box-sizing: border-box;
  flex: 2;
  font-size: 14px;
  padding: ${props => props.padding || '5px'};
  text-align: left;
  width: ${prop('width') || 'auto'};
  &:focus,
  &:active {
    outline: none;
    border: 2px solid ${colors('teal')};
  }
`;

export const TextArea = styled.textarea`
  border-radius: 0.2em;
  border: 2px solid #${colors('gray-light')};
  box-sizing: border-box;
  font-size: 14px;
  padding: 5px;
  text-align: left;
  width: 100%;
  &:focus,
  &:active {
    outline: none;
    border: 2px solid ${colors('teal')};
  }
`;

export const Button = styled.button`
  background: transparent;
  border: none;
  font-size: 12px;
  font-weight: bold;
  margin: ${prop('margin') || 0};
  text-transform: uppercase;
  &:focus,
  &:active {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const PrimaryButton = Button.extend`
  background-color: ${props =>
    props.disabled ? colors('grey', props.theme) : colors('teal', props.theme)};
  border-radius: 0.2em;
  color: #ffffff;
  padding: 1em;
`;

export const SecondaryButton = Button.extend`
  color: ${colors('teal')};
`;

export const CloseButton = styled.button`
  border: 0;
  background-color: ${colors('white')};
  font-size: 14px;
  color: #ddd;
  &:focus,
  &:active {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const FieldGroupContainer = styled.div`
  > div {
    display: flex;
    flex-direction: ${props => (props.horizontal ? 'row' : 'column')};
    margin: ${props => props.margin || '0'};
  }
  > strong {
    border-bottom: 1px solid ${colors('silver')};
    display: block;
    margin: 0 0 5px;
    padding-bottom: 5px;
  }
`;

export const NumberInputWrapper = styled.div`
  display: flex;

  & :first-child {
    border-top-left-radius: 0.2em;
    border-bottom-left-radius: 0.2em;
  }

  & :first-child + span + span {
    border-top-right-radius: 0.2em;
    border-bottom-right-radius: 0.2em;
  }
`;

export const NumberField = styled.input`
  outline: none;
  font-size: 14px;
  padding: 5px;
  width: 30px;
  border: 2px solid ${colors('gray-light')};
`;

export const NumberButton = styled.span`
  font-size: 14px;
  color: black;
  padding: 8px 8px;
  width: 24px;
  line-height: 1;
  text-align: center;
  border: 2px solid ${colors('gray-light')};
  border-left: none;

  :hover {
    color: white;
    background-color: ${colors('teal')};
  }
`;

export const NumberUnits = styled.span`
  font-size: 16px;
  margin: auto 0 auto 23px;
`;

export const NumberInput = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || 1,
      hasFocus: props.hasFocus || false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value });
    }

    if (nextProps.hasFocus && !this.state.hasFocus) {
      const { hasFocus } = nextProps;
      this.setState({ hasFocus }, () => {
        this.inputRef.focus();
        this.inputRef.select();
      });
    }

    if (!nextProps.hasFocus && this.state.hasFocus) {
      this.setState({ hasFocus: false, value: 1 });
    }
  }

  _mockEvent(value) {
    const { name } = this.props;
    return { target: { name, value }, persist: () => {} };
  }

  handleChange = ({ target: { value } }) => {
    if (value === '' || value === '-') {
      this.setState({ value });
      this.props.onChange(this._mockEvent(value));
      return;
    }

    const num = parseInt(value, 10);
    if (isNaN(num)) return;

    this.setState({ value: num });
    this.props.onChange(this._mockEvent(num));
  };

  handleIncrement = () => {
    let { value } = this.state;
    if (value === '-') value = 0;
    const num = +value + 1;
    this.setState({ value: num });
    this.props.onChange(this._mockEvent(num));
  };

  handleDecrement = () => {
    let { value } = this.state;
    if (value === '-') value = 0;
    const num = +value - 1;
    this.setState({ value: num });
    this.props.onChange(this._mockEvent(num));
  };

  handleKeyDown = e => {
    const { value } = this.state;
    if (e.key === 'Enter' && value === '') {
      e.preventDefault();
      return false;
    }
  };

  render() {
    const { value } = this.state;
    return (
      <NumberInputWrapper>
        <NumberField
          innerRef={el => {
            this.inputRef = el;
          }}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          type="input"
          value={value}
        />
        <NumberButton onClick={this.handleIncrement}>+</NumberButton>
        <NumberButton onClick={this.handleDecrement}>-</NumberButton>
        {this.props.units && <NumberUnits>{this.props.units}</NumberUnits>}
      </NumberInputWrapper>
    );
  }
};

export const FieldGroup = ({ title, children, ...other }) => (
  <FieldGroupContainer {...other}>
    {title && <strong>{title}</strong>}
    <div>{children}</div>
  </FieldGroupContainer>
);

export const Input = ({ type, ...rest }) => {
  switch (type) {
    case 'number':
      return <NumberInput {...rest} />;
    default:
      return <PrivateInput type={type} {...rest} />;
  }
};

export const Field = styled(({ label, children, ...other }) => (
  <FieldGroup {...other}>
    <label htmlFor={label}>{label}</label>
    {children}
  </FieldGroup>
))`
  margin-right: 20px;
  label {
    margin-bottom: 5px;
    font-size: 0.9em;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const CloseIcon = styled(Icon)`
  background-color: #ddd;
  &:hover {
    cursor: pointer;
  }
`;

export const CalendarIcon = styled(Icon)`
  background-color: ${colors('gray-light')};
`;

export const HR = styled.hr`
  border: 0;
  height: 1px;
  background: #ddd;
  width: 100%;
`;

const DropdownWrapper = props => <Dropdown {...props} />;

export const StyledDropdown = styled(DropdownWrapper)`
  width: 130px;
`;

export const IconClose = ({ onClick }) => (
  <CloseIcon name="close" width="15px" height="15px" onClick={onClick} />
);

export const Error = styled.div`
  color: red;
  padding-top: 5px;
  font-size: 10px;
  min-height: 15px;
`;

export const Checkbox = styled.div`
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-top: 0.125rem;

  input[type='checkbox'] {
    border-radius: 0.25em;
    &:hover {
      cursor: pointer;
    }
  }

  label {
    display: inline-block;
    margin-left: 10px;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const VarMetadata = styled.span`
  color: ${colors('gray')};
  font-size: 12px;
`;

export default Modal;
