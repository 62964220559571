import React, { Component } from 'react';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { Subscriber } from 'react-broadcast';
import { propOr } from 'ramda';

import { PathBuilder, calendarId } from '../../helpers';
import rebase, { firebase } from '../../rebase';
import TreeItem from './TreeItem';
import type { ItemType } from './types';
import type {
  Category,
  Event as EventType,
  EventTemplate,
  FirebaseCategories,
  FirebaseEvents,
  Styles,
} from '../../models';
import type { PathObject } from '../../helpers';

type Item = {
  categories: Array<Category>,
  eventTemplates: Array<EventTemplate>,
  id: string,
  name: string, // should we change to label
  styles: Styles,
  type: ItemType,
  visible: boolean,
  path: Array<string>,
  locked: boolean,
};

type TreeProps = {
  calendarCategories: FirebaseCategories,
  calendarEvents: FirebaseEvents,
  items: Array<Item>,
  level: number,
  openModal: (params: { type: string }) => void,
  resetSidebarSelections: () => void,
};

type State = {
  events: EventType[],
};

class Tree extends Component<TreeProps, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      events: [],
    };
  }

  ref: () => mixed;
  path: PathObject;

  componentDidMount() {
    this.path = new PathBuilder(calendarId(this.props));

    this.ref = rebase.syncState(this.path.events(), {
      context: this,
      state: 'events',
      asArray: true,
    });
  }

  componentWillMount() {
    document.addEventListener('keydown', this.resetTreeItemSelection);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.resetTreeItemSelection);
    rebase.removeBinding(this.ref);
  }

  resetTreeItemSelection = (e: Event) => {
    if (e.key !== 'Escape') return;
    this.props.resetSidebarSelections();
  };

  handleShowHideEventsMany = ({
    id,
    type = 'category',
    visible,
  }: {
    id: string,
    type?: ItemType,
    visible: boolean,
  }) => {
    if (type === 'event-template') {
      const updates = this.state.events.reduce((acc, event) => {
        if (event.eventTemplateId === id) {
          acc[`${this.path.event(event.id)}/visible`] = visible;
          acc[`${this.path.eventTemplate(event.ancestors)}/visible`] = visible;

          for (let i = 1; i < event.ancestors.length; i++) {
            const ancestors = event.ancestors.slice(0, i);
            const path = `${this.path.nestedCategory(ancestors)}/visible`;
            if (!acc[path] && visible) {
              acc[path] = visible;
            }
          }
        }

        return acc;
      }, {});

      firebase
        .database()
        .ref()
        .update(updates);
    } else {
      const updates = this.state.events.reduce((acc, event) => {
        if (propOr([], 'ancestors', event).includes(id)) {
          acc[`${this.path.event(event.id)}/visible`] = visible;
          acc[`${this.path.eventTemplate(event.ancestors)}/visible`] = visible;

          // The last item in the ancestors array is the eventTemplateId which we don't need - AR 18-03-09
          for (let i = 1; i < event.ancestors.length; i++) {
            const ancestors = event.ancestors.slice(0, i);
            const path = `${this.path.nestedCategory(ancestors)}/visible`;
            /**
             * If the action is visible, change the visbility of the parent.
             * If the action is !visible, do not change the visibility of the parent.
             * The functionality of showing/hiding the eye icon next to a folder is based
             * on the idea that if all items are invisible then show the eye-slash (invisible) icon
             * and if there is a mix of visible/invisible items then show the eye (visible) icon.
             * Hopefully the conditional statement below makes more sense and doesn't make you want
             * to pull your hair out.
             * The !acc[path] is so that I don't make duplicate paths, i.e. make multiple duplicate
             * calls to the Firebase API.
             */
            if ((!acc[path] && ancestors.includes(id) && !visible) || (!acc[path] && visible)) {
              acc[path] = visible;
            }
          }
        }
        return acc;
      }, {});

      firebase
        .database()
        .ref()
        .update(updates);
    }
  };

  handleLockEventsMany = ({
    id,
    type = 'category',
    locked,
    isParent,
    level,
  }: {
    id: string,
    type?: ItemType,
    locked: boolean,
    isParent: boolean,
    level: number,
  }) => {
    let updates = {};

    if (type === 'event-template') {
      updates = this.state.events.reduce((acc, event) => {
        if (event.eventTemplateId === id) {
          acc[`${this.path.event(event.id)}/locked`] = locked;
          acc[`${this.path.eventTemplate(event.ancestors)}/locked`] = locked;
        }
        return acc;
      }, {});
    } else {
      // Lock/Unlock all events
      updates = this.state.events.reduce((acc, event) => {
        if (propOr([], 'ancestors', event).includes(id)) {
          acc[`${this.path.event(event.id)}/locked`] = locked;
          // Lock/Unlock Event Template
          acc[`${this.path.eventTemplate(event.ancestors)}/locked`] = locked;
          // Lock/Unlock Ancestors
          // Something to have in mind is that ancestors work like trees.
          // First item (left) is a child.
          // Second item (center) is the root.
          // Third item (right) is a child.
          for (let i = 1; i < event.ancestors.length; i++) {
            const ancestors = event.ancestors.slice(0, i);
            const path = `${this.path.nestedCategory(ancestors)}/locked`;
            if ((!acc[path] && ancestors.includes(id) && !locked) || (!acc[path] && locked)) {
              acc[path] = locked;
            }
          }
        }
        return acc;
      }, {});
    }

    // We should lock/unlock parents
    if (!isParent) {
      this.setUpperLevelLockedStatus(updates, level);
    }

    firebase
      .database()
      .ref()
      .update(updates);
  };

  setUpperLevelLockedStatus = (currentPaths: any, level: number) => {
    const [longestCategoryPath] = Object.keys(currentPaths).filter(key =>
      key.includes('categories'),
    );
    const ids = longestCategoryPath
      .replace(/categories/g, '')
      .split('/')
      .filter(match => match !== '');

    const paths = [];
    let path = `categories/${ids[0]}`;

    for (let index = 0; index < level; index++) {
      path += `/categories/${ids[index + 1]}`;
      paths.push(path);
    }
    paths.map(p => (currentPaths[`${p}/locked`] = false));
  };

  handleOpenQuickBuildModal = () => this.props.openModal({ type: 'quick-build' });

  render() {
    const { items, level } = this.props;

    return (
      <div>
        {items.map(
          ({ id, type, name, categories, eventTemplates, styles, visible, path, locked }) => (
            <Subscriber key={`tree-item-subscriber-${id}`} channel="firebase">
              {({ categories: calendarCategories, events: calendarEvents }) => {
                return (
                  <TreeItem
                    calendarCategories={calendarCategories}
                    calendarEvents={calendarEvents}
                    ancestors={[id]}
                    categories={categories}
                    eventTemplates={eventTemplates}
                    handleShowHideEventsMany={this.handleShowHideEventsMany}
                    handleLockEventsMany={this.handleLockEventsMany}
                    id={id}
                    key={id}
                    label={name}
                    level={level || 0}
                    styles={styles}
                    type={type}
                    visible={visible}
                    path={path}
                    locked={locked}
                  />
                );
              }}
            </Subscriber>
          ),
        )}
        <ContextMenu id="sidebar-context-menu">
          <MenuItem onClick={this.handleOpenQuickBuildModal}>Quick Build</MenuItem>
        </ContextMenu>
      </div>
    );
  }
}

export default Tree;
