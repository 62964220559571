import React from 'react';
import { prop } from 'ramda';
import { SketchPicker } from 'react-color';
import Dropdown from 'react-dropdown';
import styled from 'styled-components';

import { colors } from '../theme';
import { DROPDOWN_OPTIONS } from '../constants';
import { isCloserToBlack } from '../../helpers';
import Icon from '../Icon';
import Modal, { Row } from '../Modal';

import 'react-datepicker/dist/react-datepicker.css';

export const StyledCalendarIcon = styled(Icon)`
  background-color: ${colors('gray-light')};
  position: absolute;
  top: 25px;
  right: 30px;
`;

export const StyledRow = Row.extend`
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

export const StyledEventModal = Modal.extend`
  z-index: 999;
  width: 600px;
  height: ${prop('height')};
  flex-direction: column;
`;

export const ColorPickerWrapper = styled.div`
  position: absolute;
  z-index: 2;
  .sketch-picker > div:nth-child(1) {
    display: none !important;
  }
  .sketch-picker > div:nth-child(2) {
    display: none !important;
  }
`;

// $FlowFixMe
export const ColorPicker = ({ onMouseLeave, onChange, color }) => (
  <ColorPickerWrapper onMouseLeave={onMouseLeave}>
    <SketchPicker
      color={color}
      disableAlpha={true}
      onChangeComplete={onChange}
      presetColors={DROPDOWN_OPTIONS.COLORS_PRESETS}
      triangle="hide"
      width={250}
    />
  </ColorPickerWrapper>
);

export const RowBottom = Row.extend`
  position: absolute;
  padding-bottom: 10px;
  bottom: 0;
  left: 0;
  width: 98%;
  &:last-of-type {
    padding-bottom: 10px;
  }
`;

export const StyledFieldAndCalendar = styled.div`
  position: relative;
  input {
    border: 2px solid ${colors('gray-light')};
    border-radius: 0.2em;
    padding: 5px;
    font-size: 14px;
    width: ${prop('width') || 'auto'};
    &:focus,
    &:active {
      outline: none;
      border: 2px solid ${colors('teal')};
    }
  }
  .react-datepicker__day--selected {
    background-color: ${colors('teal')};
    &:hover {
      background-color: ${colors('teal')};
    }
  }
`;

export const Header2 = styled.h2`
  margin: 0;
  font-weight: 300;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ColorField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors('gray-light')};
  border-radius: 2px;
  width: 130px;
  height: 36px;
  box-sizing: border-box;
`;

export const Color = styled.div`
  border-radius: 1px;
  color: ${props => (props && props.color && isCloserToBlack(props.color) ? '#FFFFFF' : '#000000')};
  width: 100%;
  height: 100%;
  background-color: ${prop('color')};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LockedIcon = styled(Icon)`
  background-color: #000;
  display: inline-block;
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
`;

export const LockedIconWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    min-width: 80px;
    padding-top: 5px;
    user-select: none;
  }
`;

const DropdownWrapper = props => <Dropdown {...props} />;

export const StyledDropdown = styled(DropdownWrapper)`
  width: 130px;
`;
