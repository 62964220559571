import { createSelector } from 'reselect';

const uiPath = state => state.ui;

export const isAddCalendarModalOpen = createSelector(uiPath, ui => ui.isAddCalendarModalOpen);

export const isEventModalOpen = createSelector(uiPath, ui => ui.isEventModalOpen);

export const isCategoryModalOpen = createSelector(uiPath, ui => ui.isCategoryModalOpen);

export const isExportModalOpen = createSelector(uiPath, ui => ui.isExportModalOpen);

export const isAddingCategory = createSelector(uiPath, ui => ui.isAddingCategory);

export const event = createSelector(uiPath, ui => ui.event);

export const categoryId = createSelector(uiPath, ui => ui.categoryId);

export const selectedDate = createSelector(uiPath, ui => ui.selectedDate);

export const selectedEvent = createSelector(uiPath, ui => ui.selectedEvent);

export const copiedEvent = createSelector(uiPath, ui => ui.copiedEvent);

export const sidebarSelectionsSelector = createSelector(
  uiPath,
  ({ sidebar }) => sidebar.selections,
);

export const isEventTemplateSelected = (path: string) =>
  createSelector(
    sidebarSelectionsSelector,
    ({ type, items }) => type === 'event-template' && !!items[path],
  );

export const modalSelector = createSelector(uiPath, ui => ui.modal);

export const isUsersModalOpen = createSelector(uiPath, ui => ui.isUsersModalOpen);

export const isNewCalendar = createSelector(uiPath, ui => ui.isNewCalendar);

export const selectedEvents = createSelector(uiPath, ui => ui.selectedEvents);
