import msg from '../../messages';
import type { Msg } from '../../messages';

type MsgAndPayload = {
  type: Msg,
  payload: any,
};

export function selectDate(date: Date): MsgAndPayload {
  return {
    type: msg.SELECT_DATE,
    payload: date,
  };
}
