import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DragSource, DropTarget } from 'react-dnd';
import { withRouter } from 'react-router-dom';
import { withStateHandlers, compose } from 'recompose';
import cuid from 'cuid';

import { PathBuilder, calendarId } from '../../../helpers';
import type { PathObject } from '../../../helpers';
import { openModal } from '../../../store/actions';
import type { ModalType, CategoryConfigEdit } from '../../../store/types';
import { updateState } from '../../stateHandlers';
import { ItemTypes } from '../types';
import rebase, { firebase } from '../../../rebase';
import type { FirebaseEvents, FirebaseCategories } from '../../../models';

const withStore = connect(null, { openModal });

const eventSource = {
  beginDrag({ id, label, duration, styles, ancestors, ...props }, monitor, component) {
    const data = {
      ancestors,
      duration,
      id,
      name: label,
      styles,
      type: 'outsideEvent',
    };
    const position = {
      span: duration,
      left: 0,
      right: 0,
      level: 0,
    };
    return { data, type: 'outsideEvent', position };
  },
};

const eventTarget = {
  drop(props, monitor, component) {
    return {};
  },
};

const dragAndDrop = compose(
  DropTarget(ItemTypes.EVENT, eventTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
  })),
  DragSource(ItemTypes.EVENT, eventSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })),
);

function handlers(WrappedComponent) {
  type Modal = 'events' | 'category-events';

  type State = {
    ui: {
      modalPushPull: {
        isOpen: boolean,
        type: Modal,
        categoryId: string,
      },
    },
  };

  type Props = {
    calendarCategories: FirebaseCategories,
    calendarEvents: FirebaseEvents,
    id: string,
    label: string,
    match: { params: { id: string } },
    openModal: (props: CategoryConfigEdit & { type: ModalType }) => mixed,
    path: string[],
    styles: any,
  };

  return class TreeItemEnhancer extends Component<Props, State> {
    constructor(props) {
      super(props);
      this.state = {
        ui: {
          modalPushPull: {
            isOpen: false,
            type: 'events',
            categoryId: '',
          },
        },
      };
    }

    ref: any;
    path: PathObject;

    componentDidMount() {
      this.path = new PathBuilder(calendarId(this.props));

      this.ref = rebase.syncState(this.path.state(), {
        context: this,
        state: 'ui',
        asArray: false,
      });
    }

    componentWillUnmount() {
      rebase.removeBinding(this.ref);
    }

    handleOpenPushPullModal = (type: ?string = 'category-events', id: ?number) => {
      this.setState(
        updateState({
          path: ['ui', 'modalPushPull'],
          value: { isOpen: true, type, categoryId: id || this.props.id },
        }),
      );
    };

    handleOpenCategoryModal = () => {
      const { openModal, id, path: fullPath = [], styles, label } = this.props;
      openModal({
        type: 'category:edit',
        path: fullPath.filter(v => v !== id),
        categoryId: id,
        index: -1,
        currentCategory: {
          id,
          name: label,
          styles,
          path: fullPath,
        },
      });
    };

    handleShowHideEvent = ({
      id,
      visible,
      ancestors,
    }: {
      id: string,
      visible: boolean,
      ancestors: string[],
    }) => {
      const updates = {};

      for (let i = 1; i < ancestors.length; i++) {
        const slice = ancestors.slice(0, i);
        const path = `${this.path.nestedCategory(slice)}/visible`;
        if (!updates[path] && visible) {
          updates[path] = visible;
        }
      }

      if (visible) {
        updates[`${this.path.eventTemplate(ancestors)}/visible`] = visible;
      }

      updates[`${this.path.event(id)}/visible`] = visible;

      firebase
        .database()
        .ref()
        .update(updates);
    };

    handleSortEventInstances = ({
      sortBy,
      ancestors,
    }: {
      sortBy: 'name' | 'date',
      ancestors: string[],
    }) => {
      const updates = {};

      updates[`${this.path.eventTemplate(ancestors)}/sortEventInstancesBy`] = sortBy;

      firebase
        .database()
        .ref()
        .update(updates);
    };

    handleUpdateEventName = ({ id, name }: { id: string, name: string }) => {
      const updates = {};

      updates[`${this.path.event(id)}/name`] = name;

      firebase
        .database()
        .ref()
        .update(updates);
    };

    handleDeleteCategoryOrTemplate = ({
      ancestors,
      type,
    }: {
      ancestors: string[],
      type: 'category' | 'template',
    }) => {
      if (window.confirm(`Are you sure you want to delete this ${type}?`)) {
        const id = cuid();
        const undo = {
          type: 'delete:category',
          undoCreated: Date.now(),
          data: {
            events: this.props.calendarEvents,
            categories: this.props.calendarCategories,
          },
          id,
        };

        const updates = Object.values(this.props.calendarEvents).reduce(
          (acc, event) => {
            // $FlowFixMe
            if (event.ancestors.some(ancestor => ancestor === ancestors[ancestors.length - 1])) {
              // $FlowFixMe
              acc[`${this.path.event(event.id)}`] = null;
            }
            return acc;
          },
          {
            [`${this.path.undo(id)}`]: undo,
            [`${this.path[
              {
                category: 'nestedCategory',
                template: 'eventTemplate',
              }[type]
            ](ancestors)}`]: null,
          },
        );

        firebase
          .database()
          .ref()
          .update(updates);
      }
    };

    handleLockEvent = ({
      id,
      locked,
      ancestors,
    }: {
      id: string,
      locked: boolean,
      ancestors: string[],
    }) => {
      const updates = {};

      for (let i = 1; i < ancestors.length; i++) {
        const ancestorsE = ancestors.slice(0, i);
        const path = `${this.path.nestedCategory(ancestorsE)}/locked`;
        if (!updates[path] && !locked) {
          updates[path] = locked;
        }
      }

      if (!locked) {
        updates[`${this.path.eventTemplate(ancestors)}/locked`] = locked;
      }
      updates[`${this.path.event(id)}/locked`] = locked;

      firebase
        .database()
        .ref()
        .update(updates);
    };

    render() {
      const {
        handleDeleteCategoryOrTemplate,
        handleOpenCategoryModal,
        handleOpenPushPullModal,
        handleShowHideEvent,
        handleSortEventInstances,
        handleUpdateEventName,
        handleLockEvent,
      } = this;
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          {...{
            handleDeleteCategoryOrTemplate,
            handleOpenCategoryModal,
            handleOpenPushPullModal,
            handleShowHideEvent,
            handleSortEventInstances,
            handleUpdateEventName,
            handleLockEvent,
          }}
        />
      );
    }
  };
}

const enhance = compose(
  withStore,
  withRouter,
  handlers,
  withStateHandlers(
    ({ initialIsCollapsed = false }) => ({
      isCollapsed: initialIsCollapsed,
    }),
    {
      onClick: ({ isCollapsed }) => _ => ({ isCollapsed: !isCollapsed }),
    },
  ),
);

export { enhance as default, dragAndDrop };
