type RGB = {
  r: number,
  g: number,
  b: number,
};

type Hex = string;

export function hexToRgb(hex: Hex): RGB {
  const bigInt = parseInt(hex.slice(1), 16);
  const r = (bigInt >> 16) & 255;
  const g = (bigInt >> 8) & 255;
  const b = bigInt & 255;

  return { r, g, b };
}

export function getLuminance(color: Hex): number {
  const { r, g, b } = hexToRgb(color);
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

export function isCloserToWhite(color: Hex): boolean {
  return getLuminance(color) > 127;
}

export function isCloserToBlack(color: Hex): boolean {
  return getLuminance(color) <= 127;
}
