import Rebase from 're-base';
import firebase from 'firebase/compat/app';
import firebaseConfig from './config/firebase.json';
// import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/auth';

const app = firebase.initializeApp(firebaseConfig[process.env.REACT_APP_ENV || 'development']);

// Initialize Realtime Database and get a reference to the service
const db = firebase.database();
const base = Rebase.createClass(db);

export { base as default, firebase, db };
