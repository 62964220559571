import React from 'react';
import styled from 'styled-components';
import colors from '../theme';
import { USER_ROLES } from '../constants';

import type { FirebaseUser } from '../../models';
import { Button } from '../Modal';
import { StyledDropdown } from '../EventModal/components';

export const DeleteButton = Button.extend`
  background-color: ${colors.red};
  border-radius: 0.2em;
  color: #ffffff;
  padding: 0.85em;
  width: 100px;
`;

const Text = styled.span`
  font-size: 0.9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto 0;
  margin-right: auto;
`;

const UserRow = styled.div`
  padding: 10px 15px;
  border: none;
  display: flex;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const Column = styled.div`
  width: ${props => props.width || '33%'};
  font-size: 0.9em;
  white-space: nowrap;
  flex-flow: column wrap;
`;

export type UserContainerProps = {
  user: FirebaseUser,
  handleDelete: (email: string) => void,
  handleUpdateRole: (email: string, newRole: string) => void,
};

const UserContainer = ({ user, handleDelete, handleUpdateRole }: UserContainerProps) => (
  <UserRow>
    <Column width="50%">
      <Text>{user.email}</Text>
    </Column>
    <Column width="30%">
      <StyledDropdown
        onChange={({ value }) => handleUpdateRole(user.email, value)}
        options={USER_ROLES}
        value={user.role && user.role.toUpperCase()}
      />
    </Column>
    <Column width="20%">
      <DeleteButton type="submit" aria-label="submit" onClick={() => handleDelete(user.email)}>
        Delete
      </DeleteButton>
    </Column>
  </UserRow>
);

export default UserContainer;
