import React from 'react';
import Undo from './component';
import Enhance from './container';
import { Subscriber } from 'react-broadcast';

export default (props: any) => (
  <Subscriber channel="firebase">
    {({ categories, events }) => <Enhance {...{ ...props, categories, events }} render={Undo} />}
  </Subscriber>
);
