import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import type { Node } from 'react';
import yup from 'yup';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withRouter } from 'react-router-dom';

import {
  without,
  always,
  evolve,
  map,
  not,
  prop,
  path,
  pathOr,
  reduce,
  sort,
  last,
  values,
  equals,
} from 'ramda';

import rebase from '../../rebase';
import type { FirebaseCategories, FirebaseCategory, Styles } from '../../models';
import { ERRORS } from '../constants';
import { PathBuilder, calendarId, buildBaseCategoryPath } from '../../helpers';
import type { PathObject } from '../../helpers';
import { closeModal, updateCategoryModalCategoryLocation } from '../../store/actions';
import { modalSelector } from '../../store/selectors';
import { flattenCategories, buildCategoriesPath } from '../../helpers/categories';

const withStore = connect(
  state => ({
    ...modalSelector(state),
  }),
  { closeModal, updateCategoryModalCategoryLocation },
);

type State = {
  currentCategory: FirebaseCategory,
  colorPickers: {
    showColor: boolean,
    showBackgroundColor: boolean,
    showBorderColor: boolean,
  },
};

type StyleValue = { hex: string } | { value: string };

type Config = {
  path: string[],
  categoryId: string,
  index: number,
};

export type PassedProps = {
  handleChange: (event: { target: { name: string, value: string } }) => mixed,
  handleChangeStyles: (path: Array<string>) => (value: StyleValue) => mixed,
  handleCloseModal: () => mixed,
  handleShowPicker: (picker: string) => () => mixed,
  handleSubmit: () => mixed,
  handleLocationChange: (path: string[], idx: number) => void,
  height: string,
  type: string,
  isOpen: boolean,
  config: Config,
  showBackgroundColor: boolean,
  showBorderColor: boolean,
  showColor: boolean,
  // formik
  errors: { name: boolean, ...Styles },
  handleChange: () => void,
  handleReset: () => void,
  handleSubmit: any => void,
  isSubmitting: boolean,
  resetForm: ({ name: string, ...Styles }) => void,
  setFieldValue: (field: string, value: any) => void,
  setTouched: (field: string, isTouched: boolean) => void,
  touched: any,
  values: { name: string, ...Styles },
  categories: FirebaseCategories,
};

type Props = {
  categories: FirebaseCategories,
  categoryId: string,
  closeModal: () => void,
  config: Config,
  isOpen: boolean,
  match: { params: { id: string } },
  render: (props: PassedProps) => Node,
  type: string,
  updateCategoryModalCategoryLocation: (params: Config) => void,
};

const calcWeight = (categories: FirebaseCategory[], idx: number) => {
  if (idx === -1 || idx === categories.length) {
    // no position selected or last
    const maybeLast = last(categories);
    return maybeLast ? maybeLast.weight + 1000 : 1000;
  }

  if (idx === 0) {
    const a = categories[idx];
    return a.weight / 2;
  }

  // beyond this point idx >= 1
  // calculate weight between 2 categories
  const [a, b] = categories.slice(idx - 1, idx + 1);

  // assuming b.weight > a.weight
  return a.weight + (b.weight - a.weight) / 2;
};

const initialForm = {
  name: '',
  backgroundColor: '',
  borderColor: '',
  borderStyle: '',
  borderWidth: '',
  color: '',
  fontFamily: '',
  fontSize: '',
  fontStyle: '',
  fontWeight: '',
  textAlign: '',
};

function withHandlers(WrappedComponent) {
  return class extends Component<Props, State> {
    constructor(props) {
      super(props);

      this.state = {
        categories: {},
        lastCategoryId: '',
        currentCategory: {
          name: '',
          eventTemplates: {},
          categories: {},
          path: [],
          styles: {
            backgroundColor: '',
            borderColor: '',
            borderStyle: '',
            borderWidth: '',
            color: '',
            fontFamily: '',
            fontSize: '',
            fontStyle: '',
            fontWeight: '',
            textAlign: '',
          },
          weight: 0,
        },
        colorPickers: {
          showColor: false,
          showBackgroundColor: false,
          showBorderColor: false,
        },
      };
    }

    path: PathObject;
    ref: () => mixed;

    componentDidMount() {
      this.path = new PathBuilder(calendarId(this.props));
    }

    handleShowPicker = kind => () => {
      this.setState(evolve({ colorPickers: { [kind]: not } }));
    };

    handleLocationChange = (path, index, categoryId) => {
      this.props.updateCategoryModalCategoryLocation({
        path,
        index,
        categoryId,
      });
    };

    render() {
      const { isOpen, type, config } = this.props;

      const props = {
        ...this.state.colorPickers,
        categories: this.props.categories,
        currentCategory: this.state.currentCategory,
        handleCloseModal: this.props.closeModal,
        handleShowPicker: this.handleShowPicker,
        handleLocationChange: this.handleLocationChange,
        path: this.path,
        isOpen,
        type,
        config,
      };
      return <WrappedComponent {...props} />;
    }
  };
}

export default compose(
  withStore,
  withRouter,
  withHandlers,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => {
      const { type } = props;
      if (type === 'none' || !type.startsWith('category') || type === 'category:create') {
        return initialForm;
      }

      const { config } = props;
      const { name, styles } = config.currentCategory;
      return {
        name,
        ...styles,
      };
    },
    validationSchema: () => {
      const schema = yup.object().shape({
        name: yup
          .string()
          .required(ERRORS.REQUIRED_FIELD)
          .max(100, ERRORS.MAX_CHARACTER_LENGTH),
        backgroundColor: yup.string().required(ERRORS.REQUIRED_FIELD),
        borderColor: yup.string().required(ERRORS.REQUIRED_FIELD),
        borderStyle: yup.string().required(ERRORS.REQUIRED_FIELD),
        borderWidth: yup.string().required(ERRORS.REQUIRED_FIELD),
        color: yup.string().required(ERRORS.REQUIRED_FIELD),
        fontSize: yup.string().required(ERRORS.REQUIRED_FIELD),
        fontStyle: yup.string().required(ERRORS.REQUIRED_FIELD),
        fontWeight: yup.string().required(ERRORS.REQUIRED_FIELD),
        textAlign: yup.string().required(ERRORS.REQUIRED_FIELD),
      });

      return schema;
    },
    handleSubmit: async (vals, actions) => {
      const { name, ...styles } = vals;
      const { props, resetForm } = actions;
      const defaultData = { name, styles };
      const { type, categories, config } = props;

      const nextStyles = map(always(''), styles);
      const reset = () => resetForm({ name: '', ...nextStyles });

      if (type === 'category:edit') {
        const { currentCategory } = config;
        const paths = prop('path', currentCategory);

        const childCategories = compose(
          sort((a, b) => b.length - a.length),
          nextCategories => [paths, ...nextCategories],
          reduce((acc, curr) => {
            // Check to see if every path of the currentCategory exists in another category
            // If true, then we know this other category to be a child of the currentCategory
            // and we can push its path into the accumulator
            if (paths.every(value => curr.path.some(v => v === value))) {
              acc.push(curr.path);
            }
            return acc;
          }, []),
          flattenCategories,
          pathOr({}, buildCategoriesPath(paths)),
        )(categories);

        // update all categories under `paths` with new path
        const newBasePath =
          config.categoryId && currentCategory.id !== config.categoryId
            ? [...config.path, config.categoryId]
            : config.path;

        let operations = childCategories.reduce((acc, relatedPaths) => {
          const { categories: _, ...aChildCategory } = path(
            buildBaseCategoryPath(relatedPaths),
            categories,
          );

          const diffPath = without(paths, relatedPaths);
          const mainCategoryId = currentCategory.id || undefined;
          const nextBasePath = [...newBasePath, mainCategoryId, ...diffPath];

          const nextChildCategory = {
            ...aChildCategory,
            styles,
            path: [...newBasePath, mainCategoryId, ...diffPath],
          };

          if (!diffPath.length) {
            nextChildCategory.name = name;

            if (config.index > -1) {
              const nextLocationCats = path(buildCategoriesPath(newBasePath), categories);
              const sortedNextLocationCats = values(nextLocationCats).sort(
                (a, b) => a.weight - b.weight,
              );
              const nextWeight = calcWeight(sortedNextLocationCats, config.index);
              nextChildCategory.weight = nextWeight;
            }
          }

          acc.push({
            path: props.path.nestedCategory(nextBasePath),
            data: nextChildCategory,
          });

          if (!equals(nextBasePath, aChildCategory.path)) {
            acc.push({
              path: props.path.nestedCategory(relatedPaths),
              data: null,
            });
          }
          return acc;
        }, []);

        operations.forEach(async ({ path, data }) => {
          if (data) {
            await rebase.update(path, { data });
          } else {
            await rebase.remove(path);
          }
        });

        props.handleCloseModal();
        return reset();
      }

      const { index, categoryId, path: basePath } = config;
      const nextPath = categoryId ? [...basePath, categoryId] : basePath;
      const siblings = compose(values(), path(buildCategoriesPath(nextPath)))(categories);

      const weight = calcWeight(siblings, index);
      const data = { ...defaultData, weight, visible: true };
      const categoryPath = props.path.nestedCategory(nextPath);
      rebase.push(`${categoryPath}/categories`, { data }).then(({ key }) => {
        const fullPath = props.path.nestedCategory([...nextPath, key]);
        rebase.update(fullPath, {
          data: { path: [...nextPath, key] },
        });
      });

      props.handleCloseModal();
      reset();
    },
  }),
);
