import React from 'react';
import { Subscriber } from 'react-broadcast';

import DnDCalendar from './component';
import enhance from './container';

const EnhancedDnDCalendar = enhance(DnDCalendar);

export default (props: any) => (
  <Subscriber channel="firebase">
    {({ events }) => <EnhancedDnDCalendar {...{ ...props, globalEvents: events }} />}
  </Subscriber>
);
