import React from 'react';
import styled from 'styled-components';

import Modal, {
  Button,
  CloseButton,
  CloseIcon,
  Error,
  Field,
  FieldGroup,
  Header,
  Input,
  NumberInput,
  PrimaryButton,
  Row,
  StyledDropdown,
  VarMetadata,
} from '../Modal';

const Header2 = styled.h2`
  margin: 0;
  font-weight: 300;
`;

const Dropdown = StyledDropdown.extend`
  width: 100%;
`;

const StyledModal = Modal.extend`
  width: 350px;
`;

type Props = {
  categories: { id: string, label: string, name: string, value: string },
  errors: any,
  handleBlur: () => mixed,
  handleChange: () => mixed,
  handleCloseModal: () => mixed,
  handleReset: () => mixed,
  handleSubmit: (values: any) => mixed,
  isOpen: boolean,
  isSubmitting: boolean,
  setFieldValue: (field: string, value: any) => mixed,
  touched: any,
  values: {
    name: string,
    duration: string,
    category: { label: string, value: string },
    startingNumber: string,
    eventTemplatesSize: number,
  },
};

export default function EventTemplateModal({
  categories,
  handleCloseModal,
  isOpen,
  /* formik */
  errors,
  handleBlur,
  handleChange,
  handleReset,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  touched,
  values: { name, duration, category, startingNumber, eventTemplatesSize },
}: Props) {
  const handleClose = () => {
    handleReset();
    handleCloseModal();
  };
  return (
    <StyledModal isOpen={isOpen}>
      <Header>
        <Header2>New Event Template</Header2>
        <CloseButton onClick={handleClose} aria-label="close">
          <CloseIcon name="close" width="15px" height="15px" />
        </CloseButton>
      </Header>
      <form onSubmit={handleSubmit}>
        <Row>
          <Field label="Parent Category">
            <Dropdown
              name="category"
              onChange={value => setFieldValue('category', value)}
              options={categories}
              value={category.label}
            />
            <Error>
              {touched.category && errors.category && errors.category.value
                ? errors.category.value
                : ''}
            </Error>
          </Field>
        </Row>
        <Row>
          <FieldGroup horizontal>
            <Field label="Number of Event Templates">
              <NumberInput
                name="eventTemplatesSize"
                onChange={handleChange}
                value={eventTemplatesSize}
              />
              <Error>
                {touched.eventTemplatesSize && errors.eventTemplatesSize
                  ? errors.eventTemplatesSize
                  : ''}
              </Error>
            </Field>
          </FieldGroup>
        </Row>
        <Row>
          <FieldGroup horizontal>
            <Field label="Starting Number">
              <Input
                spellCheck={true}
                id="startingNumber"
                name="startingNumber"
                onChange={handleChange}
                type="input"
                value={startingNumber}
              />
            </Field>
            <Field label="Duration">
              <NumberInput name="duration" onChange={handleChange} value={duration} />
              <Error>{touched.duration && errors.duration ? errors.duration : ''}</Error>
            </Field>
          </FieldGroup>
        </Row>
        <Row align="unset" direction="column">
          <Field label="Name">
            <Input
              spellCheck={true}
              id="name"
              key="new-event-template-input"
              name="name"
              onChange={handleChange}
              type="input"
              value={name}
            />
            <Error>{touched.name && errors.name ? errors.name : ''}</Error>
          </Field>
          <VarMetadata>
            Available Variables: <br />
            %num% - the number being incremented <br />
          </VarMetadata>
        </Row>
        <Row>
          <Button type="button" onClick={handleClose}>
            Cancel
          </Button>
          <PrimaryButton type="submit">Create</PrimaryButton>
        </Row>
      </form>
    </StyledModal>
  );
}
