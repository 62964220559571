import React from 'react';
import styled from 'styled-components';

import type { FirebaseUser } from '../../models';
import UserContainer from './UserContainer';
import { Header } from '../Modal';

export type UserTreeProps = {
  users: FirebaseUser[],
  handleDelete: (email: string) => void,
  handleUpdateRole: (email: string, newRole: string) => void,
};

const Container = styled.div`
  flex: 0 auto;
  overflow-y: auto;
  height: 300px;
  margin: 15px 0px;
`;

export default function UserTree({ users, handleDelete, handleUpdateRole }: UserTreeProps) {
  const list = users.map(user => (
    <UserContainer
      user={user}
      handleDelete={handleDelete}
      handleUpdateRole={handleUpdateRole}
      key={user.email}
    />
  ));

  return (
    <Container>
      <Header>
        <h3>User List</h3>
      </Header>
      {list}
    </Container>
  );
}
