import React from 'react';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Subscriber } from 'react-broadcast';
import rebase from '../rebase';
import { withRouter } from 'react-router-dom';
import { addUndo } from '../store/actions';

import Icon from './Icon';
import { PathBuilder, calendarId } from '../helpers';

export const LockedIcon = styled(Icon)`
  background-color: #000;
  margin-left: 5px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
`;

const Title = styled.div`
  flex: 1 1 auto;
  padding: 2px 5px;
  font-family: Arial, Helvetica, sans-serif;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

const withStore = connect(_state => ({}), {
  addUndo,
});

class ResizableMonthEvent extends React.Component {
  componentDidMount() {
    this.path = new PathBuilder(calendarId(this.props));

    this.props.connectLeftDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
    this.props.connectRightDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }

  handleKeyDown = ({ key }) => {
    const { event: { id: eventId, locked } } = this.props;
    if (key !== 'Backspace' || locked) return;
    const path = this.path.event(eventId);
    const { events } = this.props;
    delete events[eventId];
    this.props.addUndo(events);
    rebase.remove(path);
  };

  render() {
    const { event, connectLeftDragSource, connectRightDragSource } = this.props;
    const [Left, Right] = [connectLeftDragSource, connectRightDragSource].map(connectDragSource => {
      return connectDragSource(<div className="rbc-addons-dnd-resize-month-event-anchor"> </div>);
    });
    return (
      <div className="rbc-addons-dnd-resizable-month-event">
        {Left}
        <Wrapper onKeyDown={this.handleKeyDown} tabIndex="0">
          {event.locked && <LockedIcon name="locked" />}
          <Title>{event.name}</Title>
        </Wrapper>
        {Right}
      </div>
    );
  }
}

const eventSourceLeft = {
  beginDrag: ({ event: data }) => ({ data, type: 'resizeL' }),
};

const eventSourceRight = {
  beginDrag: ({ event: data }) => ({ data, type: 'resizeR' }),
};

const EnhancedResizableMonthEvent = compose(
  withStore,
  withRouter,
  DragSource('resize', eventSourceLeft, (connect, monitor) => ({
    connectLeftDragSource: connect.dragSource(),
    connectLeftDragPreview: connect.dragPreview(),
  })),
  DragSource('resize', eventSourceRight, (connect, monitor) => ({
    connectRightDragSource: connect.dragSource(),
    connectRightDragPreview: connect.dragPreview(),
  })),
)(ResizableMonthEvent);

export default (props: any) => (
  <Subscriber channel="firebase">
    {({ events }) => <EnhancedResizableMonthEvent {...{ ...props, events }} />}
  </Subscriber>
);
