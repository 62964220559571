import React, { Component } from 'react';
import { assoc } from 'ramda';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import yup from 'yup';

import { PathBuilder, calendarId } from '../../helpers';
import { closeEventModal, toggleEventModal, addUndo } from '../../store/actions';
import { ERRORS } from '../constants';
import * as selectors from '../../store/selectors';
import rebase from '../../rebase';
import type { Event, FirebaseEvents } from '../../models';
import type { PathObject } from '../../helpers';

const withStore = connect(
  state => ({
    isEventModalOpen: selectors.isEventModalOpen(state),
    event: selectors.event(state),
  }),
  {
    closeEventModal,
    toggleEventModal,
    addUndo,
  },
);

export type PassedProps = {
  event: Event,
  closeEventModal: () => void,
  handleChange: () => void,
  handleDelete: (id: string) => void,
  handleDuplicate: () => void,
  handleShowPicker: (picker: string) => () => void,
  handleSubmit: () => void,
  handleReset: () => void,
  height: string,
  isEventModalOpen: boolean,
  setFieldValue: (field: string, value: any) => void,
  showBackgroundPicker: boolean,
  showBorderPicker: boolean,
  showColorPicker: boolean,
  values: Event,
  errors: {
    name: string,
    start: string,
    end: string,
  },
  touched: {
    name: boolean,
    start: boolean,
    end: boolean,
  },
  events: FirebaseEvents,
  addUndo: (events: FirebaseEvents) => void,
};

function withHandlers(WrappedComponent) {
  type State = {
    showColorPicker: boolean,
    showBorderPicker: boolean,
    showBackgroundPicker: boolean,
  };

  type Props = {
    closeEventModal: () => void,
    event: Event,
    isEventModalOpen: boolean,
    toggleEventModal: () => void,
    events: FirebaseEvents,
    addUndo: (events: FirebaseEvents) => void,
  };

  return class extends Component<Props, State> {
    constructor(props) {
      super(props);
      this.state = {
        event: props.event,
        showColorPicker: false,
        showBorderPicker: false,
        showBackgroundPicker: false,
      };
    }

    ref: () => mixed;
    path: PathObject;

    componentDidMount() {
      // This component is mounted when the app mounts
      // It does not mount or get unmounted based on its path
      // Therefore information about the event is passed by redux
      // This could be fixed with modal routes which have not been implemented
      // AR Wed Oct 18 13:11:06 EDT 2017
      this.ref = rebase;

      this.path = new PathBuilder(calendarId(this.props));
    }

    componentWillUnmount() {
      rebase.removeBinding(this.ref);
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.event !== this.props.event) {
        this.setState(assoc('event', nextProps.event));
      }
    }

    handleDelete = () => {
      const { event: { id: eventId } } = this.props;
      const path = this.path.event(eventId);
      const { events } = this.props;

      this.props.addUndo(events);

      rebase.remove(path).then(() => {
        this.props.closeEventModal();
      });
    };

    handleDuplicate = e => {
      // Not currently being used
      // e.preventDefault();
      // this.props.addEvent(this.state);
      // this.setState({ isEditMode: false });
    };

    handleOpenEventModal = () => {
      this.props.toggleEventModal();
    };

    handleClickOutside = () => {
      this.props.closeEventModal();
    };

    handleShowPicker = kind => () => {
      this.setState(prev => ({ [kind]: !prev[kind] }));
    };

    render() {
      const { showColorPicker, showBorderPicker, showBackgroundPicker } = this.state;
      const { handleDelete, handleDuplicate, handleOpenEventModal, handleShowPicker } = this;
      return (
        <WrappedComponent
          {...this.props}
          {...{ showColorPicker, showBorderPicker, showBackgroundPicker }}
          {...{
            handleDelete,
            handleDuplicate,
            handleOpenEventModal,
            handleShowPicker,
          }}
          path={this.path}
        />
      );
    }
  };
}

export default compose(
  withRouter,
  withStore,
  onClickOutside,
  withHandlers,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ event }) => event,
    validationSchema: () => {
      const schema = yup.object().shape({
        name: yup.string().required(ERRORS.REQUIRED_FIELD),
        start: yup.date().required(ERRORS.REQUIRED_FIELD),
        end: yup
          .date()
          .when('start', (start, schema) => {
            if (!start) return;
            return schema.min(start, ERRORS.BEFORE_START_DATE);
          })
          .required(ERRORS.REQUIRED_FIELD),
      });
      return schema;
    },
    handleSubmit: (values, { props }) => {
      const { event, events } = props;
      const updatedEvent = values;
      const path = props.path.event(event.id);
      events[event.id] = {
        ...event,
        ...updatedEvent,
      };
      props.addUndo(events);
      rebase
        .update(path, {
          data: updatedEvent,
        })
        .then(event => {
          props.closeEventModal();
        });
    },
  }),
);
