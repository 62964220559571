import React, { Component } from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import Icon from '../Icon';
import IconButton from '../IconButton';

import type { PassedProps } from './container';
import type { Node } from 'react';

const NavBar = styled.div`
  background-color: ${colors('teal')};
  display: flex;
  flex: 1 0 auto;
  height: 50px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;
`;

const Logo = styled.div`
  margin: auto 7px auto 33px;
  line-height: 0;
  color: ${colors('white')};
`;

const ProjectName = styled.span`
  margin: 19px 15px auto 0;
  font-size: 16px;
  font-weight: 100;
  color: ${colors('white')};
`;

const Button = styled(IconButton)`
  margin: auto 0 auto 45px;
  display: flex;
  color: ${colors('white')};
  opacity: 0.85;

  div {
    margin-right: 7px;
  }

  span {
    margin: auto 0;
    font-size: 15px;
    font-weight: 100;
    text-transform: uppercase;
  }

  &:hover {
    color: ${colors('white')};
    cursor: default;
    opacity: 1;

    div {
      background-color: ${colors('white')};
      opacity: 1;
    }
  }
`;

type OpenFileInputProps = {
  render: ({
    handleChange: () => void,
    handleClick: () => void,
    handleRef: Node => void,
  }) => Node,
};

class OpenFileInput extends Component<OpenFileInputProps, void> {
  fileInputRef: any;

  handleRef = node => {
    this.fileInputRef = node;
  };

  handleClick = () => {
    const event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    this.fileInputRef.dispatchEvent(event);
  };

  handleChange = () => {
    const file = this.fileInputRef.files[0];
    const reader = new FileReader();

    reader.addEventListener('loadend', () => {
      const text = reader.result;
      // $FlowFixMe
      const url = text.match(/URL=(.+)/i);
      if (url && url[1]) {
        const tab = window.open(url[1], '_blank');
        if (tab) {
          // In case of pop-up blocker
          tab.focus();
        }
      }
    });

    reader.readAsText(file);
  };

  render() {
    const { handleChange, handleClick, handleRef } = this;
    return <div>{this.props.render({ handleChange, handleClick, handleRef })}</div>;
  }
}

const OpenCalendarButton = Button.extend`
  margin-top: 15px;
`;

export default function CalendarItem({
  handleNew,
  handleOpen,
  handleSaveAs,
  handleManageUsers,
  isAdmin,
  ...rest
}: PassedProps) {
  return (
    <NavBar>
      <Logo>
        <Icon name="logo" width="47px" height="24px" />
      </Logo>
      <ProjectName>Calendar</ProjectName>

      <Button name="plus" width="15px" height="15px" text="New Calendar" onClick={handleNew} />
      <Button
        name="download"
        width="15px"
        height="17px"
        text="Save Calendar As"
        onClick={handleSaveAs}
      />
      <OpenFileInput
        render={({ handleChange, handleClick, handleRef }) => {
          return (
            <div>
              <OpenCalendarButton
                name="upload"
                width="15px"
                height="17px"
                text="Open Calendar"
                onClick={handleClick}
              />
              <input
                style={{ display: 'none' }}
                type="file"
                onChange={handleChange}
                ref={handleRef}
              />
            </div>
          );
        }}
      />
      {isAdmin && (
        <Button
          name="user-plus-solid"
          width="15px"
          height="15px"
          text="Manage Users"
          onClick={handleManageUsers}
        />
      )}
    </NavBar>
  );
}
