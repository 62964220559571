import React from 'react';

import Modal, {
  Row,
  Header,
  Field,
  Input,
  PrimaryButton,
  SecondaryButton,
  CloseButton,
  FieldGroup,
  Checkbox,
} from '../Modal';

import type { PassedProps } from './container';
import type { FirebaseCategories } from '../../models';

const CalendarModal = Modal.extend`
  width: 350px;
`;

const Component = ({
  embeded,
  handleChange,
  handleCloseModal,
  handleSubmit,
  handleCheck,
  isAddCalendarModalOpen,
  name = '',
  title = 'New Calendar',
  episodesSize,
  episodesStartNumber,
  categories,
  ...other
}: PassedProps) => {
  const renderPostCategories = (categories: FirebaseCategories) => {
    if (!categories) return null;
    return Object.keys(categories).map(categoryId => {
      const category = categories[categoryId];
      const fieldId = `postCategory_${categoryId}`;
      const labelName = category.name;
      return (
        <Checkbox key={fieldId}>
          <Input
            id={fieldId}
            name={fieldId}
            onChange={e => handleCheck(categoryId, e.currentTarget.checked)}
            type="checkbox"
            checked={category.checked}
          />
          <label htmlFor={fieldId}>{labelName}</label>
        </Checkbox>
      );
    });
  };

  return (
    <CalendarModal isOpen={isAddCalendarModalOpen} embeded={embeded} {...other}>
      <Header>
        <h3>{title}</h3>
        {!embeded && (
          <CloseButton onClick={handleCloseModal} aria-label="close">
            x
          </CloseButton>
        )}
      </Header>
      <form onSubmit={handleSubmit} key="form">
        <Row>
          <Field label="Name">
            <Input
              spellCheck={true}
              id="name"
              name="name"
              onChange={handleChange}
              type="input"
              value={name}
            />
          </Field>
        </Row>
        <FieldGroup vertical title="Select Categories" margin="0.4rem 0px">
          {renderPostCategories(categories)}
        </FieldGroup>
        <FieldGroup horizontal margin="0.4rem 0px 1rem 0px">
          <Field label="Number of Episodes">
            <Input
              spellCheck={true}
              id="episodesSize"
              name="episodesSize"
              onChange={handleChange}
              type="number"
              value={episodesSize}
            />
          </Field>
          <Field label="Episode Starting Number">
            <Input
              spellCheck={true}
              id="episodesStartNumber"
              name="episodesStartNumber"
              onChange={handleChange}
              type="input"
              value={episodesStartNumber}
            />
          </Field>
        </FieldGroup>
        <Row>
          {!embeded && (
            <SecondaryButton
              type="button"
              margin="0 10px 0 0"
              onClick={handleCloseModal}
              aria-label="cancel"
            >
              Cancel
            </SecondaryButton>
          )}
          <PrimaryButton type="submit" aria-label="submit">
            Create
          </PrimaryButton>
        </Row>
      </form>
    </CalendarModal>
  );
};

export default Component;
