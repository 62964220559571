import React from 'react';
import { Subscriber } from 'react-broadcast';

import SaveAsModal from './component';
import enhance from './container';

const EnhancedSaveAsModal = enhance(SaveAsModal);

export default (props: any) => (
  <Subscriber channel="firebase">
    {({ categories, events }) => <EnhancedSaveAsModal {...{ ...props, categories, events }} />}
  </Subscriber>
);
