import { assocPath } from 'ramda';

import type { State } from '../models';

type UpdateArgs = {
  path: Array<string>,
  value: any,
};

export function updateState({ path, value }: UpdateArgs): State {
  return assocPath(path, value);
}
