import React from 'react';

import type { PassedProps } from './container';

import Modal, {
  Error,
  Field,
  FieldGroup,
  Header,
  Header2,
  IconClose,
  Input,
  PrimaryButton,
  Row,
  SecondaryButton,
  HR,
  VarMetadata,
} from '../Modal';

export default function QuickBuildModal({
  isOpen,
  type,
  handleCloseModal,
  // formik
  errors,
  handleChange,
  handleReset,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  setStatus,
  status,
  touched,
  values,
}: PassedProps) {
  const {
    eventTemplateCount,
    startOn,
    duration,
    daysTilNextStart,
    startingNumber,
    incrementBy,
    format,
  } = values;

  const handleClose = () => {
    handleCloseModal();
    handleReset();
  };

  if (!isOpen || type !== 'quick-build') {
    return null;
  }

  return (
    <Modal isOpen={isOpen}>
      <Header>
        <Header2>Quick Build</Header2>
        <IconClose onClick={handleClose} aria-label="close" />
      </Header>
      <HR />
      <form onSubmit={handleSubmit} key="form">
        <Row align="flex-end">
          <Field label="Number of Events Per Template">
            <Input
              id="eventTemplateCount"
              name="eventTemplateCount"
              onChange={handleChange}
              type="number"
              value={eventTemplateCount}
            />
            <Error>{touched.title && errors.title ? errors.title : null}</Error>
          </Field>
          <Field label="First event start on">
            <Input
              id="startOn"
              name="startOn"
              onChange={handleChange}
              type="date"
              value={startOn}
            />
            <Error>{touched.startOn && errors.startOn ? errors.startOn : null}</Error>
          </Field>
          <Field label="Each Event Duration">
            <Input
              id="duration"
              name="duration"
              onChange={handleChange}
              type="number"
              units="day(s)"
              value={duration}
            />
            <Error>{touched.duration && errors.duration ? errors.duration : null}</Error>
          </Field>
        </Row>
        <Row align="flex-end">
          <Field label="Time between event start dates">
            <Input
              id="daysTilNextStart"
              name="daysTilNextStart"
              onChange={handleChange}
              type="number"
              units="day(s)"
              value={daysTilNextStart}
            />
            <Error>
              {touched.daysTilNextStart && errors.daysTilNextStart ? errors.daysTilNextStart : null}
            </Error>
          </Field>
        </Row>
        <Row>
          <FieldGroup horizontal title="Naming Convention">
            <Field label="Starting Number">
              <Input
                id="startingNumber"
                name="startingNumber"
                onChange={handleChange}
                type="text"
                value={startingNumber}
              />
              <Error>
                {touched.startingNumber && errors.startingNumber ? errors.startingNumber : null}
              </Error>
            </Field>
            <Field label="Increment By">
              <Input
                id="incrementBy"
                name="incrementBy"
                onChange={handleChange}
                type="number"
                value={incrementBy}
              />
              <Error>{touched.incrementBy && errors.incrementBy ? errors.incrementBy : null}</Error>
            </Field>
          </FieldGroup>
        </Row>
        <Row align="unset" direction="column">
          <Field label="Format">
            <Input id="format" name="format" onChange={handleChange} type="text" value={format} />
            <Error>{touched.format && errors.format ? errors.format : null}</Error>
          </Field>
          <VarMetadata>
            Available Variables: <br />
            %num% - the number being incremented <br />
            %event% - the name of the event template <br />
          </VarMetadata>
        </Row>
        <Row>
          <SecondaryButton
            type="button"
            margin="0 10px 0 0"
            onClick={handleClose}
            aria-label="cancel"
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton type="submit" aria-label="submit">
            Build
          </PrimaryButton>
        </Row>
      </form>
    </Modal>
  );
}
