import React, { Component } from 'react';
import yup from 'yup';
import cuid from 'cuid';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withRouter } from 'react-router';
import { last, range, dropLast, path, values } from 'ramda';
import { format } from 'date-fns';

import { firebase } from '../../rebase';
import type { PathObject } from '../../helpers';
import { ERRORS } from '../constants';
import { PathBuilder, calendarId, buildBaseCategoryPath, genName } from '../../helpers';
import { closeModal, resetSidebarSelections, addUndo } from '../../store/actions';
import { modalSelector, sidebarSelectionsSelector } from '../../store/selectors';
import { defaultEventStart, defaultEventEnd, fAddDays } from '../DnDCalendar/helpers';
import type { FirebaseCategories, FirebaseEvents } from '../../models';

const withStore = connect(
  state => ({
    ...modalSelector(state),
    selectedTemplates: sidebarSelectionsSelector(state),
  }),
  {
    closeModal,
    addUndo,
    resetSidebarSelections,
  },
);

const initialFormValues = {
  eventTemplateCount: 1,
  startOn: '',
  duration: 1,
  daysTilNextStart: 7,
  startingNumber: '',
  incrementBy: 1,
  format: '',
};

export type PassedProps = {
  isOpen: boolean,
  type: string,
  path: PathObject,
  handleCloseModal: () => void,
  // formik
  errors: {
    eventTemplateCount: number,
    startOn: string,
    duration: number,
    daysTilNextStart: number,
    startingNumber: string,
    incrementBy: number,
    format: string,
  },
  handleChange: () => void,
  handleReset: () => void,
  handleSubmit: () => void,
  isSubmitting: boolean,
  setFieldValue: (field: string, value: any) => void,
  setStatus: (value: any) => void,
  touched: any,
  status: any,
  values: {
    eventTemplateCount: number,
    startOn: string,
    duration: number,
    daysTilNextStart: number,
    startingNumber: string,
    incrementBy: number,
    format: string,
  },
  addUndo: (events: FirebaseEvents) => void,
};

function withHandlers(WrappedComponent) {
  type Props = {
    isOpen: boolean,
    closeModal: () => void,
    resetSidebarSelections: () => void,
  };

  type State = {};

  return class extends Component<Props, State> {
    ref: () => mixed;
    path: PathObject;

    componentDidMount() {
      this.path = new PathBuilder(calendarId(this.props));
    }

    handleCloseModal = () => {
      this.props.resetSidebarSelections();
      this.props.closeModal();
    };

    render() {
      const props = {
        ...this.props,
        ...this.state,
        path: this.path,
        handleCloseModal: this.handleCloseModal,
      };
      return <WrappedComponent {...props} />;
    }
  };
}

export default compose(
  withStore,
  withRouter,
  withHandlers,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => ({
      ...initialFormValues,
    }),
    validationSchema: () => {
      const schema = yup.object().shape({
        eventTemplateCount: yup
          .number()
          .required(ERRORS.REQUIRED_FIELD)
          .positive(ERRORS.POSITIVE_NUMBER_REQUIRED)
          .integer(ERRORS.REQUIRED_FIELD),
        startOn: yup.date().required(ERRORS.REQUIRED_FIELD),
        duration: yup
          .number()
          .required(ERRORS.REQUIRED_FIELD)
          .positive(ERRORS.POSITIVE_NUMBER_REQUIRED)
          .integer(ERRORS.REQUIRED_FIELD),
        daysTilNextStart: yup
          .number()
          .required(ERRORS.REQUIRED_FIELD)
          .positive(ERRORS.POSITIVE_NUMBER_REQUIRED)
          .integer(ERRORS.REQUIRED_FIELD),
        startingNumber: yup.lazy(value => {
          if (typeof value === 'number') {
            return yup
              .number()
              .required(ERRORS.REQUIRED_FIELD)
              .positive(ERRORS.POSITIVE_NUMBER_REQUIRED)
              .integer(ERRORS.REQUIRED_FIELD);
          }
          if (typeof value === 'string') {
            if (!value) return yup.string();
            if (!isNaN(+value)) {
              return yup
                .number()
                .transform((_currVal, origVal) => +origVal)
                .required(ERRORS.REQUIRED_FIELD)
                .positive(ERRORS.POSITIVE_NUMBER_REQUIRED)
                .integer(ERRORS.REQUIRED_FIELD);
            }

            const parts = value.split(',');
            if (parts.length <= 1) {
              return yup
                .number()
                .required(ERRORS.REQUIRED_FIELD)
                .positive(ERRORS.POSITIVE_NUMBER_REQUIRED)
                .integer(ERRORS.REQUIRED_FIELD);
            }
          }

          return value instanceof Array
            ? yup.array().when(['eventTemplateCount'], (eventTemplateCount, schema) => {
                return schema.test(
                  'last-val',
                  'last segment should be a number',
                  parts => (parts.length < eventTemplateCount ? !isNaN(last(parts)) : true),
                );
              })
            : yup.string().transform((currVal, origVal) => {
                const parts = currVal.split(',');
                return parts.length <= 1 ? currVal : parts;
              });
        }),
        incrementBy: yup
          .number()
          .required(ERRORS.REQUIRED_FIELD)
          .positive(ERRORS.POSITIVE_NUMBER_REQUIRED)
          .integer(ERRORS.REQUIRED_FIELD),
        format: yup.string(),
      });

      return schema;
    },
    handleSubmit: (formValues, actions) => {
      const {
        props,
      }: {
        props: {
          selectedTemplates: {
            items: { [key: string]: boolean },
          },
          categories: FirebaseCategories,
          events: FirebaseEvents,
          path: PathObject,
          closeModal: () => void,
          resetSidebarSelections: () => void,
          addUndo: (events: FirebaseEvents) => void,
        },
      } = actions;

      const { selectedTemplates: { items }, categories, events } = props;
      const {
        eventTemplateCount,
        startOn,
        duration,
        daysTilNextStart,
        startingNumber,
        incrementBy,
        format: formatStr,
      } = formValues;

      const currEvents = values(events).sort((a, b) => a.weight - b.weight);
      const newEvents = events;

      const updates = Object.keys(items).reduce((acc, pathStr, templateIdx) => {
        const templatePath = pathStr.split('/');
        const eventTemplateId = last(templatePath);
        const categoryPath = dropLast(1, templatePath);
        const template = path(
          [...buildBaseCategoryPath(categoryPath), 'eventTemplates', eventTemplateId],
          categories,
        );

        const currTemplateEvents = currEvents.reduce((acc, ev) => {
          return ev.eventTemplateId === eventTemplateId ? [...acc, ev] : acc;
        }, []);

        const { weight: startWeight } = last(currTemplateEvents) || { weight: 0 };

        return range(0, eventTemplateCount).reduce((acc, idx) => {
          const eid = cuid();
          const skew = (templateIdx + idx) * daysTilNextStart;
          const event = {
            ancestors: templatePath,
            end: compose(format, fAddDays(duration + skew - 1), defaultEventEnd)(startOn),
            eventTemplateId,
            id: eid,
            key: eid,
            locked: false,
            name: genName(template.name, startingNumber, incrementBy, formatStr, idx),
            note: '',
            start: compose(fAddDays(skew), defaultEventStart)(startOn),
            styles: path(buildBaseCategoryPath(categoryPath), categories).styles,
            url: '',
            visible: true,
            weight: startWeight + (idx + 1) * 1000,
          };

          newEvents[eid] = event;

          return {
            ...acc,
            [`${props.path.event(eid)}`]: event,
          };
        }, acc);
      }, []);

      props.addUndo(newEvents);

      firebase
        .database()
        .ref()
        .update(updates)
        .then(() => {
          props.resetSidebarSelections();
          props.closeModal();
        });
    },
  }),
);
