import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import rebase from '../../rebase';

import * as selectors from '../../store/selectors';
import { openAddCalendarModal, openUsersModal } from '../../store/actions';
import { PathBuilder, calendarId } from '../../helpers';

import type { PathObject } from '../../helpers';

const withStore = connect(
  state => ({
    isAddCalendarModalOpen: selectors.isAddCalendarModalOpen(state),
    isUsersModalOpen: selectors.isUsersModalOpen(state),
  }),
  {
    openAddCalendarModal: openAddCalendarModal,
    openUsersModal: openUsersModal,
  },
);

export type PassedProps = {
  handleNew: () => void,
  handleOpen: () => void,
  handleSaveAs: () => void,
  handleManageUsers: () => void,
  isAdmin: boolean,
};

function withHandlers(WrappedComponent) {
  type Props = {
    isAddCalendarModalOpen: boolean,
    isUsersModalOpen: boolean,
    openAddCalendarModal: () => void,
    openUsersModal: () => void,
    isAdmin: boolean,
  };

  type State = {
    ui: {
      isSaveAsModalOpen: boolean,
    },
  };

  return class extends Component<Props, State> {
    constructor(props) {
      super(props);
      this.state = {
        ui: {
          isSaveAsModalOpen: false,
        },
      };
    }

    ref: () => mixed;
    path: PathObject;

    componentDidMount() {
      this.path = new PathBuilder(calendarId(this.props));

      this.ref = rebase.syncState(this.path.state(), {
        context: this,
        state: 'ui',
        asArray: false,
      });
    }

    componentWillUnmount() {
      rebase.removeBinding(this.ref);
    }

    handleNew = () => this.props.openAddCalendarModal();

    handleOpen() {
      alert('TODO: Open Calendar');
    }

    handleManageUsers = () => this.props.openUsersModal();

    handleSaveAs = () => {
      const { ui } = this.state;
      this.setState({
        ui: {
          ...ui,
          isSaveAsModalOpen: true,
        },
      });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          handleNew={this.handleNew}
          handleOpen={this.handleOpen}
          handleSaveAs={this.handleSaveAs}
          handleManageUsers={this.handleManageUsers}
        />
      );
    }
  };
}

export default compose(withStore, withRouter, withHandlers);
