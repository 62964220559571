import React from 'react';
import { Subscriber } from 'react-broadcast';

import CategoryModal from './component';
import enhance from './container';

const EnhancedCategoryModal = enhance(CategoryModal);

export default (props: any) => (
  <Subscriber channel="firebase">
    {({ categories }) => <EnhancedCategoryModal {...{ ...props, categories }} />}
  </Subscriber>
);
