import React from 'react';
import { Subscriber } from 'react-broadcast';

import EventTemplateModal from './component';
import enhance from './container';

const EnhancedEventTemplateModal = enhance(EventTemplateModal);

export default (props: any) => (
  <Subscriber channel="firebase">
    {({ categories }) => <EnhancedEventTemplateModal {...{ ...props, categories }} />}
  </Subscriber>
);
