import React from 'react';
import { Subscriber } from 'react-broadcast';

import QuickBuildModal from './component';
import enhance from './container';

const EnhancedQuickBuildModal = enhance(QuickBuildModal);

export default (props: any) => (
  <Subscriber channel="firebase">
    {({ categories, events }) => <EnhancedQuickBuildModal {...{ ...props, categories, events }} />}
  </Subscriber>
);
