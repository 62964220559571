import React, { Component } from 'react';
import format from 'date-fns/format';
import yup from 'yup';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withRouter } from 'react-router';

import rebase from '../../rebase';
import type { PathObject } from '../../helpers';
import { ERRORS } from '../constants';
import { PathBuilder, calendarId } from '../../helpers';
import { closeExportModal } from '../../store/actions';
import { isExportModalOpen } from '../../store/selectors';

const withStore = connect(
  state => ({
    isExportModalOpen: isExportModalOpen(state),
  }),
  { closeExportModal },
);

export type PassedProps = {
  isExportModalOpen: boolean,
  printReady: boolean,
  path: PathObject,
  handleCloseModal: () => void,
  config: {
    title: string,
    from: string,
    to: string,
    client: string,
    calendarType: string,
    episodes: string,
    duration: string,
    filename: string,
    filenamePristine: boolean,
  },
  setPrintReady: (printReady: boolean) => void,
  // formik
  errors: {
    title: string,
    from: string,
    to: string,
    client: string,
    calendarType: string,
    episodes: string,
    duration: string,
    filename: string,
  },
  handleChange: () => void,
  handleReset: () => void,
  handleSubmit: () => void,
  isSubmitting: boolean,
  setFieldValue: (field: string, value: any) => void,
  setStatus: (value: any) => void,
  touched: any,
  status: any,
  values: {
    title: string,
    from: string,
    to: string,
    client: string,
    calendarType: string,
    episodes: string,
    duration: string,
    filename: string,
    filenamePristine: boolean,
  },
};

function withHandlers(WrappedComponent) {
  type Props = {
    isExportModalOpen: boolean,
    closeExportModal: () => void,
  };

  type State = {
    config: {
      title: string,
      from: string,
      to: string,
      client: string,
      calendarType: string,
      episodes: string,
      duration: string,
      filename: string,
      filenamePristine: boolean,
    },
    printReady: boolean,
  };

  return class extends Component<Props, State> {
    state = {
      config: {
        title: '',
        from: '',
        to: '',
        client: '',
        calendarType: '',
        episodes: '',
        duration: '',
        filename: '',
        filenamePristine: true,
      },
      printReady: false,
    };

    ref: () => mixed;
    path: PathObject;

    componentDidMount() {
      this.path = new PathBuilder(calendarId(this.props));

      rebase.bindToState(this.path.export(), {
        context: this,
        state: 'config',
        defaultValue: this.state.config,
      });
    }

    handleCloseModal = () => {
      this.props.closeExportModal();
      this.setState({ printReady: false });
    };

    render() {
      const { printReady, config } = this.state;
      const { path, handleCloseModal } = this;
      return (
        <WrappedComponent
          {...this.props}
          {...{ printReady, path, handleCloseModal, config }}
          setPrintReady={printReady => this.setState({ printReady })}
        />
      );
    }
  };
}

export default compose(
  withStore,
  withRouter,
  withHandlers,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ config: { filename, ...rest } }) => ({
      filename: filename || `${format(new Date(), 'MM-DD-YY')}`,
      ...rest,
    }),
    validationSchema: () => {
      const schema = yup.object().shape({
        title: yup
          .string()
          .max(100, ERRORS.MAX_CHARACTER_LENGTH)
          .required(ERRORS.REQUIRED_FIELD),
        from: yup.date().required(ERRORS.REQUIRED_FIELD),
        to: yup
          .date()
          .when('from', (from, schema) => {
            if (!from) return;
            return schema.min(from, ERRORS.BEFORE_DATE_FROM);
          })
          .required(ERRORS.REQUIRED_FIELD),

        client: yup
          .string()
          .max(100, ERRORS.MAX_CHARACTER_LENGTH)
          .required(ERRORS.REQUIRED_FIELD),
        calendarType: yup
          .string()
          .max(100, ERRORS.MAX_CHARACTER_LENGTH)
          .required(ERRORS.REQUIRED_FIELD),
        episodes: yup
          .string()
          .max(100, ERRORS.MAX_CHARACTER_LENGTH)
          .required(ERRORS.REQUIRED_FIELD),
        duration: yup
          .string()
          .max(100, ERRORS.MAX_CHARACTER_LENGTH)
          .required(ERRORS.REQUIRED_FIELD),
        filename: yup
          .string()
          .max(100, ERRORS.MAX_CHARACTER_LENGTH)
          .required(ERRORS.REQUIRED_FIELD),
      });

      return schema;
    },
    handleSubmit: (values, actions) => {
      const { props } = actions;
      rebase.update(props.path.export(), { data: values }).then(() => {
        props.setPrintReady(true);
      });
    },
  }),
);
