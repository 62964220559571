import React from 'react';
import styled from 'styled-components';

import type { PassedProps } from './container';

const ButtonWrappers = styled.div`
  padding-right: 20px;
`;

const Button = styled.button`
  outline: none;
  &:hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;

export default function({ handleUndo, handleRedo, canRedo, canUndo }: PassedProps) {
  return (
    <ButtonWrappers>
      <Button
        type="button"
        disabled={!canUndo}
        onClick={handleUndo}
        style={
          canUndo ? { backgroundColor: 'transparent' } : { backgroundColor: '#eee', color: '#ddd' }
        }
      >
        Undo
      </Button>{' '}
      <Button
        type="button"
        disabled={!canRedo}
        onClick={handleRedo}
        style={
          canRedo ? { backgroundColor: 'transparent' } : { backgroundColor: '#eee', color: '#ddd' }
        }
      >
        Redo
      </Button>
    </ButtonWrappers>
  );
}
