import type { MsgAndPayload, OnlyMsg } from './types';
import msg from '../../messages';

export function toggleEventTemplateSelection(path: string): MsgAndPayload {
  return {
    type: msg.TOGGLE_SIDEBAR_EVENT_TEMPLATE_SELECTION,
    payload: path,
  };
}

export function resetSidebarSelections(): OnlyMsg {
  return {
    type: msg.RESET_SIDEBAR_EVENT_TEMPLATE_SELECTION,
  };
}
