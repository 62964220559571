import type {
  FirebaseCategories,
  FirebaseEvents,
  FirebaseEventTemplates,
  Category,
  Event,
  EventTemplate,
  Styles,
} from '../models';

function entries<T>(obj: { [string]: T }): Array<[string, T]> {
  const keys: string[] = Object.keys(obj);
  return keys.map(key => [key, obj[key]]);
}

type TemplateEventsMap = {
  [key: string]: Array<Event>,
};

const normEvents = (
  templates: FirebaseEventTemplates,
  events: TemplateEventsMap,
): Array<EventTemplate> =>
  entries(templates).map(([id, template]) => {
    return { ...template, id, instances: events[id] || null };
  });

const normCategories = (
  categories: FirebaseCategories,
  eventsMap: TemplateEventsMap,
): Array<Category> =>
  entries(categories).map(([id, category]) => {
    const { categories = {}, eventTemplates = {}, ...rest } = category;
    return {
      id,
      ...rest,
      categories: normCategories(categories, eventsMap),
      eventTemplates: normEvents(eventTemplates, eventsMap),
    };
  });

const templateToEvents = (events: FirebaseEvents): TemplateEventsMap => {
  const vals = (Object.values(events): any);
  return vals.reduce((acc, { eventTemplateId, ...rest }) => {
    let evs = acc[eventTemplateId];
    if (evs === undefined) {
      acc[eventTemplateId] = [{ ...rest, eventTemplateId }];
    } else {
      evs.push({ ...rest, eventTemplateId });
    }
    return acc;
  }, {});
};

const normalizeCategories = (
  categories: FirebaseCategories,
  events: FirebaseEvents,
): Array<Category> => {
  const templateIdToEventsMap = templateToEvents(events);
  return entries(categories).map(([id, category]) => {
    const { categories = {}, eventTemplates = {}, ...rest } = category;
    return {
      id,
      ...rest,
      categories: normCategories(categories, templateIdToEventsMap),
      eventTemplates: normEvents(eventTemplates, templateIdToEventsMap),
    };
  });
};

// Used to normalize eventTemplates into tree-node structures
type Item = {
  id: string,
  type: string,
  name: string,
  categories: Array<Category>,
  eventTemplates: Array<EventTemplate>,
};

type NormalizedCategory = {
  id?: string,
  name: string,
  items: Array<Item>,
  styles?: Styles,
  weight: number,
  categoryId?: string,
};

const normalizeItems = ({ eventTemplates, categories, ...rest }: Category): NormalizedCategory => {
  let mixed = categories.reduce((acc, { weight, ...rest }) => {
    if (acc['' + weight]) {
      acc['' + weight + 1] = { type: 'category', ...rest };
    } else {
      acc['' + weight] = { type: 'category', ...rest };
    }
    return acc;
  }, {});

  if (eventTemplates) {
    mixed = eventTemplates.reduce((acc, { weight, ...rest }) => {
      if (acc['' + weight]) {
        acc['' + weight + 1] = { type: 'event-template', ...rest };
      } else {
        acc['' + weight] = { type: 'event-template', ...rest };
      }
      return acc;
    }, mixed);
  }

  // sort and convert back to array
  const items = Object.keys(mixed)
    .sort((a, b) => (+a < +b ? -1 : 1))
    .map(key => mixed[key]);

  return { items, ...rest };
};

export { normalizeCategories, normalizeItems };
