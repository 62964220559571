import React from 'react';

import Icon from './Icon';

export default ({ className, name, width, height, text, onClick, color }) => (
  <a onClick={onClick} className={className}>
    <Icon name={name} width={width} height={height} color={color} />
    <span>{text}</span>
  </a>
);
