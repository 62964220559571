export const DROPDOWN_OPTIONS = {
  BORDER_STYLE: ['solid', 'dotted'],
  BORDER_WIDTH: ['0px', '1px', '2px', '3px'],
  FONT_SIZE: [
    '8px',
    '9px',
    '10px',
    '11px',
    '12px',
    '13px',
    '14px',
    '15px',
    '16px',
    '17px',
    '18px',
    '19px',
    '20px',
  ],
  FONT_STYLE: ['normal', 'italic'],
  FONT_WEIGHT: ['bold', 'normal'],
  TEXT_ALIGN: ['left', 'center', 'right'],
  COLORS_PRESETS: [
    { color: '#000000', title: 'black' },
    { color: '#434343', title: 'dark gray 4' },
    { color: '#666666', title: 'dark gray 3' },
    { color: '#999999', title: 'dark gray 2' },
    { color: '#b7b7b7', title: 'dark gray 1' },
    { color: '#cccccc', title: 'gray' },
    { color: '#d9d9d9', title: 'light gray 1' },
    { color: '#efefef', title: 'light gray 2' },
    { color: '#f3f3f3', title: 'light gray 3' },
    { color: '#ffffff', title: 'white' },
    { color: '#980000', title: 'red berry' },
    { color: '#ff0000', title: 'red' },
    { color: '#ff9900', title: 'orange' },
    { color: '#ffff00', title: 'yellow' },
    { color: '#00ff00', title: 'green' },
    { color: '#00ffff', title: 'cyan' },
    { color: '#4a86ea', title: 'sky blue' },
    { color: '#0000ff', title: 'blue' },
    { color: '#9900ff', title: 'purple' },
    { color: '#ff00ff', title: 'magenta' },
    { color: '#e6b8af', title: 'light red berry 3' },
    { color: '#f4cccc', title: 'light red 3' },
    { color: '#fce5cd', title: 'light orange 3' },
    { color: '#fff2cc', title: 'light yellow 3' },
    { color: '#d9ead3', title: 'light green 3' },
    { color: '#d0e0e3', title: 'light cyan 3' },
    { color: '#c9daf8', title: 'light sky blue 3' },
    { color: '#cfe2f3', title: 'light blue 3' },
    { color: '#d9d2e9', title: 'light purple 3' },
    { color: '#ead1dc', title: 'light magenta 3' },
    { color: '#dd7e6b', title: 'light red berry 2' },
    { color: '#ea9999', title: 'light red 2' },
    { color: '#f9cb9c', title: 'light orange 2' },
    { color: '#ffe599', title: 'light yellow 2' },
    { color: '#b6d7a8', title: 'light green 2' },
    { color: '#a2c4c9', title: 'light cyan 2' },
    { color: '#a4c2f4', title: 'light sky blue 2' },
    { color: '#9fc5e8', title: 'light blue 2' },
    { color: '#b4a7d6', title: 'light purple 2' },
    { color: '#d5a6bd', title: 'light magenta 2' },
    { color: '#cc4125', title: 'light red berry 1' },
    { color: '#e06666', title: 'light red 1' },
    { color: '#f6b26b', title: 'light orange 1' },
    { color: '#ffd966', title: 'light yellow 1' },
    { color: '#93c47d', title: 'light green 1' },
    { color: '#76a5af', title: 'light cyan 1' },
    { color: '#6d9eeb', title: 'light sky blue 1' },
    { color: '#6fa8dc', title: 'light blue 1' },
    { color: '#8e7cc3', title: 'light purple 1' },
    { color: '#c27ba0', title: 'light magenta 1' },
    { color: '#a61c00', title: 'dark red berry 1' },
    { color: '#cc0000', title: 'dark red 1' },
    { color: '#e69138', title: 'dark orange 1' },
    { color: '#f1c232', title: 'dark yellow 1' },
    { color: '#6aa84f', title: 'dark green 1' },
    { color: '#45818e', title: 'dark cyan 1' },
    { color: '#3c78d8', title: 'dark sky blue 1' },
    { color: '#3d85c6', title: 'dark blue 1' },
    { color: '#674ea7', title: 'dark purple 1' },
    { color: '#a64d79', title: 'dark magenta 1' },
    { color: '#85200c', title: 'dark red berry 2' },
    { color: '#990000', title: 'dark red 2' },
    { color: '#b45f06', title: 'dark orange 2' },
    { color: '#bf9000', title: 'dark yellow 2' },
    { color: '#38761d', title: 'dark green 2' },
    { color: '#134f5c', title: 'dark cyan 2' },
    { color: '#1155cc', title: 'dark sky blue 2' },
    { color: '#0b5394', title: 'dark blue 2' },
    { color: '#351c75', title: 'dark purple 2' },
    { color: '#741b47', title: 'dark magenta 2' },
    { color: '#5b0f00', title: 'dark red berry 3' },
    { color: '#660000', title: 'dark red 3' },
    { color: '#783f04', title: 'dark orange 3' },
    { color: '#7f6000', title: 'dark yellow 3' },
    { color: '#274e13', title: 'dark green 3' },
    { color: '#0c343d', title: 'dark cyan 3' },
    { color: '#1c4587', title: 'dark sky blue 3' },
    { color: '#073763', title: 'dark blue 3' },
    { color: '#20124d', title: 'dark purple 3' },
    { color: '#4c1130', title: 'dark magenta 3' },
  ],
  USER_ROLES: [{ name: 'Admin', value: 'ADMIN' }, { name: 'User', value: 'USER' }],
};

export const ERRORS = {
  AFTER_END_DATE: 'Date should come before "end"',
  BEFORE_DATE_FROM: 'Date should come after "from"',
  BEFORE_START_DATE: '"End" date must be on or after "Start" date',
  MAX_CHARACTER_LENGTH: 'Maximum characters is 100',
  MIN_DURATION: 'Duration needs to be at least 1 day',
  MIN_TEMPLATE_SIZE: 'Number of Event Templates needs to be at least 1',
  REQUIRED_FIELD: 'Field is required.',
  POSITIVE_NUMBER_REQUIRED: 'Number should be positive',
  EMAIL_FIELD: 'Should be an email',
};

export const COLORS = {
  '#000000': 'black',
  '#434343': 'dark gray 4',
  '#666666': 'dark gray 3',
  '#999999': 'dark gray 2',
  '#b7b7b7': 'dark gray 1',
  '#cccccc': 'gray',
  '#d9d9d9': 'light gray 1',
  '#efefef': 'light gray 2',
  '#f3f3f3': 'light gray 3',
  '#ffffff': 'white',
  '#980000': 'red berry',
  '#ff0000': 'red',
  '#ff9900': 'orange',
  '#ffff00': 'yellow',
  '#00ff00': 'green',
  '#00ffff': 'cyan',
  '#4a86ea': 'sky blue',
  '#0000ff': 'blue',
  '#9900ff': 'purple',
  '#ff00ff': 'magenta',
  '#e6b8af': 'light red berry 3',
  '#f4cccc': 'light red 3',
  '#fce5cd': 'light orange 3',
  '#fff2cc': 'light yellow 3',
  '#d9ead3': 'light green 3',
  '#d0e0e3': 'light cyan 3',
  '#c9daf8': 'light sky blue 3',
  '#cfe2f3': 'light blue 3',
  '#d9d2e9': 'light purple 3',
  '#ead1dc': 'light magenta 3',
  '#dd7e6b': 'light red berry 2',
  '#ea9999': 'light red 2',
  '#f9cb9c': 'light orange 2',
  '#ffe599': 'light yellow 2',
  '#b6d7a8': 'light green 2',
  '#a2c4c9': 'light cyan 2',
  '#a4c2f4': 'light sky blue 2',
  '#9fc5e8': 'light blue 2',
  '#b4a7d6': 'light purple 2',
  '#d5a6bd': 'light magenta 2',
  '#cc4125': 'light red berry 1',
  '#e06666': 'light red 1',
  '#f6b26b': 'light orange 1',
  '#ffd966': 'light yellow 1',
  '#93c47d': 'light green 1',
  '#76a5af': 'light cyan 1',
  '#6d9eeb': 'light sky blue 1',
  '#6fa8dc': 'light blue 1',
  '#8e7cc3': 'light purple 1',
  '#c27ba0': 'light magenta 1',
  '#a61c00': 'dark red berry 1',
  '#cc0000': 'dark red 1',
  '#e69138': 'dark orange 1',
  '#f1c232': 'dark yellow 1',
  '#6aa84f': 'dark green 1',
  '#45818e': 'dark cyan 1',
  '#3c78d8': 'dark sky blue 1',
  '#3d85c6': 'dark blue 1',
  '#674ea7': 'dark purple 1',
  '#a64d79': 'dark magenta 1',
  '#85200c': 'dark red berry 2',
  '#990000': 'dark red 2',
  '#b45f06': 'dark orange 2',
  '#bf9000': 'dark yellow 2',
  '#38761d': 'dark green 2',
  '#134f5c': 'dark cyan 2',
  '#1155cc': 'dark sky blue 2',
  '#0b5394': 'dark blue 2',
  '#351c75': 'dark purple 2',
  '#741b47': 'dark magenta 2',
  '#5b0f00': 'dark red berry 3',
  '#660000': 'dark red 3',
  '#783f04': 'dark orange 3',
  '#7f6000': 'dark yellow 3',
  '#274e13': 'dark green 3',
  '#0c343d': 'dark cyan 3',
  '#1c4587': 'dark sky blue 3',
  '#073763': 'dark blue 3',
  '#20124d': 'dark purple 3',
  '#4c1130': 'dark magenta 3',
};

export const USER_ROLES = ['ADMIN', 'USER'];
