import React from 'react';
import format from 'date-fns/format';
import styled from 'styled-components';

import type { PassedProps } from './container';

import Export from '../Export';
import Modal, {
  Error,
  Field,
  FieldGroup,
  Header,
  Header2,
  IconClose,
  Input,
  PrimaryButton,
  Row,
  SecondaryButton,
} from '../Modal';

const OutOfScreen = styled.div`
  position: absolute;
  left: 100vw;
  top: 100vh;
`;

export default function ExportModal({
  isExportModalOpen,
  handleCloseModal,
  printReady,
  setPrintReady,
  config,
  location,
  // formik
  errors,
  handleChange,
  handleReset,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  setStatus,
  status,
  touched,
  values,
}: PassedProps) {
  const {
    title,
    from,
    to,
    client,
    calendarType,
    episodes,
    duration,
    filename,
    filenamePristine,
  } = values;

  const nextFilename = filenamePristine
    ? `${title} ${episodes} ${calendarType} ${format(new Date(), 'MM-DD-YY')}`
    : filename;

  const handleClose = () => {
    handleCloseModal();
    handleReset();
  };

  return (
    <Modal isOpen={isExportModalOpen}>
      <Header>
        <Header2>Export PDF</Header2>
        <IconClose onClick={handleClose} aria-label="close" />
      </Header>
      <form onSubmit={handleSubmit} key="form">
        <Row align="flex-end">
          <Field label="Project Name">
            <Input
              id="title"
              name="title"
              onChange={handleChange}
              type="input"
              value={title}
              placeholder="Example Show Name"
            />
            <Error>{touched.title && errors.title ? errors.title : null}</Error>
          </Field>
          <FieldGroup horizontal title="Export Range">
            <Field label="From">
              <Input id="from" name="from" onChange={handleChange} type="date" value={from} />
              <Error>{touched.from && errors.from ? errors.from : null}</Error>
            </Field>
            <Field label="To">
              <Input id="to" name="to" onChange={handleChange} type="date" value={to} />
              <Error>{touched.to && errors.to ? errors.to : null}</Error>
            </Field>
          </FieldGroup>
        </Row>
        <Row align="flex-end">
          <Field label="Client">
            <Input
              placeholder="Enter text here..."
              id="client"
              name="client"
              onChange={handleChange}
              type="text"
              value={client}
            />
            <Error>{touched.client && errors.client ? errors.client : null}</Error>
          </Field>
          <FieldGroup horizontal title="CODE">
            <Field label="Calendar Type">
              <Input
                id="calendarType"
                name="calendarType"
                onChange={handleChange}
                type="text"
                value={calendarType}
                placeholder="PROD CALENDAR"
              />
              <Error>
                {touched.calendarType && errors.calendarType ? errors.calendarType : null}
              </Error>
            </Field>
            <Field label="Episodes">
              <Input
                id="episodes"
                name="episodes"
                onChange={handleChange}
                type="text"
                value={episodes}
                placeholder="101-110"
              />
              <Error>{touched.episodes && errors.episodes ? errors.episodes : null}</Error>
            </Field>
            <Field label="Duration">
              <Input
                id="duration"
                name="duration"
                onChange={handleChange}
                type="text"
                value={duration}
                placeholder="(x30)"
              />
              <Error>{touched.duration && errors.duration ? errors.duration : null}</Error>
            </Field>
          </FieldGroup>
        </Row>
        <Row>
          <Field label="Filename">
            <Input
              id="filename"
              name="filename"
              onChange={({ target: { value } }) => {
                setFieldValue('filename', value);
                setFieldValue('filenamePristine', false);
              }}
              type="text"
              value={nextFilename}
            />
            <Error>{touched.filename && errors.filename ? errors.filename : null}</Error>
          </Field>
        </Row>
        <Row>
          <SecondaryButton
            type="button"
            margin="0 10px 0 0"
            onClick={handleClose}
            aria-label="cancel"
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton type="submit" aria-label="submit">
            {printReady ? 'Generating...' : 'Export'}
          </PrimaryButton>
        </Row>
      </form>
      {printReady && (
        <OutOfScreen>
          <Export callback={handleCloseModal} />
        </OutOfScreen>
      )}
    </Modal>
  );
}
