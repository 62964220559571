import { compose, dropLast, reduce } from 'ramda';

import type { FirebaseCategories, Styles } from '../models';

type OptionalStyles = Styles | {};

type FlatCategory = {
  categories?: FirebaseCategories,
  id: string,
  label?: string,
  name: string,
  path?: Array<string>,
  value?: string,
  styles?: OptionalStyles,
};

type ObjectWithID = {
  id: string,
};

export function flattenObjectOfCategories(categories: FirebaseCategories) {
  return Object.keys(categories).reduce((acc, curr) => {
    return acc.concat(
      Object.assign(
        {},
        {
          categories: categories[curr].categories || null,
          id: curr,
          name: categories[curr].name,
          path: categories[curr].path || [],
        },
      ),
    );
  }, []);
}

export function flattenObjectOfCategoriesWithStyles(categories: FirebaseCategories) {
  return Object.keys(categories).reduce((acc, curr) => {
    return acc.concat(
      Object.assign(
        {},
        {
          categories: categories[curr].categories || null,
          id: curr,
          name: categories[curr].name,
          path: categories[curr].path || [],
          styles: categories[curr].styles || {},
        },
      ),
    );
  }, []);
}

export function flattenArrayOfCategories(categories: Array<FlatCategory>) {
  return categories.reduce((acc, curr) => {
    if (curr.categories) {
      const flattenCategories = flattenObjectOfCategories(curr.categories);
      return flattenArrayOfCategories([
        ...acc,
        ...flattenCategories,
        { id: curr.id, name: curr.name, path: curr.path || [] },
      ]);
    }
    acc.push({ id: curr.id, name: curr.name, value: curr.id, label: curr.name, path: curr.path });
    return acc;
  }, []);
}

export function flattenArrayOfCategoriesWithStyles(categories: Array<FlatCategory>) {
  return categories.reduce((acc, curr) => {
    if (curr.categories) {
      const flattenCategories = flattenObjectOfCategoriesWithStyles(curr.categories);
      return flattenArrayOfCategoriesWithStyles([
        ...acc,
        ...flattenCategories,
        { id: curr.id, name: curr.name, path: curr.path || [], styles: curr.styles || {} },
      ]);
    }
    acc.push({
      id: curr.id,
      label: curr.name,
      name: curr.name,
      path: curr.path,
      styles: curr.styles,
      value: curr.id,
    });
    return acc;
  }, []);
}

export function flattenArrayToObject(array: Array<ObjectWithID>) {
  return array.reduce((acc, curr) => {
    const { id, ...rest } = curr;
    acc[id] = rest;
    return acc;
  }, {});
}

export const flattenCategories = compose(flattenArrayOfCategories, flattenObjectOfCategories);

export const flattenCategoriesWithStyles = compose(
  flattenArrayOfCategoriesWithStyles,
  flattenObjectOfCategoriesWithStyles,
);

export function buildCategoryPath(path: string[]) {
  return compose(reduce((acc, id) => [...acc, id, 'categories'], []), dropLast(1))(path);
}

export function buildBaseCategoryPath(path: string[]) {
  return compose(dropLast(1), reduce((acc, id) => [...acc, id, 'categories'], []))(path);
}

export function buildCategoriesPath(path: string[]) {
  return reduce((acc, id) => [...acc, id, 'categories'], [])(path);
}
