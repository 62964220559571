import styled from 'styled-components';

const Icon = styled.div.attrs({
  name: props => props.name || 'no_name_provided',
  height: props => props.height || '100px',
  width: props => props.width || '100px',
  color: props => props.color || 'white',
})`
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: ${props => props.color};
  -webkit-mask: url(${process.env.PUBLIC_URL}/img/${props => props.name}.svg) no-repeat 50% 50%;
  mask: url(${process.env.PUBLIC_URL}/img/${props => props.name}.svg) no-repeat 50% 50%;
`;

export default Icon;
