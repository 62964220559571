import { mapProps } from 'recompose';
import { length, path, pathOr, dropLast, keys, compose } from 'ramda';
import { buildCategoryPath, buildCategoriesPath } from '../../helpers/categories';

type RowProps = {
  id: string,
  name: string,
};

type DividerProps = {
  index: number,
};

type Item = {
  type: 'row' | 'divider',
  selected: boolean,
  props: RowProps | DividerProps,
};

const canDrillDown = (categories: any, id: string, currentCategoryId: string) =>
  !!compose(length(), keys(), pathOr([], [id, 'categories']))(categories) &&
  id !== currentCategoryId;

const handleLocationChange = (path: string[], idx: number, id: string, fn) => (e: Event) => {
  e.stopPropagation();
  fn(path, idx, id);
};

const generateItems = ({
  categories,
  path: basePath,
  categoryId,
  index,
  onLocationChange: fn,
  currentCategory = {},
}): Item[] => {
  const catsPath = buildCategoryPath([...basePath, categoryId]);
  const cats = path(catsPath, categories);
  return keys(cats)
    .reduce((acc, id) => [...acc, { ...cats[id], id }], [])
    .sort((a, b) => a.weight - b.weight)
    .reduce((acc, { id }, idx, arr) => {
      const list = [
        ...acc,
        {
          type: 'divider',
          props: {
            selected: index === idx,
            index: idx,
            onClick: () => fn(basePath, idx, ''),
          },
        },
        {
          type: 'row',
          props: {
            id,
            name: path([id, 'name'], cats),
            selected: categoryId === id,
            onDrillDown: canDrillDown(cats, id, currentCategory.id)
              ? handleLocationChange([...basePath, id], -1, '', fn)
              : null,
            onClick: handleLocationChange(basePath, -1, id, fn),
          },
        },
      ];

      if (idx === arr.length - 1) {
        // last one
        list.push({
          type: 'divider',
          props: {
            selected: index === idx + 1,
            index: idx + 1,
            onClick: () => fn(basePath, idx + 1, ''),
          },
        });
      }

      return list;
    }, []);
};

const maybeParentName = ({ path: basePath, categories }): string => {
  if (!basePath.length) return '';

  const fullPath = compose(dropLast(1), buildCategoriesPath)(basePath);
  const parentCategory = path(fullPath, categories);
  return parentCategory.name;
};

const handleBackClick = ({ path: basePath, index, onLocationChange }) => () => {
  const nextPath = basePath.length ? basePath.slice(0, basePath.length - 1) : basePath;

  onLocationChange(nextPath, -1, '');
};

export default mapProps(ownerProps => ({
  items: generateItems(ownerProps),
  path: ownerProps.path,
  maybeParentName: maybeParentName(ownerProps),
  handleBackClick: handleBackClick(ownerProps),
}));
