import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
// $FlowFixMe
import { ActionCreators as UndoActionCreators } from 'redux-undo';

import { canRedo, canUndo } from '../../store/selectors';

const withStore = connect(
  state => ({
    canRedo: canRedo(state),
    canUndo: canUndo(state),
  }),
  {
    onUndo: UndoActionCreators.undo,
    onRedo: UndoActionCreators.redo,
  },
);

export type PassedProps = {
  handleRedo: () => void,
  handleUndo: () => void,
  canRedo: boolean,
  canUndo: boolean,
};

type Props = {
  render: any,
  onRedo: () => void,
  onUndo: () => void,
  canRedo: boolean,
  canUndo: boolean,
};

type State = {};

class Undo extends Component<Props, State> {
  render() {
    const { canRedo, canUndo, onRedo, onUndo } = this.props;

    const props = {
      handleRedo: onRedo,
      handleUndo: onUndo,
      canRedo,
      canUndo,
    };

    return <div>{this.props.render({ ...props })}</div>;
  }
}

export default compose(withStore)(Undo);
