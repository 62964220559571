import { flip, curry, compose } from 'ramda';
import format from 'date-fns/format/index';
import addDays from 'date-fns/add_days/index';
import addHours from 'date-fns/add_hours/index';
import addMinutes from 'date-fns/add_minutes/index';
import differenceInMilliseconds from 'date-fns/difference_in_milliseconds/index';

export function toArray(events: any[]) {
  return Object.values(events);
}

export const fAddHours = compose(flip, curry)(addHours);

export const fAddMinutes = compose(flip, curry)(addMinutes);

export const fAddDays = compose(flip, curry)(addDays);

export const duration = (start: Date, end: Date) => differenceInMilliseconds(end, start);

export const defaultEventStart = compose(format, fAddHours(8));

export const defaultEventEnd = compose(format, fAddHours(16));
