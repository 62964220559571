import React from 'react';
import styled from 'styled-components';

import { colors } from '../theme';

const Header = styled.div`
  align-items: center;
  border: 1px solid ${colors('gray')};
  border-top: 0;
  display: flex;
  justify-content: space-between;
  padding: 10px ${props => (props.noPadding ? 0 : '10px')};
`;

const TextContainer = styled.div`
  align-items: ${props => props.align};
  display: flex;
  flex-direction: column;
  flex: 1;

  > * {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const DayHeader = styled.small`
  flex: 1;
  text-align: center;
  text-transform: uppercase;
`;

type PassedProps = {
  title: string,
  client: string,
  calendarType: string,
  episodes: string,
  duration: number,
  month: string,
  year: string,
};

export default ({ title, client, calendarType, episodes, duration, month, year }: PassedProps) => (
  <div>
    <Header>
      <TextContainer>
        <strong>{title}</strong>
        <small>
          <strong>{client}</strong>
        </small>
      </TextContainer>
      <TextContainer align="center">
        <strong>
          {month} {year}
        </strong>
      </TextContainer>
      <TextContainer align="flex-end">
        <small>{new Date().toLocaleDateString()}</small>
        <small>{`${calendarType}, ${episodes && episodes.match(/^\d/)
          ? 'EPS'
          : ''} ${episodes} ${duration}`}</small>
      </TextContainer>
    </Header>
    <Header noPadding>
      {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, idx) => (
        <DayHeader key={`${day}.${idx}`}>{day}</DayHeader>
      ))}
    </Header>
  </div>
);
