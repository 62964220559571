import React from 'react';
import styled from 'styled-components';

import type { PassedProps } from './container';
import { USER_ROLES } from '../constants';
import { colors } from '../theme';
import UserTree from './UserTree';

import Modal, {
  Error,
  Field,
  Header,
  Header2,
  IconClose,
  Input,
  SecondaryButton,
  Row,
  FieldGroup,
  Button,
} from '../Modal';
import { StyledDropdown } from '../EventModal/components';

export const AddButton = Button.extend`
  background-color: ${colors('teal')};
  border-radius: 0.2em;
  color: #ffffff;
  padding: 1em;
  width: 100px;
  margin-top: 15px;
`;

const Alert = styled.div`
  color: ${props => props.color || '#055160'};
  background-color: ${props => props.backgroundColor || '#cff4fc'};
  border-color: ${props => props.borderColor || '#b6effb'};
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
`;

export default function UsersModal({
  isUsersModalOpen,
  handleCloseModal,
  errors,
  handleChange,
  handleReset,
  handleSubmit,
  setFieldValue,
  isSubmitting,
  touched,
  values,
  handleDelete,
  handleUpdateRole,
  users,
  setUserAdded,
  setError,
  isUserAdded,
  error,
}: PassedProps) {
  const { email, role } = values;

  const handleClose = () => {
    handleCloseModal();
    handleReset();
  };

  return (
    <Modal isOpen={isUsersModalOpen}>
      <Header>
        <Header2>Users Management</Header2>
        <IconClose onClick={handleClose} aria-label="close" />
      </Header>
      <Header>
        <h3>Add User</h3>
      </Header>
      {isSubmitting && <Alert>Adding user</Alert>}
      {isUserAdded && (
        <Alert backgroundColor="#d1e7dd" borderColor="#badbcc">
          User registered successfully.
        </Alert>
      )}
      {error && (
        <Alert backgroundColor="#f8d7da" borderColor="#f5c2c7">
          An error has ocured: {error}
        </Alert>
      )}
      <form onSubmit={handleSubmit} key="form" style={{ paddingTop: '10px' }}>
        <Row align="flex-end">
          <FieldGroup horizontal>
            <Field label="Email">
              <Input
                id="email"
                name="email"
                onChange={handleChange}
                type="input"
                value={email}
                placeholder="Add user email"
                padding="9px"
              />
              <Error>{touched.email && errors.email ? errors.email : null}</Error>
            </Field>
            <Field label="Role">
              <StyledDropdown
                onChange={({ value }) => setFieldValue('role', value)}
                options={USER_ROLES}
                value={role}
              />
              <Error>{touched.role && errors.role ? errors.role : null}</Error>
            </Field>
            <Field>
              <AddButton type="submit" aria-label="submit" onClick={handleSubmit}>
                Add
              </AddButton>
            </Field>
          </FieldGroup>
        </Row>
        <UserTree users={users} handleDelete={handleDelete} handleUpdateRole={handleUpdateRole} />
        <Row align="flex-end">
          <SecondaryButton
            type="button"
            margin="0 10px 0 0"
            onClick={handleClose}
            aria-label="cancel"
          >
            Close
          </SecondaryButton>
        </Row>
      </form>
    </Modal>
  );
}
