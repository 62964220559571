import React from 'react';
import styled from 'styled-components';
import NavBar from './NavBar';

const Layout = styled.div`
  flex: 1 0 auto;
  flex-direction: column;
  height: 100vh;
`;

const Body = styled.div`
  display: flex;
  padding-top: 50px;
  padding-left: 400px;
`;

export default function({ children, isAdmin }) {
  return (
    <Layout>
      <NavBar isAdmin={isAdmin} />
      <Body>{children}</Body>
    </Layout>
  );
}
