import React, { Component } from 'react';
import { compose } from 'recompose';
import { isEmpty } from 'ramda';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Spinner from 'react-spinkit';

import { calendarId } from '../../../helpers/props';
import { normalizeCategories } from '../../../helpers/normalizers';
import { PathBuilder } from '../../../helpers/pathBuilder';
import rebase from '../../../rebase';
import { openModal } from '../../../store/actions';
import type {
  Category,
  FirebaseEvents,
  FirebaseCategory,
  FirebaseCategories,
  FirebaseCalendar,
} from '../../../models';

const withStore = connect(null, { openModal });

function handlers(WrappedComponent) {
  type State = {
    isAddingCategory: boolean,
    categoryName: string,
    calendar?: FirebaseCalendar,
  };

  type Props = {
    match: any,
    categories: FirebaseCategories,
    events: FirebaseEvents,
    openModal: any => void,
  };

  return class extends Component<Props, State> {
    constructor(props) {
      super(props);
      this.state = {
        isAddingCategory: false,
        categoryName: '',
        calendar: undefined,
        ui: {
          modalEventTemplate: {
            isOpen: false,
          },
        },
      };
    }

    ref: any;
    path: any;

    componentDidMount() {
      this.path = new PathBuilder(calendarId(this.props));

      this.ref = rebase.bindToState(this.path.calendar(), {
        context: this,
        state: 'calendar',
        asArray: false,
      });

      rebase.bindToState(this.path.state(), {
        context: this,
        state: 'ui',
        asArray: false,
      });
    }

    componentWillUnmount() {
      rebase.removeBinding(this.ref);
    }

    handleOpenEventTemplateModal = () => {
      rebase.update(this.path.state(), {
        data: { modalEventTemplate: { isOpen: true } },
      });
    };

    handleAddCategory = () => {
      this.props.openModal({
        type: 'category:create',
        path: [],
        index: -1,
        categoryId: '',
      });
    };

    handleCategoryNameUpdate = ({ target }) => {
      this.setState({ categoryName: target.value });
    };

    handleCategoryNameAction = ({ key }) => {
      const { categoryName } = this.state;

      const lastCategory = this._lastCategory() || {};
      const lastWeight = lastCategory.weight || 0;

      if (key === 'Escape') {
        this.setState(() => ({ isAddingCategory: false, categoryName: '' }));
      } else if (key === 'Enter') {
        const prefix = this.path.categories();
        const category: Category = {
          categories: [],
          path: [],
          eventTemplates: [],
          id: '',
          name: categoryName,
          weight: lastWeight + 1000,
          styles: {
            backgroundColor: '',
            borderColor: '',
            borderStyle: '',
            borderWidth: '',
            color: '',
            fontFamily: '',
            fontSize: '',
            fontStyle: '',
            fontWeight: '',
            textAlign: '',
          },
        };
        rebase.push(prefix, { data: category });
        this.setState({ isAddingCategory: false, categoryName: '' });
      }
    };

    _lastCategory = (): ?FirebaseCategory => {
      const { categories } = this.props;
      if (categories == null) {
        return null;
      }
      return categories[Object.keys(categories)[Object.keys(categories).length - 1]];
    };

    render() {
      const { calendar } = this.state;
      const { events, categories } = this.props;
      if (calendar === undefined || isEmpty(categories)) {
        return <Spinner />;
      } else {
        return (
          <WrappedComponent
            {...this.props}
            {...this.state}
            categories={normalizeCategories(categories, events)}
            handleAddCategory={this.handleAddCategory}
            handleCategoryNameAction={this.handleCategoryNameAction}
            handleCategoryNameUpdate={this.handleCategoryNameUpdate}
            handleOpenEventTemplateModal={this.handleOpenEventTemplateModal}
          />
        );
      }
    }
  };
}

export default compose(withStore, withRouter, handlers);
