import React from 'react';
import { prop } from 'ramda';

import { DROPDOWN_OPTIONS, COLORS } from '../constants';
import Modal, {
  CloseButton,
  CloseIcon,
  Error,
  Field,
  HR,
  Header,
  Input,
  PrimaryButton,
  Row,
  SecondaryButton,
} from '../Modal';

import {
  Color,
  ColorField,
  ColorPicker,
  Form,
  Header2,
  RowBottom,
  StyledDropdown,
  StyledRow,
} from '../EventModal/components';

import type { PassedProps } from './container';
import TreeNav from '../TreeNav';

const StyledModal = Modal.extend`
  height: ${prop('height')};
`;

const StyledLocationSelectorRow = Row.extend`
  align-items: unset;
  flex-direction: column;
`;

export default function CategoryModal({
  handleChangeStyles,
  handleCloseModal,
  handleShowPicker,
  handleLocationChange,
  height = '700px',
  type,
  isOpen,
  config,
  showBackgroundColor,
  showBorderColor,
  showColor,
  // formik
  errors,
  handleChange,
  handleReset,
  handleSubmit,
  isSubmitting,
  resetForm,
  setFieldValue,
  setTouched,
  touched,
  values,
  categories,
  currentCategory,
  location,
}: PassedProps) {
  const { name = '', ...styles } = values;
  const isEdit = type === 'category:edit';

  const handleClose = (): void => {
    handleCloseModal();
    handleReset();
  };

  if (!isOpen || type.split(':')[0] !== 'category') {
    // TODO: remove when we have proper routing in place for modals
    // this will force an unmount when modals are closed
    return null;
  }

  return (
    <StyledModal isOpen={isOpen} height={height}>
      <Header>
        <Header2>{isEdit ? 'Edit' : 'New'} Category</Header2>
        <CloseButton onClick={handleClose} aria-label="close">
          <CloseIcon name="close" width="15px" height="15px" />
        </CloseButton>
      </Header>
      <HR />
      <Form onSubmit={handleSubmit} key="category-modal-form">
        <StyledLocationSelectorRow>
          <Field label="Name">
            <Input
              spellCheck={true}
              id="name"
              name="name"
              type="input"
              value={name}
              onChange={handleChange}
            />
            <Error>{touched.name && errors.name ? errors.name : null}</Error>
          </Field>
          <Field label="Choose Location">
            <TreeNav
              categories={categories}
              {...config}
              onLocationChange={handleLocationChange}
              isEdit={isEdit}
            />
          </Field>
        </StyledLocationSelectorRow>
        {/* BEGIN FONT STYLES */}
        <StyledRow>
          <Field label="Font Color">
            <ColorField onClick={handleShowPicker('showColor')}>
              {/* $FlowFixMe */}
              <Color color={styles.color}>{COLORS[styles.color] || styles.color}</Color>
            </ColorField>
            {showColor && (
              <ColorPicker
                color={styles.color}
                onChange={value => {
                  setFieldValue('color', value.hex);
                  handleShowPicker('showColor')();
                }}
                onMouseLeave={handleShowPicker('showColor')}
              />
            )}
            <Error>{touched.color && errors.color ? errors.color : null}</Error>
          </Field>
          <Field label="Font Style">
            <StyledDropdown
              onChange={({ value }) => setFieldValue('fontStyle', value)}
              options={DROPDOWN_OPTIONS.FONT_STYLE}
              value={styles.fontStyle}
            />
            <Error>{touched.fontStyle && errors.fontStyle ? errors.fontStyle : null}</Error>
          </Field>
          <Field label="Font Size">
            <StyledDropdown
              onChange={({ value }) => setFieldValue('fontSize', value)}
              options={DROPDOWN_OPTIONS.FONT_SIZE}
              value={styles.fontSize}
            />
            <Error>{touched.fontSize && errors.fontSize ? errors.fontSize : null}</Error>
          </Field>
          <Field label="Font Weight">
            <StyledDropdown
              onChange={({ value }) => setFieldValue('fontWeight', value)}
              options={DROPDOWN_OPTIONS.FONT_WEIGHT}
              value={styles.fontWeight}
            />
            <Error>{touched.fontWeight && errors.fontWeight ? errors.fontWeight : null}</Error>
          </Field>
        </StyledRow>
        {/* END FONT STYLES*/}

        {/* BEGIN BOX STYLES */}
        <StyledRow>
          <Field label="Background Color">
            <ColorField onClick={handleShowPicker('showBackgroundColor')}>
              <Color color={styles.backgroundColor}>
                {/* $FlowFixMe */}
                {COLORS[styles.backgroundColor] || styles.backgroundColor}
              </Color>
            </ColorField>
            {showBackgroundColor && (
              <ColorPicker
                color={styles.backgroundColor}
                onChange={value => {
                  setFieldValue('backgroundColor', value.hex);
                  handleShowPicker('showBackgroundColor')();
                }}
                onMouseLeave={handleShowPicker('showBackgroundColor')}
              />
            )}
            <Error>
              {touched.backgroundColor && errors.backgroundColor ? errors.backgroundColor : null}
            </Error>
          </Field>
          <Field label="Border Color">
            <ColorField onClick={handleShowPicker('showBorderColor')}>
              <Color color={styles.borderColor}>
                {/* $FlowFixMe */}
                {COLORS[styles.borderColor] || styles.borderColor}
              </Color>
            </ColorField>
            {showBorderColor && (
              <ColorPicker
                color={styles.borderColor}
                onChange={value => {
                  setFieldValue('borderColor', value.hex);
                  handleShowPicker('showBorderColor')();
                }}
                onMouseLeave={handleShowPicker('showBorderColor')}
              />
            )}
            <Error>{touched.borderColor && errors.borderColor ? errors.borderColor : null}</Error>
          </Field>
          <Field label="Border Style">
            <StyledDropdown
              onChange={({ value }) => setFieldValue('borderStyle', value)}
              options={DROPDOWN_OPTIONS.BORDER_STYLE}
              value={styles.borderStyle}
            />
            <Error>{touched.borderStyle && errors.borderStyle ? errors.borderStyle : null}</Error>
          </Field>
          <Field label="Border Width">
            <StyledDropdown
              onChange={({ value }) => setFieldValue('borderWidth', value)}
              options={DROPDOWN_OPTIONS.BORDER_WIDTH}
              value={styles.borderWidth}
            />
            <Error>{touched.borderWidth && errors.borderWidth ? errors.borderWidth : null}</Error>
          </Field>
        </StyledRow>
        <StyledRow>
          <Field label="Text Align">
            <StyledDropdown
              onChange={({ value }) => setFieldValue('textAlign', value)}
              options={DROPDOWN_OPTIONS.TEXT_ALIGN}
              value={styles.textAlign}
            />
            <Error>{touched.textAlign && errors.textAlign ? errors.textAlign : null}</Error>
          </Field>
        </StyledRow>
        {/* END BOX STYLES */}

        <RowBottom>
          <SecondaryButton type="button" onClick={handleClose} aria-label="cancel">
            Cancel
          </SecondaryButton>
          <PrimaryButton aria-label="submit" type="submit">
            {isSubmitting ? 'Submitting...' : 'Save'}
          </PrimaryButton>
        </RowBottom>
      </Form>
    </StyledModal>
  );
}
