import msg from '../messages';
import { omit } from 'ramda';
import type { Event } from '../../models';
import type { Modal, Sidebar } from '../types';

type State = {
  categoryId: string,
  modal: Modal,
  event: Event | {},
  sidebar: Sidebar,
  isAddCalendarModalOpen: boolean,
  isAddingCategory: boolean,
  isEventModalOpen: boolean,
  isExportModalOpen: boolean,
  isUsersModalOpen: boolean,
  selectedDate: string,
  selectedEvent: Event | {},
  isNewCalendar: boolean,
  selectedEvents: Event[],
};

type Action = {
  type: string,
  payload: any,
};

const initialState = {
  categoryId: '',
  copiedEvent: {},
  event: {},
  isAddCalendarModalOpen: false,
  isAddingCategory: false,
  isEventModalOpen: false,
  isExportModalOpen: false,
  isUsersModalOpen: false,
  selectedDate: '',
  selectedEvent: {},
  selectedEvents: [],
  isNewCalendar: false,
  modal: {
    type: 'none',
    isOpen: false,
    config: {},
  },
  sidebar: {
    selections: {
      type: 'none',
      items: {},
    },
  },
};

export default function ui(state: State = initialState, action: Action): State {
  const { type, payload } = action;
  switch (type) {
    case msg.OPEN_EXPORT_MODAL: {
      return { ...state, isExportModalOpen: true };
    }
    case msg.CLOSE_EXPORT_MODAL: {
      return { ...state, isExportModalOpen: false };
    }
    case msg.OPEN_ADD_CALENDAR_MODAL: {
      return { ...state, isAddCalendarModalOpen: true };
    }
    case msg.CLOSE_ADD_CALENDAR_MODAL: {
      return { ...state, isAddCalendarModalOpen: false };
    }
    case msg.CLOSE_EVENT_MODAL: {
      return { ...state, isEventModalOpen: false };
    }
    case msg.TOGGLE_ADD_EVENT_MODAL: {
      return { ...state, isEventModalOpen: !state.isEventModalOpen };
    }
    case msg.OPEN_ADD_EVENT_MODAL: {
      return { ...state, isEventModalOpen: true, event: payload };
    }
    case msg.NOT_ADDING_CATEGORY_INPUT: {
      return { ...state, isAddingCategory: false };
    }
    case msg.OPEN_MODAL: {
      const { type, ...config } = payload;
      return {
        ...state,
        modal: {
          type,
          config,
          isOpen: true,
        },
      };
    }
    case msg.CLOSE_MODAL: {
      return {
        ...state,
        modal: {
          type: 'none',
          config: {},
          isOpen: false,
        },
      };
    }
    case msg.UPDATE_CATEGORY_MODAL_CATEGORY_LOCATION: {
      return {
        ...state,
        modal: {
          type: state.modal.type,
          config: {
            ...state.modal.config,
            ...payload,
          },
          isOpen: true,
        },
      };
    }
    case msg.OPEN_USERS_MODAL: {
      return { ...state, isUsersModalOpen: true };
    }
    case msg.CLOSE_USERS_MODAL: {
      return { ...state, isUsersModalOpen: false };
    }
    case msg.SELECT_DAY_CELL: {
      return { ...state, selectedDate: action.payload };
    }
    case msg.SELECT_EVENT: {
      return { ...state, selectedEvent: action.payload };
    }
    case msg.SELECT_EVENTS: {
      return { ...state, selectedEvents: action.payload };
    }
    case msg.COPY_EVENT: {
      return { ...state, copiedEvent: action.payload };
    }
    case msg.CLEAR_COPY_PASTE: {
      return { ...state, copiedEvent: {}, selectedEvent: {} };
    }
    case msg.TOGGLE_SIDEBAR_EVENT_TEMPLATE_SELECTION: {
      const { sidebar } = state;
      const currItems = sidebar.selections.items;
      const maybeSelectedItem = currItems[payload];
      let items = maybeSelectedItem
        ? omit([payload], currItems)
        : { ...currItems, [payload]: true };

      return {
        ...state,
        sidebar: {
          ...sidebar,
          selections: {
            type: 'event-template',
            items,
          },
        },
      };
    }
    case msg.RESET_SIDEBAR_EVENT_TEMPLATE_SELECTION: {
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          selections: {
            ...initialState.sidebar.selections,
          },
        },
      };
    }
    case msg.TOGGLE_CALENDAR_NEWNESS: {
      return { ...state, isNewCalendar: payload.isNew };
    }
    default: {
      return state;
    }
  }
}
