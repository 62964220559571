import msg from '../messages';

type State = {
  selectedCalendarDate: Date | null,
};

type Action = {
  type: string,
  payload: any,
};

const initialState = {
  selectedCalendarDate: null,
};

export default function calendar(state: State = initialState, action: Action): State {
  const { type, payload } = action;
  switch (type) {
    case msg.SELECT_DATE: {
      return { ...state, selectedCalendarDate: payload };
    }
    default: {
      return state;
    }
  }
}
