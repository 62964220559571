import React from 'react';

import type { PassedProps } from './container';

import Modal, {
  Row,
  Header,
  Field,
  Input,
  PrimaryButton,
  SecondaryButton,
  CloseButton,
} from '../Modal';

const SaveAsModal = Modal.extend`
  width: 300px;
`;

export default ({
  isSaveAsModalOpen,
  handleCloseModal,
  handleChange,
  handleSubmit,
  name = '',
  calendar: { name: calendarName },
}: PassedProps) => (
  <SaveAsModal isOpen={isSaveAsModalOpen}>
    <Header>
      <h3>Save As</h3>
      <CloseButton onClick={handleCloseModal} aria-label="close">
        x
      </CloseButton>
    </Header>
    <form onSubmit={handleSubmit} key="form">
      <Row>
        <Field label="Name">
          <Input
            id="name"
            name="name"
            onChange={handleChange}
            spellCheck={true}
            type="input"
            value={name || calendarName}
          />
        </Field>
      </Row>
      <Row>
        <SecondaryButton
          type="button"
          margin="0 10px 0 0"
          onClick={handleCloseModal}
          aria-label="cancel"
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton type="submit" aria-label="submit">
          Save As
        </PrimaryButton>
      </Row>
    </form>
  </SaveAsModal>
);
