import React, { Component } from 'react';
import { compose } from 'recompose';
import { pathOr, omit, prop, sortBy } from 'ramda';
import { withFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import cuid from 'cuid';
import yup from 'yup';

import { PathBuilder, genName, flattenCategories, calendarId } from '../../helpers';
import { ERRORS } from '../constants';
import rebase, { firebase } from '../../rebase';
import type { FirebaseCategories } from '../../models';
import type { PathObject } from '../../helpers/pathBuilder';

const checkIsOpen = pathOr(false, ['modalEventTemplate', 'isOpen']);
const omitCategories = omit(['categories']);
const sortedCategories = compose(sortBy(prop('name')), flattenCategories);

function withHandlers(WrappedComponent) {
  type State = {
    ui: {
      modalEventTemplate: {
        isOpen: boolean,
      },
    },
  };

  type Props = {
    categories: FirebaseCategories,
    match: any,
  };
  return class EventTemplateModalHandler extends Component<Props, State> {
    constructor(props) {
      super(props);
      this.state = {
        ui: {
          modalEventTemplate: {
            isOpen: false,
          },
        },
      };
    }

    ref: State;
    path: PathObject;

    componentDidMount() {
      this.path = new PathBuilder(calendarId(this.props));

      this.ref = rebase.bindToState(this.path.state(), {
        context: this,
        state: 'ui',
        asArray: false,
      });
    }

    componentWillUnmount() {
      rebase.removeBinding(this.ref);
    }

    handleCloseModal = () => {
      rebase.update(this.path.state('modalEventTemplate'), { data: { isOpen: false } });
    };

    render() {
      return (
        <WrappedComponent
          {...{
            categories: sortedCategories(this.props.categories),
            handleCloseModal: this.handleCloseModal,
            isOpen: checkIsOpen(this.state.ui),
            path: this.path,
          }}
          {...omitCategories(this.props)}
        />
      );
    }
  };
}

export default compose(
  withRouter,
  withHandlers,
  withFormik({
    mapPropsToValues: props => ({
      name: '',
      duration: 1,
      category: { label: '', value: '' },
      startingNumber: '',
      eventTemplatesSize: 1,
    }),
    validationSchema: () => {
      const schema = yup.object().shape({
        name: yup
          .string()
          .required(ERRORS.REQUIRED_FIELD)
          .max(100, ERRORS.MAX_CHARACTER_LENGTH),
        duration: yup.number().min(1, ERRORS.MIN_DURATION),
        eventTemplatesSize: yup.number().min(1, ERRORS.MIN_TEMPLATE_SIZE),
        category: yup.object({
          value: yup.string().required(ERRORS.REQUIRED_FIELD),
          label: yup.string().required(),
        }),
      });

      return schema;
    },
    handleSubmit: (values, actions) => {
      const { name, duration, category, startingNumber, eventTemplatesSize } = values;
      const { props, resetForm } = actions;
      let etName, etId;

      const categoriesObject = props.categories.reduce((acc, curr) => {
        const { id, ...rest } = curr;
        acc[id] = rest;
        return acc;
      }, {});
      const ancestors = categoriesObject[category.value].path;
      const path = props.path.eventTemplates(ancestors);

      props.handleCloseModal();

      rebase
        .fetch(path, {
          context: this,
          asArray: true,
        })
        .then(eventTemplate => {
          const size = eventTemplate.length;
          const updates = {};

          const nameWords = name.split('%num%').filter(n => !!n);
          const hasMidFormat = nameWords.length > 1;
          const keyName = nameWords[0].trim();

          for (let i = 1; i <= ancestors.length; i++) {
            updates[`${props.path.nestedCategory(ancestors.slice(0, i))}/visible`] = true;
          }

          for (let index = 0; index < eventTemplatesSize; index++) {
            const format = 'edit' === keyName.toLowerCase() ? '%event% %num% - Week' : undefined;
            etId = cuid();
            etName = genName(keyName, startingNumber, 1, format, index);

            if (hasMidFormat) {
              const rest = nameWords.slice(1, nameWords.length).join(' ');
              etName = `${etName} ${rest}`;
            }

            updates[`${path}/${etId}`] = {
              name: etName,
              weight: (index + 1 + size) * 1000,
              duration,
              visible: true,
            };
          }

          firebase
            .database()
            .ref()
            .update(updates);
        });

      resetForm();
    },
  }),
);
