import React, { Component } from 'react';
import cuid from 'cuid';
import { compose } from 'recompose';
import { saveAs } from 'file-saver';
import { toTOMLString } from 'toml-stream';
import { withRouter } from 'react-router';

import rebase, { firebase } from '../../rebase';
import { PathBuilder, calendarId } from '../../helpers';

import type { FirebaseCategories, FirebaseEvents } from '../../models';
import type { PathObject } from '../../helpers';

export type PassedProps = {
  isSaveAsModalOpen: boolean,
  handleCloseModal: () => void,
  handleChange: () => void,
  handleSubmit: () => void,
  name: string,
  calendar: { name: string },
};

function withHandlers(WrappedComponent) {
  type Props = {
    categories: FirebaseCategories | {},
    events: FirebaseEvents,
    history: any,
  };

  type State = {
    calendar: { name: string },
    name: string,
    ui: {
      isSaveAsModalOpen: boolean,
    },
  };

  return class extends Component<Props, State> {
    state = {
      calendar: { name: '' },
      categories: {},
      events: {},
      name: '',
      ui: { isSaveAsModalOpen: false },
    };

    ref: () => mixed;
    path: PathObject;

    componentDidMount() {
      this.path = new PathBuilder(calendarId(this.props));

      this.ref = rebase.bindToState(this.path.calendar(), {
        context: this,
        state: 'calendar',
        asArray: false,
      });

      rebase.syncState(this.path.state(), {
        context: this,
        state: 'ui',
        asArray: false,
      });
    }

    componentWillUnmount() {
      rebase.removeBinding(this.ref);
    }

    handleChange = ({ target: { name, value } }) => {
      this.setState({
        [name]: value,
      });
    };

    handleCloseModal = () => {
      const { ui } = this.state;
      this.setState({
        ui: {
          ...ui,
          isSaveAsModalOpen: false,
        },
      });
    };

    handleSubmit = e => {
      e.preventDefault();

      const { history, events, categories } = this.props;
      const { name } = this.state;

      const updates = {};
      const calendarId = cuid();
      const calendar = new PathBuilder(calendarId);
      updates[calendar.calendar()] = { name };
      updates[calendar.categories()] = categories;
      updates[calendar.events()] = events;

      firebase
        .database()
        .ref()
        .update(updates, () => {
          // close modal
          this.handleCloseModal();

          // generate shortcut
          const endpoint = document.location.href.split('/#').shift();
          const shortcutTemplate = `[InternetShortcut]\nURL=${endpoint}/#/calendar/${calendarId}\n\n`;
          const metadata = {
            metadata: {
              id: calendarId,
              name: name,
              created_on: Date.now(),
            },
          };

          toTOMLString(metadata, (err, data) => {
            const blob = new Blob([shortcutTemplate, data], { type: 'text/plain;charset=utf-8' });
            saveAs(blob, `${name}.url`, false);

            history.replace(`/calendar/${calendarId}`);
          });
        });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          {...this.state.ui}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          handleCloseModal={this.handleCloseModal}
        />
      );
    }
  };
}

export default compose(withRouter, withHandlers);
