import React, { PureComponent } from 'react';
import { DragDropContextProvider } from 'react-dnd';
import { HashRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import HTML5Backend from 'react-dnd-html5-backend';
import { lifecycle } from 'recompose';

import { firebase, db } from '../rebase';
import Calendar from './Calendar';
import Export from './Export';
import Login from './Login';
import configureStore from '../store';
import theme from './theme';

const store = configureStore();

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  setRedirectUrl,
  location,
  user,
  ...rest
}) => (
  <Route
    {...rest}
    component={lifecycle({
      componentWillMount() {
        setRedirectUrl(location.pathname + location.search);
      },
    })(
      props => (isAuthenticated ? <Component {...{ ...props, user }} /> : <Redirect to="/login" />),
    )}
  />
);

type Props = {
  location: any,
};

type State = {
  authorized: boolean | null,
  loading: boolean,
  redirectUrl: string | null,
  user: any,
};

export default class App extends PureComponent<Props, State> {
  state = {
    authorized: null,
    user: null,
    loading: true,
    redirectUrl: null,
  };

  checkAuthorization = () => {
    const ref = db.ref('calendars');
    ref.limitToLast(1).on(
      'child_added',
      _snapshot => {
        this.setState({ authorized: true, loading: false });
      },
      _error => {
        this.setState({ authorized: false, loading: false });
      },
    );
  };

  componentWillMount() {
    firebase.auth().onAuthStateChanged(user => {
      this.checkAuthorization();
      this.setState({ user });
    });
  }

  handleLogin = () => {
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(_result => {
        this.checkAuthorization();
      });
  };

  handleLogout = () => {
    firebase.auth().signOut();
    this.setState({ authorized: false });
  };

  setRedirectUrl = (redirectUrl: string) => {
    this.setState({ redirectUrl });
  };

  render() {
    return (
      <Provider store={store}>
        <DragDropContextProvider backend={HTML5Backend}>
          <ThemeProvider theme={theme}>
            <Router>
              <Switch>
                <Route
                  exact
                  path="/login"
                  render={props => (
                    <Login
                      handleLogin={this.handleLogin}
                      handleLogout={this.handleLogout}
                      {...this.state}
                      {...props}
                    />
                  )}
                />
                <PrivateRoute
                  exact
                  path="/calendar/:id"
                  component={Calendar}
                  setRedirectUrl={this.setRedirectUrl}
                  isAuthenticated={!!this.state.user}
                  location={this.props.location}
                  user={this.state.user}
                />
                <PrivateRoute
                  exact
                  path="/calendar/:id/export"
                  component={Export}
                  setRedirectUrl={this.setRedirectUrl}
                  isAuthenticated={!!this.state.user}
                  location={this.props.location}
                  user={this.state.user}
                />
                <Redirect exact from="/" to="/login" />
              </Switch>
            </Router>
          </ThemeProvider>
        </DragDropContextProvider>
      </Provider>
    );
  }
}
