import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../theme';

const Folder = styled.i`
  color: ${colors('orange')};
  line-height: unset;
  margin: auto 5px;
`;

const Text = styled.span`
  font-size: 0.9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto 0;
  margin-right: auto;
`;

const RightCarrot = styled.i`
  color: ${colors('white')};
  margin: 1px 5px 1px 0;
  font-size: 22px;
  display: none;

  &:hover {
    color: ${colors('orange')};
  }
`;

const selectedFragment = css`
  background-color: ${colors('teal')};
`;

const hoverFragment = css`
  &:hover {
    ${selectedFragment};
  }
`;

const drillDownFragment = css`
  &:hover {
    ${RightCarrot} {
      display: unset;
    }
  }
`;

const RowContainer = styled.div.attrs({
  selected: props => !!props.selected,
})`
  display: flex;
  flex-direction: row;
  justify-content; space-between;
  min-width: 100px;
  min-height: 25px;
  height: 25px;
  font-weight: normal;
  cursor: default;

  ${props => (props.selected ? selectedFragment : hoverFragment)}

  ${props => props.canDrillDown && drillDownFragment}
`;

const Row = ({ name, selected, onDrillDown, onClick, ...props }) => (
  <RowContainer
    selected={selected}
    // eslint-disable-next-line no-extra-boolean-cast
    onClick={!!onDrillDown ? onDrillDown : onClick}
    canDrillDown={!!onDrillDown}
  >
    <Folder className="fa fa-folder" />
    <Text>{name}</Text>
    {/* <RightCarrot className="fa fa-angle-right" onClick={onDrillDown} /> */}
  </RowContainer>
);

export default Row;
