import { combineReducers } from 'redux';

import ui from './ui';
import calendar from './calendar';
import events from './events';

export default combineReducers({
  ui,
  calendar,
  events,
});
