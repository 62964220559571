import React from 'react';
import styled from 'styled-components';

import Modal, {
  Button,
  Row,
  Header,
  Field,
  Input,
  SecondaryButton,
  CloseButton,
  FieldGroup,
} from '../Modal';

import type { PassedProps } from './container';

export const SpacerText = styled.span`
  font-size: 16px;
  margin: 23px 0 0 0;
`;

const CtxMenuModal = Modal.extend`
  padding: 30px 30px;
  width: auto;
`;

const CtxHeader = Header.extend`
  padding: 0px 0px;
`;

const HeaderTitle = styled.span`
  font-weight: regular;
  font-size: 24px;
`;

const CtxForm = styled.form`
  padding-top: 26px;
`;

const CtxSubmitButton = Button.extend`
  background-color: #6f6f6f;
  color: #fff;
  padding: 1em;
  width: 100px;
  height: 46px;
  flex: none !important;
`;

const CtxCancelButton = SecondaryButton.extend`
  margin: 0px 60px 0px 90px;
  flex: none !important;
`;

const Controls = Row.extend`
  padding-top: 30px;
`;

export default ({
  handleChange,
  handleCloseModal,
  handleSubmit,
  hasFocus,
  modalPushPull = { isOpen: false, type: 'events' },
  push = 1,
  startDate = '',
}: PassedProps) => (
  <CtxMenuModal isOpen={modalPushPull.isOpen}>
    <CtxHeader>
      <HeaderTitle>
        Push/Pull {modalPushPull.type.split('-').map(w => w[0].toUpperCase() + w.slice(1))}
      </HeaderTitle>
      <CloseButton onClick={handleCloseModal} aria-label="close">
        x
      </CloseButton>
    </CtxHeader>
    <CtxForm onSubmit={handleSubmit} key="form">
      <Row>
        <FieldGroup horizontal>
          <Field label="Push/Pull to date">
            <Input
              hasFocus={hasFocus}
              id="startDate"
              name="startDate"
              onChange={handleChange}
              type="date"
              units="or"
              value={startDate}
            />
          </Field>
          <Field>
            <SpacerText>or</SpacerText>
          </Field>
          <Field label="Push/Pull number of days">
            <Input name="push" onChange={handleChange} type="number" units="day(s)" value={push} />
          </Field>
        </FieldGroup>
      </Row>
      <Controls>
        <CtxCancelButton
          type="button"
          margin="0 10px 0 0"
          onClick={handleCloseModal}
          aria-label="cancel"
        >
          Cancel
        </CtxCancelButton>
        <CtxSubmitButton type="submit" aria-label="submit">
          Push/Pull
        </CtxSubmitButton>
      </Controls>
    </CtxForm>
  </CtxMenuModal>
);
