// $FlowFixMe
import undoable from 'redux-undo';

const event = (state, action) => {
  switch (action.type) {
    case 'ADD_UNDO':
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

const events = (state = [], action) => {
  switch (action.type) {
    case 'ADD_UNDO':
      return event(undefined, action);
    case 'CLEAR_UNDO_PRESENT':
      return [];
    default:
      return state;
  }
};

const undoableEvents = undoable(events, {
  clearHistoryType: 'CLEAR_UNDO_HISTORY',
});

export default undoableEvents;
