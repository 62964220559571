import React, { Component } from 'react';
import yup from 'yup';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withRouter } from 'react-router';
import { firebase } from '../../rebase';

import type { FirebaseUsers, FirebaseUser } from '../../models';
import { ERRORS, USER_ROLES } from '../constants';
import { closeUsersModal } from '../../store/actions';
import { isUsersModalOpen } from '../../store/selectors';
import type { PathObject } from '../../helpers';
import { PathBuilder, calendarId, flattenUsers, getUserPath } from '../../helpers';

const withStore = connect(
  state => ({
    isUsersModalOpen: isUsersModalOpen(state),
  }),
  { closeUsersModal },
);

export type PassedProps = {
  isUsersModalOpen: boolean,
  handleCloseModal: () => void,
  // formik
  errors: {
    email: string,
    role: string,
  },
  handleChange: () => void,
  handleReset: () => void,
  handleSubmit: () => void,
  isSubmitting: boolean,
  setFieldValue: (field: string, value: any) => void,
  setStatus: (value: any) => void,
  touched: any,
  status: any,
  values: {
    email: string,
    role: string,
  },
  users: FirebaseUser[],
  handleDelete: (email: string) => void,
  handleUpdateRole: (email: string, newRole: string) => void,
  setUserAdded: (isUserAdded: boolean) => void,
  setError: (error: any) => void,
  isUserAdded: boolean,
  error: any,
};

function withHandlers(WrappedComponent) {
  type Props = {
    isUsersModalOpen: boolean,
    closeUsersModal: () => void,
    users: FirebaseUsers,
  };

  type State = {
    users: FirebaseUser[],
    isUserAdded: boolean,
    error: any,
    currentUser: any,
  };

  return class extends Component<Props, State> {
    state = {
      users: [],
      error: null,
      isUserAdded: false,
      currentUser: null,
    };

    componentDidMount() {
      this.path = new PathBuilder(calendarId(this.props));

      const { users } = this.props;
      const currentUser = firebase.auth().currentUser;

      this.setState({
        currentUser,
      });

      if (Object.keys(users).length > 0) {
        const usersArray = flattenUsers(users, currentUser && currentUser.email);

        this.setState({ users: usersArray });
      }
    }

    handleDelete = email => {
      const willDelete = window.confirm(`Are you sure you want to delete user: ${email}?`);

      if (willDelete) {
        const ref = firebase.database().ref(getUserPath(email));

        ref.remove().catch(err => {
          this.setError(err.message);
        });
      }
    };

    handleUpdateRole(email, newRole) {
      const path = getUserPath(email);

      const update = {
        [path]: {
          email,
          role: newRole,
        },
      };

      firebase
        .database()
        .ref()
        .update(update)
        .catch(err => {
          this.setError(err.message);
        });
    }

    path: PathObject;
    ref: () => mixed;

    componentDidUpdate(prevProps) {
      const { users } = this.props;
      const { currentUser } = this.state;


      if (prevProps.users !== users) {
        const usersArray = flattenUsers(users, currentUser && currentUser.email);
        this.setState({ users: usersArray });
      }
    }

    handleCloseModal = () => {
      this.props.closeUsersModal();
      this.resetStates();
    };

    setUserAdded = isUserAdded => {
      this.setState({ isUserAdded });

      setTimeout(() => {
        this.setState({ isUserAdded: !isUserAdded });
      }, 5000);
    };

    setError = error => {
      this.setState({ error });

      setTimeout(() => {
        this.setState({ error: null });
      }, 5000);
    };

    resetStates = () => {
      this.setState({
        isUserAdded: false,
        error: null,
      });
    };

    render() {
      const { users, isUserAdded, error } = this.state;
      const {
        path,
        handleCloseModal,
        handleDelete,
        handleUpdateRole,
        setUserAdded,
        setError,
      } = this;
      return (
        <WrappedComponent
          {...this.props}
          {...{
            path,
            handleCloseModal,
            users,
            handleDelete,
            handleUpdateRole,
            setUserAdded,
            setError,
            isUserAdded,
            error,
          }}
        />
      );
    }
  };
}

export default compose(
  withStore,
  withRouter,
  withHandlers,
  withFormik({
    enableReinitialize: true,
    validationSchema: () => {
      const schema = yup.object().shape({
        email: yup
          .string()
          .max(100, ERRORS.MAX_CHARACTER_LENGTH)
          .email(ERRORS.EMAIL_FIELD)
          .required(ERRORS.REQUIRED_FIELD),
        role: yup
          .string()
          .max(100, ERRORS.MAX_CHARACTER_LENGTH)
          .oneOf(USER_ROLES)
          .required(ERRORS.REQUIRED_FIELD),
      });

      return schema;
    },
    handleSubmit: (values, actions) => {
      const { resetForm, props } = actions;
      const { email, role } = values;
      const reset = () => resetForm({ email: '' });
      const path = getUserPath(email);

      const newUser = {
        [path]: {
          email,
          role,
        },
      };

      return firebase
        .database()
        .ref()
        .update(newUser)
        .then(() => {
          props.setUserAdded(true);
          reset();
          props.resetStates();
        })
        .catch(e => props.setError(e.message));
    },
  }),
);
